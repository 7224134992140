import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { Button } from "../../../components";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ManageTable from "./ManageTable";
import Filterbar from "./Filterbar";
import useTranslation from "../../../hooks/Translation";
import { Brand, Category } from "../../../types/General";
import useAuth from "../../../hooks/UseAuth";
import usePermissions from "../../../hooks/Permissions";
import { PermissionEnums } from "../../../contansts/permissions";
import { styled } from "@mui/material/styles";
import { UploadCsv } from "../../../utils/mediaUpload";
import { successToast } from "../../../helpers";
import { errorToast } from "../../../helpers";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type ManageProps = {
  SetActiveCase: Dispatch<SetStateAction<any>>;
  setSelectedId: Dispatch<SetStateAction<any>>;
  categories: Category[];
  brandsData: Brand[];
  setIsAdd: Dispatch<SetStateAction<boolean>>;
};

const ManageProducts = ({
  SetActiveCase,
  setSelectedId,
  categories,
  brandsData,
  setIsAdd,
}: ManageProps) => {
  const user = useAuth();
  const permissions = usePermissions();
  const translation = useTranslation() as any;

  const [productId, setProductId] = useState<string>("");
  const [dates, setDates] = useState<any>();
  const [value, setValue] = React.useState(0);

  const [category, setCategory] = useState<string>("");
  const [brand, setBrand] = useState<string>("");
  const [pendingCount, setPendintCount] = useState<number>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const Input = styled("input")({
    // display: "none",
  });

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    console.log(files, "files");
    if (files?.length) {
      const response = await UploadCsv(files[0]);
      if (response?.code === 200) {
        setProductId(" ");
        successToast(response?.message || "");
      } else {
        errorToast(response?.message || "Something went wrong");
      }
    }
  };

  const handleDownload = async () => {
    try {
      const response = await fetch("/static/images/product.csv");
      const csvData = await response.text();

      // Create a Blob object from the fetched CSV data
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

      // Generate a temporary URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a link element
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.setAttribute("download", "product.csv"); // Set the filename for the download

      // Append the link to the body and click it to trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up the URL and link element
      URL.revokeObjectURL(url);
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  return (
    <>
      <div className="manageProducts">
        <div className="productHead">
          <h3>Manage Products</h3>
          <div className="rightp">
            <h4 onClick={handleDownload}>
              <FileDownloadIcon />
              Download template
            </h4>

            {user?.is_company_owner ||
            permissions?.includes(`${PermissionEnums.addProduct}`) ? (
              <div className="bulk">
                <label htmlFor="icon-button-filee">
                  <div className="uploadImg">
                    <Input
                      id="icon-button-file"
                      type="file"
                      accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .csv"
                      onChange={handleFileUpload}
                    />

                    <div className="bussinessimg">
                      <p>{translation.addProduct.bulk}</p>
                      <FileUploadOutlinedIcon />
                    </div>
                  </div>
                </label>
              </div>
            ) : null}
            {user?.is_company_owner ||
            permissions?.includes(`${PermissionEnums.addProduct}`) ? (
              <div className="btn">
                <Button
                  value="+ Add product"
                  onClick={() => {
                    SetActiveCase(2);
                    setIsAdd(true);
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="tabdiv">
          <div className="tableft">
            <Box sx={{ width: "100%" }} className="tablebox">
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  position: "relative",
                }}
              >
                {pendingCount ? (
                  <h3 className="count_pen">{pendingCount}</h3>
                ) : null}
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="tablepanal"
                >
                  <Tab label="Pending" {...a11yProps(0)} />

                  <Tab label="Approved" {...a11yProps(1)} />

                  <Tab label="Un-Approved" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <ManageTable
                  SetActiveCase={SetActiveCase}
                  value={value}
                  productId={productId}
                  dates={dates}
                  category={category}
                  brand={brand}
                  setSelectedId={setSelectedId}
                  setIsAdd={setIsAdd}
                  setPendintCount={setPendintCount}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ManageTable
                  SetActiveCase={SetActiveCase}
                  value={value}
                  productId={productId}
                  dates={dates}
                  category={category}
                  brand={brand}
                  setSelectedId={setSelectedId}
                  setIsAdd={setIsAdd}
                  setPendintCount={setPendintCount}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ManageTable
                  SetActiveCase={SetActiveCase}
                  value={value}
                  productId={productId}
                  dates={dates}
                  category={category}
                  brand={brand}
                  setSelectedId={setSelectedId}
                  setIsAdd={setIsAdd}
                  setPendintCount={setPendintCount}
                />
              </TabPanel>
            </Box>
          </div>
          <div className="tabryt">
            <Filterbar
              dates={dates}
              setDates={setDates}
              setProductId={setProductId}
              productId={productId}
              categories={categories}
              category={category}
              setCategory={setCategory}
              brand={brand}
              setBrand={setBrand}
              brandsData={brandsData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageProducts;
