import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import useTranslation from "../../hooks/Translation";
import useAuth from "../../hooks/UseAuth";
import { useLocation } from "react-router-dom";
import usePermissions from "../../hooks/Permissions";
import { PermissionEnums } from "../../contansts/permissions";

type accountTab = {
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  SetActiveCase: Dispatch<SetStateAction<number>>;
};

const SellerSidebar = ({
  activeTab,
  setActiveTab,
  SetActiveCase,
}: accountTab) => {
  const userData = useAuth();
  const location = useLocation();
  const permissions = usePermissions();

  const { state } = location;

  useEffect(() => {
    if (state && state === "list") {
      setActiveTab(3);
      SetActiveCase(2);
    } else if (state && state === "address") {
      setOpen(!open);
      setActiveTab(8);
    } else {
      setActiveTab(1);
      SetActiveCase(1);
    }
  }, [state]);

  const translation = useTranslation() as any;
  const [open, setOpen] = useState(false);

  const array = [
    {
      id: 1,
      img: "/static/images/dash.png",
      clickedImg: "/static/images/blueDash.svg",
      text: translation.seller.dashboard,
      click: 1,
      isVisible: userData?.is_company_owner,
    },
    {
      id: 2,
      img: "/static/images/manage.png",
      clickedImg: "/static/images/ordersblue.svg",
      text: translation.seller.ManageOrder,
      click: 2,
      isVisible:
        userData?.is_company_owner ||
        permissions?.includes(`${PermissionEnums.viewOrderStatus}`),
    },
    {
      id: 3,
      img: "/static/images/product.png",
      clickedImg: "/static/images/manageblue.svg",
      text: translation.seller.mangaeproduct,
      click: 3,
      isVisible:
        userData?.is_company_owner ||
        permissions?.includes(`${PermissionEnums.addProduct}`) ||
        permissions?.includes(`${PermissionEnums.editProduct}`) ||
        permissions?.includes(`${PermissionEnums.markProductObsolete}`),
    },
    {
      id: 6,
      img: "/static/images/report.png",
      clickedImg: "/static/images/roles.svg",
      text: "Manage Roles",
      click: 12,
      isVisible: userData?.is_company_owner,
    },
    {
      id: 4,
      img: "/static/images/report.png",
      clickedImg: "/static/images/roles.svg",
      text: "Roles & Permissions",
      click: 4,
      isVisible: userData?.is_company_owner,
    },
    {
      id: 5,
      img: "/static/images/report.png",
      clickedImg: "/static/images/userblue.svg",
      text: "Manage Users",
      click: 5,
      isVisible: userData?.is_company_owner,
    },
    {
      id: 5,
      img: "/static/images/report.png",
      clickedImg: "/static/images/reportblue.svg",
      text: "Report & MIS",
      click: 6,
      isVisible: userData?.is_company_owner,
    },
  ];

  const account = [
    {
      id: 1,
      img: "/static/images/report.png",
      clickedImg: "/static/images/logout2.png",
      text: "Account Settings",
      click: activeTab,
      subCat: [
        {
          id: 1,
          name: translation.seller.info,
          onclick: 7,
          isVisible: true,
        },
        {
          id: 2,
          name: translation.seller.address,
          onclick: 8,
          isVisible: true,
          // isVisible:
          //   userData?.is_company_owner ||
          //   permissions?.includes(`${PermissionEnums.changeShippingAddress}`),
        },
        {
          id: 4,
          name: translation.seller.changePass,
          onclick: 9,
          isVisible: true,
        },
      ],
    },
  ];

  console.log(
    permissions,
    permissions?.includes(`${PermissionEnums.viewOrderStatus}`)
  );

  const handleAccountSetting = (click: number) => {
    setOpen(!open);
    setActiveTab(click);
  };

  return (
    <div className="sidebarmain">
      <div className="username">
        <div className="figleft">
          <figure>
            <img
              src={
                userData?.image?.media_file_url ||
                "/static/images/profilepic.png"
              }
              alt="profile"
            />
          </figure>
          <h3>
            {" "}
            {userData?.first_name
              ? userData?.first_name + " " + userData?.last_name
              : ""}
          </h3>
        </div>
      </div>

      <div className="sidebar">
        <ul>
          {array.map((item) => {
            return (
              <li key={item?.id}>
                {item?.isVisible ? (
                  <div
                    className={
                      activeTab === item?.click ? "accmain" : "accmain2"
                    }
                    onClick={() => {
                      setActiveTab(item?.click);
                      if (item?.click === 3) {
                        SetActiveCase(1);
                      }
                    }}
                  >
                    <figure>
                      <img src={item.clickedImg} alt="clicked-item" />
                    </figure>
                    <h3>{item.text}</h3>
                  </div>
                ) : (
                  <></>
                )}
              </li>
            );
          })}
        </ul>
        {account.map((val, index) => {
          return (
            <List
              key={index}
              sx={{
                width: "100%",
                maxWidth: "100%",
                bgcolor: "background.paper",
              }}
            >
              <ListItemButton
                onClick={() => handleAccountSetting(val?.click)}
                sx={{ paddingLeft: "10px" }}
              >
                <ListItemIcon className="iconuser">
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  primary={val.text}
                  className="boldhead2"
                  // className={activeTab === val?.click ? "boldhead2" : "bold1"}
                />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                {val.subCat.map((item) => {
                  return (
                    <List
                      component="div"
                      style={{ display: item?.isVisible ? "flex" : "none" }}
                      disablePadding
                      key={item.id}
                    >
                      <ListItemButton
                        className={
                          activeTab === item.onclick
                            ? "info_actv"
                            : "info_option"
                        }
                        onClick={() => setActiveTab(item.onclick)}
                      >
                        <ListItemText primary={item.name} />
                      </ListItemButton>
                    </List>
                  );
                })}
              </Collapse>
            </List>
          );
        })}
      </div>
    </div>
  );
};

export default SellerSidebar;
