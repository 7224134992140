import React from "react";
import "./AboutUs.scss";
import Layout from "../../layout";
import { Button } from "../../components";
import useTranslation from "../../hooks/Translation";
import { useAppSelector } from "../../hooks/Store";
import { getCms } from "../../reducers/commonSlice";
const About = () => {
  const cms = useAppSelector(getCms);

  const translation = useTranslation() as any;
  return (
    <Layout>
      <div className="aboutWrapper">
        {/* <div className="aboutBannr">
          <h1>{translation.aboutUs.bannerHead}</h1>
          <div className="bannerText">
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>
        </div> */}
        {/* <div className="bnnrBtn">
          <h2>
            Lorem Ipsum is simply dummy text of the printing and
            <br />
            typesetting industry.
          </h2>
          <div className="btnn">
            <Button value={translation.aboutUs.button} />
          </div>
        </div> */}
        <div className="container">
          <div className="aboutDiv">
            <h3>{translation.aboutUs.aboutHead}</h3>
            <div className="content">
              {/* <p className="aboutDetails">{translation.aboutUs.content}</p>
              <p className="aboutDetails">{translation.aboutUs.content}</p>
              <p className="aboutDetails">{translation.aboutUs.content}</p> */}
              <p
                dangerouslySetInnerHTML={{
                  __html: cms ? cms?.about_us : "No Data Found",
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default About;
