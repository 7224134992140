import "./ManageOrder.scss";
import OrderTable from "./OrderTable";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useGetAllOrdersListMutation } from "../../service/sellerPanel/Orders";
import { useCallback, useEffect, useState } from "react";
import { Loader } from "../../helpers";
import OderDetail from "./OrderDetail";

export type OrderType = {
  user: string;
  product: string;
  product_category: string;
  product_sub_category: string;
  brand: string;
  delivery_status: number;
  order_id: number;
  product_order_id: number;
  quantity: number;
  cost: number;
};

let totalPages = 1;

const ManageOrder = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderData, setOrderData] = useState<OrderType[]>([]);
  const [isOrderId, setIsOrderId] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();

  useCallback(() => {
    totalPages = Math.ceil(totalCount / 10);
  }, [totalCount]);

  const [getAllOrdersMethod] = useGetAllOrdersListMutation();

  const goToPage = (page: React.SetStateAction<number>) => {
    setCurrentPage(page);
  };

  const getAllOrdersData = async () => {
    try {
      let body = {
        length: 5,
        start: currentPage,
        search: {
          value: "",
          regex: false,
        },
      };
      setLoading(true);
      const res = await getAllOrdersMethod(body).unwrap();
      setLoading(false);
      if (res?.code === 200 || res?.code === 201) {
        const flattenedData = res?.data.flatMap((item) =>
          item.ordered_product.map((product) => ({
            ...product,
            user: `${product?.user?.first_name || ""} ${
              product?.user?.last_name || ""
            }`,
            product: product?.product?.product_name || "",
            product_category: product?.product?.product_category?.name || "",
            product_sub_category:
              product.product.product_sub_category.name || "",
            brand: product?.product?.brand?.name || "",
            delivery_status: product?.delivery_status || 0,
            order_id: item?.id || 0,
            product_order_id: product?.id || 0,
            quantity: product?.quantity || 0,
            cost: product?.cost || 0,
          }))
        );
        setOrderData(flattenedData || []);
        setTotalCount(res?.recordsTotal || 1);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllOrdersData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <div className="manageOrders">
      <Loader isLoad={loading} />
      {isOrderId ? (
        <OderDetail setIsOrderId={setIsOrderId} selectedId={selectedId} />
      ) : (
        <>
          <h3>Manage Orders</h3>
          <div className="mngOrdrSec">
            <OrderTable
              orders={orderData}
              setIsOrderId={setIsOrderId}
              setSelectedId={setSelectedId}
            />
          </div>

          <div className="pagination" style={{ marginTop: "20px" }}>
            <p>
              <span>
                Showing {orderData?.length ? `${orderData?.length}` : 0} items{" "}
              </span>
            </p>
            {/* {users?.length ? ( */}
            <div className="pages">
              <button
                className="prevbtn"
                onClick={() => goToPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <ArrowBackIosIcon />
              </button>
              <div className="count">
                {totalPages > 6
                  ? Array.from({ length: totalPages })
                      .slice(0, 6)
                      .map((_, index) => (
                        <button
                          className={
                            currentPage === index + 1 ? "actv" : "inActv"
                          }
                          key={index}
                          onClick={() => goToPage(index + 1)}
                          disabled={currentPage === index + 1}
                        >
                          {index + 1}
                        </button>
                      ))
                      .concat(
                        <span key="ellipsis" className="ellipsis">
                          ...
                        </span>,
                        <button
                          className={"actv"}
                          style={{
                            display:
                              currentPage < 6 || currentPage === totalPages
                                ? "none"
                                : undefined,
                          }}
                          key={totalPages}
                        >
                          {currentPage}
                        </button>,
                        <button
                          className={
                            currentPage === totalPages ? "actv" : "inActv"
                          }
                          key={totalPages}
                          onClick={() => goToPage(totalPages)}
                          disabled={currentPage === totalPages}
                        >
                          {totalPages}
                        </button>
                      )
                  : Array.from({ length: totalPages }).map((_, index) => (
                      <button
                        className={
                          currentPage === index + 1 ? "actv" : "inActv"
                        }
                        key={index}
                        onClick={() => goToPage(index + 1)}
                        disabled={currentPage === index + 1}
                      >
                        {index + 1}
                      </button>
                    ))}
              </div>
              <button
                className="prevbtn"
                onClick={() => goToPage(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <ArrowForwardIosIcon
                // className="prevbtn"
                // onClick={() => goToPage(currentPage + 1)}
                />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ManageOrder;
