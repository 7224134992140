import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ManageUser } from "../../../types/General";

type ManageProps = {
  users: ManageUser[];
};

const UsersReportTable = ({ users }: ManageProps) => {
  return (
    <TableContainer component={Paper} sx={{ padding: 0 }} className="tablemain">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email ID</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Role</TableCell>
            {/* <TableCell>Action</TableCell> */}
          </TableRow>
        </TableHead>
        {users?.length ? (
          users?.map((item) => (
            <TableBody>
              <TableRow
                key={item?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  {item?.first_name || ""} {item?.last_name || ""}
                </TableCell>
                <TableCell>{item?.email || "--"}</TableCell>
                <TableCell>
                  {item?.office_country_code || ""}{" "}
                  {item?.office_phone_no || "--"}
                </TableCell>
                <TableCell>{item?.user_website_roles?.name || "--"}</TableCell>
                <TableCell>{item?.location || "--"}</TableCell>
                {/* <TableCell>
                  <RemoveRedEyeIcon sx={{ color: "green" }} />
                </TableCell> */}
              </TableRow>
            </TableBody>
          ))
        ) : (
          <TableBody className="order_err2">
            <h4>Users not found</h4>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default UsersReportTable;
