import { TextField } from "@mui/material";
import dayjs from "dayjs";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import { usePostSubscribeMutation } from "../../service/home";
import { successToast, warnToast } from "../../helpers";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";

const Footer = ({ scrollPosition }: { scrollPosition: ScrollPosition }) => {
  const translation = useTranslation() as any;
  const [subscribe] = usePostSubscribeMutation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      const body = {
        email: formik.values.email,
      };

      try {
        const res = await subscribe(body).unwrap();
        if (res?.code === 200) {
          console.log("res", res?.data);
          successToast(res?.message || "");
          formik.resetForm();
        }
      } catch (error: any) {
        warnToast("Newsletter already subscribed with this email" || "");
        formik.resetForm();
        console.log(error);
      }
    },
  });

  return (
    <footer className="footermain">
      <div className="topbar">
        <div className="container">
          <div className="topmain">
            <div className="tleft">
              <figure>
                <LazyLoadImage
                  alt={"Share"}
                  height={"100%"}
                  src={"/static/images/share_icon.png" || ""}
                  width={"100%"}
                  effect="blur"
                  scrollPosition={scrollPosition}
                />
              </figure>
              <h3>{translation.footer.subs}</h3>
            </div>
            <div className="tRight">
              <form onSubmit={formik.handleSubmit}>
                <div className="inputField">
                  <TextField
                    id="email"
                    name="email"
                    type="text"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    // helperText={formik.touched.email && formik.errors.email}
                    placeholder="Enter your email address"
                    sx={{ width: "100%" }}
                  />

                  <div className="btnddiv">
                    <button
                      type="submit"
                      disabled={formik.isSubmitting}
                      className=""
                    >
                      Subscribe
                    </button>
                  </div>

                  {/* <Button value="" /> */}
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <h6 className="err_msg_sub">
                    {formik.touched.email && formik.errors.email}
                  </h6>
                ) : (
                  ""
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="centerdiv">
        <div className="container">
          <ul>
            <li>
              <figure>
                <LazyLoadImage
                  alt={"Logo"}
                  height={"100%"}
                  src={"/static/images/logo.png" || ""}
                  width={"100%"}
                  effect="blur"
                  scrollPosition={scrollPosition}
                />
              </figure>
              <h3>{translation.footer.address}</h3>
              <p>
                Mohamed bin Fahd St. - Eastern Beach, Beach Towers Blg, Office
                A51
              </p>
              <h3>{translation.footer.phone}</h3>
              <p>+966-13-841 1042</p>
              <div className="social_icons">
                {/* <LinkedInIcon /> */}

                {/* <FacebookOutlinedIcon/>  */}
                <figure>
                  <img src="/static/images/linkedin.png" alt="linkedIn" />
                </figure>
                <TwitterIcon />

                <FacebookOutlinedIcon />
              </div>
            </li>
            <li>
              <h2>{translation.footer.service}</h2>
              <h6>Petrochemicals</h6>
              <h6>Industrial Plants</h6>
              <h6>Oil & Gas</h6>
              <h6>Pipeline</h6>
            </li>
            <li>
              <h2>{translation.footer.pages}</h2>
              <h6 onClick={() => navigate("/")}>Home</h6>
              <h6 onClick={() => navigate("/about-us")}>About Us</h6>
              <h6 onClick={() => navigate("/faq")}>FAQ's</h6>
              <h6 onClick={() => navigate("/faq")}>Help</h6>
            </li>
            <li>
              <h2>{translation.footer.links}</h2>
              <h6 onClick={() => navigate("/profile")}>My Account</h6>
              <h6 onClick={() => navigate("/contact-us")}>Contact Us</h6>
              <h6 onClick={() => navigate("/terms-&-conditions")}>
                Terms & Conditions
              </h6>
              <h6 onClick={() => navigate("/privacy-policy")}>
                Privacy Policy
              </h6>
            </li>
          </ul>
        </div>
      </div>
      <div className="bottombar">
        <div className="container">
          <h5>Copyright {dayjs().year()} - All right reserved by e nilcon</h5>
        </div>
      </div>
    </footer>
  );
};

export default trackWindowScroll(Footer);
