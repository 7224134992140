import React, { Dispatch, SetStateAction } from "react";
import { ProductType } from "../../types/General";
import moment from "moment";

type ProductProps = {
  data: ProductType[];
  getOrderById: (id: number) => void;
  setOrderId: Dispatch<SetStateAction<number | undefined>>;
  setSelectedId: Dispatch<SetStateAction<number | undefined>>;
  OrderId: number | undefined;
  selectedId: number | undefined;
  active: boolean;
};

const PastOrderData = ({
  data,
  getOrderById,
  setOrderId,
  setSelectedId,
  OrderId,
  selectedId,
  active,
}: ProductProps) => {
  const status = {
    1: "Order placed",
    2: "Shipped",
    3: "Delivered",
    4: "Rejected",
    5: "Cancelled",
  } as any;

  return (
    <div>
      {data?.length ? (
        data?.map((item, i) => {
          return (
            <div
              key={i}
              // className="orders-data"
              className={
                selectedId === item?.cancelProduct_id
                  ? "orders-data_slt"
                  : "orders-data"
              }
              onClick={() => {
                getOrderById(item?.product_id);
                setOrderId(item?.order_id);
                setSelectedId(item?.order_id);
              }}
            >
              <figure className="order-img">
                <img src={item?.product_image || ""} alt="data-img"></img>
              </figure>
              <div className="product-details">
                <h4>{item?.product_name || ""}</h4>
                <div style={{ width: "70%" }}>
                  {/* <p>Size:{items.size}</p> */}
                </div>
                <p>QTY:{item?.quantity}</p>
                <span>
                  Total Price: $
                  {`${
                    item?.cost ? parseFloat(`${item?.cost}`).toFixed(2) : ""
                  }`}
                </span>
              </div>
              <div
                // style={{active?}}
                style={{
                  backgroundColor: !active
                    ? item?.delivery_status === 3
                      ? "green"
                      : "#ff3131"
                    : "#008EF7",
                }}
                className="order-status"
              >
                <p style={{ fontSize: "12px" }}>
                  {status[item?.delivery_status || 1]}
                </p>
                <p style={{ fontSize: "10px" }}>
                  On {moment(item?.created_at).format("ddd, D MMM")}
                </p>
              </div>
            </div>
          );
        })
      ) : (
        <div className="no_order_div">
          <figure>
            <img src="/static/images/no_pro.png" alt="No Products" />
          </figure>
          <h4>No orders found</h4>
        </div>
      )}
    </div>
  );
};

export default PastOrderData;
