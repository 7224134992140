import Layout from "../../layout";
import { useAppSelector } from "../../hooks/Store";
import { getCms } from "../../reducers/commonSlice";

const Terms = () => {
  const cms = useAppSelector(getCms);

  return (
    <Layout>
      <div className="wrapcls policyWrapper">
        <div className="container">
          <div className="prvtPolicy">
            <h2>Terms and Conditions</h2>

            <div className="policyContent">
              <p
                dangerouslySetInnerHTML={{
                  __html: cms
                    ? cms?.terms_and_conditions
                    : "Terms & Conditions not found",
                }}
              ></p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Terms;
