import React, { useEffect, useState } from "react";
import "../../pages/seller/Seller.scss";
import "./Dashboard.scss";
import useTranslation from "../../hooks/Translation";
import { useLazyGetDashboardCountQuery } from "../../service/sellerPanel/Dashboard";
import { DashboardCount } from "../../types/General";
import { Loader } from "../../helpers";
import OrdersReportTable from "./Reports/OrdersReports";
import { useGetAllOrdersListMutation } from "../../service/sellerPanel/Orders";
import { OrderType } from "./ManageOrder";

const Dashboard = () => {
  const translation = useTranslation() as any;
  const [dashboardCount, setDashboardCount] = useState<DashboardCount>();
  const [loading, setLoading] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<OrderType[]>([]);

  const [dashboardCountMethod] = useLazyGetDashboardCountQuery();
  const [getOrderList] = useGetAllOrdersListMutation();

  const handleDashboardCountData = async () => {
    try {
      setLoading(true);
      const res = await dashboardCountMethod({}).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        setDashboardCount(res?.data || undefined);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const dashboard = [
    {
      id: 1,
      title: "Total Orders",
      value: dashboardCount?.total_orders || "0",
    },
    {
      id: 2,
      title: "Total Revenue",
      value: dashboardCount?.total_revenue || "0",
    },
    {
      id: 3,
      title: "Total Products",
      value: dashboardCount?.total_products || "0",
    },
    {
      id: 4,
      title: "Pending Products",
      value: dashboardCount?.pending_orders || "0",
    },
    {
      id: 5,
      title: "Approved Products",
      value: dashboardCount?.approved_products || "0",
    },
    {
      id: 6,
      title: "Paid Amount",
      value: Number(dashboardCount?.paid_amount).toFixed(2) || "0",
    },
    {
      id: 7,
      title: "Earning",
      value: dashboardCount?.earning || "0",
    },
  ];

  const orders = [
    {
      id: 1,
      title: "All",
      price: dashboardCount?.total_orders || "0",
    },
    {
      id: 2,
      title: "Compeleted",
      price: dashboardCount?.completed_orders || "0",
    },
    // {
    //   id: 3,
    //   title: "Pending",
    //   price: dashboardCount?.pending_orders || "0",
    // },
    // {
    //   id: 4,
    //   title: "Accepted",
    //   price: dashboardCount?.accepted_orders || "0",
    // },
    {
      id: 5,
      title: "Ongoing",
      price: dashboardCount?.ongoing_orders || "0",
    },
    // {
    //   id: 6,
    //   title: "Scheduled",
    //   price: dashboardCount?.scheduled_orders || "0",
    // },
    {
      id: 7,
      title: "Cancelled",
      price: dashboardCount?.canceled_orders || "0",
    },
  ];

  const getOrderDetail = async () => {
    try {
      let body = {
        start: 1,
        length: 5,
        search: {
          value: "",
          regex: false,
        },
      };
      const res = await getOrderList(body).unwrap();
      if (res?.code === 200) {
        const flattenedData = res?.data.flatMap((item) =>
          item.ordered_product.map((product) => ({
            ...product,
            user: `${product?.user?.first_name || ""} ${
              product?.user?.last_name || ""
            }`,
            product: product?.product?.product_name || "",
            product_category: product?.product?.product_category?.name || "",
            product_sub_category:
              product.product.product_sub_category.name || "",
            brand: product?.product?.brand?.name || "",
            delivery_status: product?.delivery_status || 0,
            order_id: item?.id || 0,
            product_order_id: product?.id || 0,
            quantity: product?.quantity || 0,
            cost: product?.cost || 0,
          }))
        );
        setOrderData(flattenedData || []);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleDashboardCountData();
    getOrderDetail();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="dashboard">
      <h3>{translation.dashboardMain.dashboard}</h3>
      <Loader isLoad={loading} />
      <div className="dashSec">
        {dashboard.map((items) => (
          <>
            <ul key={items.id}>
              <li>{items.title}</li>
              <li>{items.value}</li>
            </ul>
          </>
        ))}
      </div>

      <h3>{translation.dashboardMain.orders}</h3>

      <div className="orderSec">
        {orders.map((value) => (
          <ul key={value.id}>
            <li>{value.title}</li>
            <li>{value.price}</li>
          </ul>
        ))}
      </div>

      <h3>Recent Orders</h3>

      <div className="productsSec">
        <OrdersReportTable orders={orderData} />
      </div>
    </div>
  );
};

export default Dashboard;
