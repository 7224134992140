import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { List, Collapse, ListItemText, ListItemButton } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import useTranslation from "../../hooks/Translation";
import { useLazyGetCountryListQuery } from "../../service/home";
import { CategoryResponse, CountriesResponse } from "../../types/General";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/Store";
import { filterData } from "../../reducers/commonSlice";

type ManageProps = {
  categories: CategoryResponse[];
  state?: number;
  countryId?: number;
  selected: number;
  setSelected: Dispatch<SetStateAction<number>>;
};

const SideBar = ({
  categories,
  state,
  countryId,
  selected,
  setSelected,
}: ManageProps) => {
  const dispatch = useAppDispatch();
  const Translation = useTranslation() as any;
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [subOpen, setSubOpen] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);
  const [subVal, setSubVal] = useState([] as number[]);
  const [countries, setCountries] = useState<CountriesResponse[]>([]);
  // const [selected, setSelected] = useState(0);
  const [countrySelected, setCountrySelected] = useState(0);
  const [subcat, setSubCat] = useState(0);

  const pageLocation = window.location.pathname;

  const [getCountryDataMethod] = useLazyGetCountryListQuery();

  const handleClick = () => {
    setOpen(!open);
  };
  const subClickHandler = (item: number) => {
    setSubOpen(!subOpen);
    if (subVal.includes(item)) {
      let arr = [];
      arr = subVal.filter((name) => name !== item);
      setSubVal(arr);
    } else {
      setSubVal([...subVal, item]);
    }
  };

  const handleCountries = () => {
    setOpenSecond(!openSecond);
  };

  const handleCountryClick = (id: number) => {
    setCountrySelected(id);
    let data = {
      countryId: id,
    };
    if (pageLocation === "/") {
      navigate("/products", { state: { country: id } });
    }
    dispatch(
      filterData({
        filterId: data,
      })
    );
  };
  const handleSubCatClick = (id: number) => {
    let data = {
      subCatId: id,
    };
    if (pageLocation === "/") {
      navigate("/products");
    }
    dispatch(
      filterData({
        filterId: data,
      })
    );
  };
  const handleCatClick = (id: number) => {
    let data = {
      catId: id,
    };
    if (pageLocation === "/") {
      navigate("/products", { state: id });
    }
    dispatch(
      filterData({
        filterId: data,
      })
    );
  };

  const handleClearAll = () => {
    dispatch(
      filterData({
        filterId: null,
      })
    );
    setSelected(0);
    setSubCat(0);
    setCountrySelected(0);
  };

  const getCountryData = async () => {
    try {
      const res = await getCountryDataMethod({}).unwrap();
      if (res?.code === 200) {
        setCountries(res?.data || []);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCountryData();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelected(Number(state));
    if (countryId) {
      setOpenSecond(true);
      setCountrySelected(Number(countryId));
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <List
        sx={{
          width: "100%",
          maxWidth: "100%",
          bgcolor: "background.paper",
        }}
      >
        {selected || countrySelected ? (
          <div onClick={() => handleClearAll()}>
            <h4 className="clear_all">Clear All</h4>
          </div>
        ) : null}

        <ListItemButton onClick={handleClick}>
          <ListItemText
            primary={Translation.sideBar.cat}
            className="boldhead"
          />
          {open ? <RemoveIcon /> : <AddIcon />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {categories?.length
              ? categories?.map((item) => {
                  return (
                    <div key={item?.id}>
                      <ListItemButton
                        // style={{ color: "red" }}
                        onClick={() => {
                          subClickHandler(item.id);
                          setSelected(item.id);
                          handleCatClick(item?.id);
                        }}
                        className={
                          selected === item?.id ? "headingtext" : "headingtext2"
                        }
                      >
                        <ListItemText
                          primary={item?.name || ""}
                          className="subcat"
                          // onClick={() =>}
                        />
                        {subVal.includes(item.id) ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemButton>
                      {subVal.includes(item.id) ? (
                        <>
                          {item?.sub_category?.length
                            ? item?.sub_category.map((value) => {
                                return (
                                  <div key={value?.id}>
                                    <Collapse
                                      in={subVal.includes(item?.id)}
                                      timeout="auto"
                                      unmountOnExit
                                    >
                                      <List component="div" disablePadding>
                                        <ListItemButton
                                          onClick={() => {
                                            handleSubCatClick(
                                              Number(value?.id)
                                            );
                                            setSubCat(Number(value?.id));
                                          }}
                                        >
                                          <ListItemText
                                            primary={value?.name}
                                            // className={"subtext"}
                                            className={
                                              subcat === value?.id
                                                ? "subtext2"
                                                : "subtext"
                                            }
                                          />
                                        </ListItemButton>
                                      </List>
                                    </Collapse>
                                  </div>
                                );
                              })
                            : null}
                        </>
                      ) : null}
                    </div>
                  );
                })
              : null}
          </List>
        </Collapse>
      </List>

      {/* cournty  */}
      <List
        sx={{
          width: "100%",
          maxWidth: "100%",
          bgcolor: "background.paper",
        }}
      >
        <ListItemButton onClick={() => handleCountries()}>
          <ListItemText
            primary={Translation.sideBar.country}
            className="boldhead"
          />
          {openSecond ? <RemoveIcon /> : <AddIcon />}
        </ListItemButton>
        <Collapse in={openSecond} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {countries?.length ? (
              countries?.map((a) => {
                return (
                  <>
                    <ListItemButton
                      onClick={() => handleCountryClick(a?.id)}
                      key={a?.id}
                      className={
                        countrySelected === a?.id
                          ? "headingtext"
                          : "headingtext2"
                      }
                    >
                      <ListItemText
                        primary={a?.name || ""}
                        className="subcat"
                      />
                    </ListItemButton>
                  </>
                );
              })
            ) : (
              <></>
            )}
          </List>
        </Collapse>
      </List>
    </div>
  );
};
export default SideBar;
