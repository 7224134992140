import { useState } from "react";
import { TextField } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";

const ManageAddressForm = () => {
  const [phoneCode, setPhoneCode] = useState("+91");

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      address: "",
      state: "",
      zipCode: "",
      phone: "",
      email: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("First name is required")
        .label("First name")
        .min(3),
      address: Yup.string()
        .required("Address is required.")
        .label("Address")
        .min(3),
      state: Yup.string().required("State is required.").label("State").min(3),
      zipCode: Yup.string()
        .required("Zip code is required.")
        .label("Zip code")
        .min(3)
        .max(6),
      phone: Yup.string()
        .required("Phone number is required")
        .matches(/^\d{8,}$/, "Invalid phone number"),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
    }),
    onSubmit: (value) => {
      formik.setSubmitting(true);
      console.log(value);
    },
  });
  return (
    <div className="address-form-wrapper">
      <form onSubmit={formik.handleSubmit}>
        <div className="inputdiv">
          <div className="firstname">
            <div className="fdiv">
              <TextField
                id="firstName"
                name="firstName"
                placeholder="First Name"
                fullWidth
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </div>
            <div className="fdiv">
              <TextField
                placeholder="Last Name"
                fullWidth
                id="lastName"
                name="lastName"
              />
            </div>
          </div>
          <TextField
            id="address"
            name="address"
            placeholder="address"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            helperText={formik.touched.address && formik.errors.address}
          />
          <TextField
            id="state"
            name="state"
            placeholder="State"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.state}
            helperText={formik.touched.state && formik.errors.state}
          />
          <TextField
            id="zipCode"
            name="zipCode"
            placeholder="Zip Code"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.zipCode}
            helperText={formik.touched.zipCode && formik.errors.zipCode}
          />
          <PhoneInput
            enableSearch={true}
            country={"ae"}
            inputClass="phoneInput"
            buttonClass="phoneBtn"
            placeholder="phone number"
            value={phoneCode + formik.values.phone}
            onChange={(phone, country) => handleChangePhone(phone, country)}
            // onChange={(value) => formik.setFieldValue("phone", value)}
            onBlur={formik.handleBlur}
            inputStyle={{ marginBottom: "10px" }}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <p className="errMsg">{formik.errors.phone}</p>
          ) : (
            ""
          )}
          <TextField
            id="email"
            name="email"
            className="adornment"
            placeholder="Email"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            helperText={formik.touched.email && formik.errors.email}
          />
          <div className="btn">
            <div className="btnddiv">
              <button type="submit" disabled={formik.isSubmitting}>
                Edit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ManageAddressForm;
