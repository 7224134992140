export const en = {
  globals: {
    save: "8591 Ramblewood St. Lower Manhattan..",
    publish: "Publish",
    cancel: "Cancel",
    contact: "Contact Now",
    cancelOrdr: "Cancel Order",
    mngAddress: "Edit",
    profileBtn: "Edit Profile",
    footer: "Sign Up",
  },
  HomeSlider: {
    consumer: "Consumer Goods",
    equipment: "Heavy Equipment",
    textiles: "Textiles",
    commercial: "Commercial Equipment",
  },
  checkout: {
    billingdetail: "Billing Details",
    YourOder: "Your Order",
    paymentInfo: "Payment Information",
  },
  privatePolicy: {
    heading: "Privacy Policy",
    policyData:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for ‘lorem ipsum’ will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
    personalData: "Personal Data Processing",
    data1: "1. Data Controller",
    content1:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    data2: "2. Website Use Under Terms And Conditions",
    content2:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    data3: "3. Our Purposes And Lawful Basis Of Data We Collect",
    content3:
      "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  aboutUs: {
    bannerHead: "What is e nilcon?",
    button: "Get Started",
    aboutHead: "About e nilcon",
    content:
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using ‘Content here, content here’, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for ‘lorem ipsum’ will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
  },

  seller: {
    dashboard: "Dashboard",
    ManageOrder: "Manage Orders",
    mangaeproduct: "Manage Products",
    inventory: "Inventory Management",
    report: "Report Management",
    account: "Account Settings",
    info: "Personal info",
    address: "Manage Addresses",
    changePass: "Change Password",
    logout: "Log out",
  },
  dashboardMain: {
    dashboard: "Dashboard",
    orders: "Orders",
    products: "Products",
  },
  manageAddress: {
    manage: "Manage Address",
  },
  changePass: {
    change: "Change Password",
  },
  addProduct: {
    bulk: "Bulk Uploading",
    productId: "Product ID",
    uploadimgs: "Upload images",
    pName: "Product Name",
    pCategory: "Product category",
    subCate: "Sub Category",
    proCost: "Product Cost",
    ProQuan: "Product Quantity",
    countryOrigin: "Country of Origin",
    ProLocation: "Country of Origin",
    size: "Size",
    specification: "Short Description",
    description: "Description",
    meritaltest: "Material Test Certificates",
    enterproductid: "Enter Product ID",
    entrProductName: "Enter Product Name",
    enterProCost: "Enter Product Cost",
    enterLocation: "Enter Location",
    entersize: "Enter Size",
    enterspecification: "Enter Specifications",
    enterDes: "Specification/Material Description",
  },
  faq: {
    heading: "FAQ's",
  },
  contactUs: {
    heading: "Contact Us",
    para: " Lorem Ipsum has been the industry's standard dummy text ever sincethe 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    address: "8591 Ramblewood St. Lower Manhattan..",
    email: "Email: info@enilcon.com",
    phone: "Contact number: 240 3335",
    help: "How can we help?",
  },
  myOrders: {
    btn: " On-Going Orders",
    pastBtn: "Past Orders",
  },
  sideBar: {
    cat: "Categories",
    country: "Country of origin",
  },
  footer: {
    subs: "Subscribe To Our Newsletter",
    address: "Address",
    phone: "Contact number",
    service: "Services",
    pages: "Pages",
    links: "Quick Links",
  },
  manageOrder: {
    Showing: "Showing",
    from: "from",
    data: "data",
  },
};
