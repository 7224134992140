import { useMemo } from "react";
import { useAppSelector } from "./Store";
import { getCookieStatus } from "../reducers/commonSlice";

const useCookie = () => {
  const cookie = useAppSelector(getCookieStatus);
  return useMemo(() => cookie, [cookie]);
};

export default useCookie;
