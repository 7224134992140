import { Navigate } from "react-router-dom";
import { getFromStorage } from "../../contansts";
import { STORAGE_KEYS } from "../../contansts/Storage";

const ProtectedRoutes = ({ children }: any) => {
  if (getFromStorage(STORAGE_KEYS.token)) {
    return children;
  }
  return <Navigate to="/" replace />;
};

export default ProtectedRoutes;
