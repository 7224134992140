import React, { useEffect, useState } from "react";
import useTranslation from "../../hooks/Translation";
import "./ManageAddress.scss";
import { TextField } from "@mui/material";
import "../../pages/profile/Profile.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { isString } from "../../utils/validations";
import {
  useDeleteAddressByIdMutation,
  useLazyGetAddressByIdQuery,
  useLazyGetAddressesQuery,
  usePostAddAddressMutation,
  useUpdateAddressMutation,
} from "../../service/Address";
import { Loader, errorToast, successToast } from "../../helpers";
import { ManageAddressType } from "../../types/User";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Box, Modal } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@mui/material";

const ManageAddress = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const translation = useTranslation() as any;
  const [phoneCode, setPhoneCode] = useState("+91");
  const [isAdd, setIsAdd] = useState<boolean>(true);
  const [getAddresses, setGetAddresses] = useState<ManageAddressType[]>([]);
  const [selectedId, setSelectedId] = useState<number>();
  const [selectedData, setSelectedData] = useState<ManageAddressType | null>();
  const [item, setItem] = useState<number>();
  const [index, setIndex] = useState<number>();

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const [getAllAddressMethod] = useLazyGetAddressesQuery();
  const [addAddressMethod] = usePostAddAddressMutation();
  const [getAddressByIdMethod] = useLazyGetAddressByIdQuery();
  const [updateAddressMethod] = useUpdateAddressMutation();
  const [deleteAddressMethod] = useDeleteAddressByIdMutation();

  const style = {
    position: "absolute" as "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    outline: "none",
    p: 4,
    borderRadius: 4,
    width: isSmallScreen ? "77%" : "400px",
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: selectedData?.first_name || "",
      address: selectedData?.address || "",
      state: selectedData?.state || "",
      zipCode: selectedData?.zipcode || "",
      phone: selectedData?.phone_no || "",
      // email: "",
      lastName: selectedData?.last_name || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("First name is required")
        .label("First name")
        .min(3, "First name must include at least 3 characters")
        .max(40, "First name must not be more than 40 characters"),
      lastName: Yup.string()
        .label("First name")
        .min(3, "Last name must include at least 3 characters")
        .max(40, "Last name must not be more than 40 characters"),
      address: Yup.string()
        .required("Address is required.")
        .label("Address")
        .min(3, "Address must include at least 3 characters"),
      state: Yup.string()
        .required("State is required.")
        .label("State")
        .min(3, "State must include at least 3 characters"),
      zipCode: Yup.string()
        .required("Zip code is required.")
        .label("Zip code")
        .min(3, "Zip code must include at least 3 characters")
        .max(6, "Zip code must include at most 6 characters"),
      phone: Yup.string()
        .required("Phone number is required")
        .min(7, "Phone Number must be at least 7 characters"),
      // email: Yup.string()
      //   .required("Email is required")
      //   .matches(
      //     /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
      //     "Enter a valid email address"
      // ),
    }),
    onSubmit: async (value: any) => {
      formik.setSubmitting(true);
      console.log(value);
      let body = {
        first_name: formik.values.firstName,
        last_name: formik.values.lastName,
        address: formik.values.address,
        state: formik.values.state,
        zipcode: formik.values.zipCode,
        country_code: phoneCode,
        // country_iso_code: coun,
        phone_no: formik.values.phone,
        latitude: "30.704649",
        longitude: "76.717873",
      };
      setLoading(true);
      try {
        if (isAdd) {
          const res = await addAddressMethod(body).unwrap();
          setLoading(false);
          if (res?.code === 201) {
            if (res?.data) {
              setGetAddresses([res?.data, ...getAddresses]);
              successToast(res?.message || "");
              // formik.resetForm();
              setIsAdd(true);

              if (state === "address") {
                formik.resetForm();
                navigate("/checkout");
              } else {
                formik.resetForm();
              }
            }
          }
        } else {
          const res = await updateAddressMethod({
            body,
            address_id: selectedId,
          }).unwrap();
          setLoading(false);
          if (res?.code === 200 || res?.code === 201) {
            if (res?.data) {
              setSelectedData(res?.data);
              setSelectedId(selectedId);
              const response = await getAllAddressMethod({}).unwrap();
              setLoading(false);
              if (response?.code === 200) {
                setGetAddresses(response?.data || []);
              }

              successToast(res?.message || "");

              formik.resetForm();
              handleResetForm();
              console.log("navigate");

              // navigate("/checkout");
            }
          }
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        setLoading(false);
      }

      formik.setSubmitting(false);
    },
  });

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
  };

  const getAllAddressData = async () => {
    setLoading(true);
    try {
      const res = await getAllAddressMethod({}).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        setGetAddresses(res?.data || []);
        if (res?.data?.length && !isAdd) {
          setSelectedId(res?.data[0]?.id);
          setSelectedData(res?.data[0]);
        }
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  const handleEditAddress = async (id: number) => {
    setLoading(true);
    try {
      const res = await getAddressByIdMethod({ address_id: id }).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        setSelectedData(res?.data);
        setSelectedId(id);
        setIsAdd(false);
        setSelectedData(res?.data);
        setPhoneCode(res?.data?.country_code || "+91");
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleDeleteAddress = async (index: number, id: number) => {
    setLoading(true);
    try {
      const res = await deleteAddressMethod({ address_id: id }).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        successToast(res?.message || "");
        let arr = [];
        arr.push(
          ...getAddresses.slice(0, index),
          ...getAddresses.slice(index + 1)
        );
        setGetAddresses(arr);
        handleResetForm();
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllAddressData();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  const handleResetForm = () => {
    formik.setFieldValue("firstName", "");
    formik.setFieldValue("lastName", "");
    formik.setFieldValue("address", "");
    formik.setFieldValue("state", "");
    formik.setFieldValue("zipCode", "");
    formik.setFieldValue("phone", "");
    setPhoneCode("+91");
    setIsAdd(true);
    setSelectedId(undefined);
  };

  const handleDelete = (index: number, id: number) => {
    setOpen(true);
    setIndex(index);
    setItem(id);
  };

  return (
    <div className="manageAddress profilemain">
      <Loader isLoad={loading} />
      <form onSubmit={formik.handleSubmit}>
        <div className="addHead">
          <h3>{translation.manageAddress.manage}</h3>

          <div className="addBtn">
            {selectedId ? (
              <div className="btn">
                <div className="btnddiv">
                  <button
                    type="button"
                    onClick={() => {
                      handleResetForm();
                      setSelectedData(null);
                    }}
                  >
                    + Add New
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="addrData">
          {getAddresses?.length ? (
            getAddresses?.map((items, index) => (
              <ul
                className={selectedId === items?.id ? "brdr_add" : ""}
                key={items?.id}
              >
                <div className="icon_div">
                  <BorderColorIcon
                    sx={{ fontSize: 15, color: "#61439F" }}
                    onClick={() => handleEditAddress(items?.id)}
                  />
                  <DeleteIcon
                    style={{ color: "red" }}
                    onClick={() => handleDelete(index, items?.id)}
                  />
                </div>

                <li>
                  {items?.first_name || ""}
                  {items?.last_name ? " " + items?.last_name : ""}
                </li>
                <li>{items?.address || ""}</li>
                <li>Phone No.{items?.phone_no || ""}</li>
              </ul>
            ))
          ) : (
            <></>
          )}
        </div>

        <div className="inputdiv">
          <div className="firstname">
            <div className="fdiv">
              <TextField
                id="firstName"
                name="firstName"
                className="text_field"
                placeholder="First Name"
                fullWidth
                onChange={(val: any) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </div>
            <div className="fdiv">
              <TextField
                placeholder="Last Name"
                fullWidth
                id="lastName"
                className="text_field"
                name="lastName"
                onChange={(val: any) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                value={formik.values.lastName}
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </div>
          </div>
          <TextField
            id="address"
            name="address"
            placeholder="Address"
            className="text_field"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            helperText={formik.touched.address && formik.errors.address}
          />
          <TextField
            id="state"
            name="state"
            placeholder="State"
            className="text_field"
            fullWidth
            onChange={(val: any) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else if (isString(val.target.value)) {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            value={formik.values.state}
            helperText={formik.touched.state && formik.errors.state}
          />
          <TextField
            id="zipCode"
            name="zipCode"
            placeholder="Zip Code"
            className="text_field"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.zipCode}
            helperText={formik.touched.zipCode && formik.errors.zipCode}
          />
          <PhoneInput
            enableSearch={true}
            country={"ae"}
            inputClass="phoneInput"
            buttonClass="phoneBtn"
            placeholder="phone number"
            value={phoneCode + formik.values.phone}
            onChange={(phone, country) => handleChangePhone(phone, country)}
            onBlur={formik.handleBlur}
            inputStyle={{ marginBottom: "10px" }}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <p className="errMsg">{formik.errors.phone}</p>
          ) : (
            ""
          )}
          <div className="btn">
            <div className="btnddiv">
              <button type="submit" disabled={formik.isSubmitting}>
                {isAdd ? "Add" : "Update"}
              </button>
            </div>
          </div>
        </div>
      </form>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="warning-modal">
            <h3>Are you sure, you want to remove this address?</h3>
            <div className="btn-div">
              <button
                className="yes"
                onClick={() => {
                  setOpen(false);
                  handleDeleteAddress(Number(index), Number(item));
                }}
              >
                Yes
              </button>
              <button className="no" onClick={() => setOpen(false)}>
                No
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
export default ManageAddress;
