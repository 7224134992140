import { Checkbox, IconButton, InputAdornment, TextField } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { usePostLoginMutation } from "../../service/Auth";
import {
  STORAGE_KEYS,
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from "../../contansts/Storage";
import Loader from "../../helpers/Loader";
import { errorToast, successToast } from "../../helpers/showToast";
import { useAppDispatch } from "../../hooks/Store";
import { setCredentials } from "../../reducers/authSlice";

type LoginProps = {
  setActiveModal: Dispatch<SetStateAction<any>>;
  closeModal: () => void;
};

const LoginModal = ({ closeModal, setActiveModal }: LoginProps) => {
  const dispatch = useAppDispatch();
  const [LoginMutation, LoginMutationData] = usePostLoginMutation();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      password: Yup.string()
        .required("Password is required!")
        .matches(
          /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
          "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character. "
        ),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        email: formik.values.email,
        password: formik.values.password,
      };
      if (formik.values.remember) {
        setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
      } else {
        removeFromStorage(STORAGE_KEYS.credentials);
      }
      try {
        const response = await LoginMutation(body).unwrap();
        if (response?.code === 200) {
          successToast(response?.message || "");
          setToStorage(
            STORAGE_KEYS.token,
            JSON.stringify(response?.data?.token || "")
          );
          setToStorage(
            STORAGE_KEYS.userData,
            JSON.stringify(response?.data || "")
          );
          dispatch(
            setCredentials({
              token: response?.data?.token || "",
              user: response?.data || null,
            })
          );
          closeModal();
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    },
  });

  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);

    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.email);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loader isLoad={LoginMutationData.isLoading} />
      <div className="loginmodal">
        <h2>Welcome back!</h2>
        <p>Please login to your account.</p>
        <form onSubmit={formik.handleSubmit}>
          <div className="inputs">
            <TextField
              className="text_field"
              size="small"
              placeholder="Email Id"
              fullWidth
              id="email"
              name="email"
              type="text"
              // onChange={formik.handleChange}
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else {
                  formik.handleChange(val);
                }
              }}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              className="text_field adornment"
              size="small"
              placeholder="Password"
              fullWidth
              sx={{ marginTop: "18px" }}
              id="password"
              name="password"
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else {
                  formik.handleChange(val);
                }
              }}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              helperText={formik.touched.password && formik.errors.password}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="iconador">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className="fordiv">
              <div className="checkbox">
                <Checkbox
                  checked={formik.values.remember}
                  name="remember"
                  onChange={formik.handleChange}
                />
                <h5>Remember me</h5>
              </div>
              <h4 onClick={() => setActiveModal(3)}>Forgot Password?</h4>
            </div>
            <div className="btnddiv">
              <button type="submit" disabled={formik.isSubmitting}>
                Login
              </button>
            </div>
          </div>
        </form>
        <h3>
          Don't Have an Account?{" "}
          <span onClick={() => setActiveModal(2)}>Sign Up</span>
        </h3>
      </div>
    </>
  );
};

export default LoginModal;
