import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Checkbox } from "@mui/material";
import { useLazyGetRolesPermissionsQuery } from "../../../service/sellerPanel/Permissions";
import { RoleData } from "../../../types/General";
import { Loader } from "../../../helpers";
import { PermissionEnums } from "../../../contansts/permissions";

const data = [
  { id: PermissionEnums.addProduct, name: "Add Product" },
  { id: PermissionEnums.editProduct, name: "Edit Product" },
  { id: PermissionEnums.markProductObsolete, name: "Mark product obsolete" },
  { id: PermissionEnums.changeCompanyInfo, name: "Change company info" },
  { id: PermissionEnums.viewOrderStatus, name: "View order status" },
  { id: PermissionEnums.buyProducts, name: "Buy Products" },
  { id: PermissionEnums.writeReviews, name: "Write reviews" },
  {
    id: PermissionEnums.changeShippingAddress,
    name: "Change shipping address",
  },
];

interface TableRowType {
  role_id: number;
  module_id: number[];
}

type ManageProps = {
  tableData: TableRowType[];
  setTableData: Dispatch<SetStateAction<any>>;
};

const PermissionTable = ({ tableData, setTableData }: ManageProps) => {
  const [getRoles, setGetRoles] = useState<RoleData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [getRolesMethod] = useLazyGetRolesPermissionsQuery();

  const getRolesData = async () => {
    try {
      setLoading(true);
      const res = await getRolesMethod({}).unwrap();
      setLoading(false);
      if (res?.code === 200 || res?.code === 201) {
        setGetRoles(res?.data || []);
        const desiredArray = res?.data?.map((item) => {
          return {
            role_id: item?.id,
            module_id: item?.permissions?.length
              ? item?.permissions?.map((permission: string) =>
                  parseInt(permission)
                )
              : [],
          };
        });
        setTableData(desiredArray);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const selectCheckBox = (roleId: number, moduleId: number) => {
    setTableData((prevTableData: any) => {
      const updatedData = [...prevTableData];
      const index = updatedData.findIndex((row) => row.role_id === roleId);

      if (index > -1) {
        const moduleIndex = updatedData[index].module_id.indexOf(moduleId);

        if (moduleIndex > -1) {
          updatedData[index].module_id.splice(moduleIndex, 1);
        } else {
          updatedData[index].module_id.push(moduleId);
        }
      } else {
        updatedData.push({
          role_id: roleId,
          module_id: [moduleId],
        });
      }

      return updatedData;
    });
  };

  useEffect(() => {
    getRolesData();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableContainer component={Paper} className="rolesTable">
      <Loader isLoad={loading} />
      <Table
        sx={{ minWidth: 650 }}
        aria-label="simple table"
        className="tablecon"
      >
        <TableHead>
          <TableRow className="tablerw">
            <TableCell>Permissions</TableCell>

            {getRoles?.length
              ? getRoles?.map((item) => (
                  <TableCell key={item?.id} align="center">
                    {item?.name || ""}
                  </TableCell>
                ))
              : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, i) => (
            <TableRow
              key={i}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {item?.name}
              </TableCell>
              {getRoles?.length
                ? getRoles?.map((item, index) => (
                    <TableCell
                      onClick={() => {
                        selectCheckBox(item?.id, i + 1);
                      }}
                      key={item?.id}
                      align="center"
                    >
                      <Checkbox
                        checked={tableData.some(
                          (row) =>
                            row?.role_id === item?.id &&
                            row?.module_id.includes(i + 1)
                        )}
                      />
                    </TableCell>
                  ))
                : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PermissionTable;
