import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import "../../layout/Layout.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import useAuth from "../../hooks/UseAuth";
import { usePutUpdateProfileMutation } from "../../service/Auth";
import { UploadMedia } from "../../utils/mediaUpload";
import { ImageResponse } from "../../types/User";
import { Loader, errorToast, successToast } from "../../helpers";
import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/Store";
import { getFromStorage } from "../../contansts";
import { STORAGE_KEYS } from "../../contansts/Storage";
import { isString } from "../../utils/validations";
import { generateEncryptedKeyBody } from "../../utils/encryption";
import { generateDecryptedBody } from "../../utils/decryption";

const PersonalInfo = () => {
  const userData = useAuth();
  const dispatch = useAppDispatch();

  const token = getFromStorage(STORAGE_KEYS.token);

  const [image, setImage] = useState<ImageResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  const [putUpdateMethod] = usePutUpdateProfileMutation();

  const formik = useFormik({
    initialValues: {
      firstName: userData?.first_name || "",
      lastName: userData?.last_name || "",
      phone: userData?.office_phone_no || "",
      jobTitle: userData?.job_title || "",
      companyName: userData?.company_name || "",
      email: userData?.business_email || "",
      address: userData?.location || "",
      companyNumber: userData?.company_phone_no || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("First name is required")
        .min(2, "Minimum 2 characters are required"),
      lastName: Yup.string().min(2, "Minimum 2 characters are required"),
      jobTitle: Yup.string().min(2, "Minimum 2 characters are required"),
      address: Yup.string().min(2, "Minimum 2 characters are required"),
      // designation: Yup.string().required("Designation is required"),
      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
      companyNumber: Yup.string()
        .required("Company phone number is required")
        .min(6, "Company phone number must be at least 6 characters")
        .max(16, "Company phone number must be at least 16 characters"),
      // phone: Yup.string()
      //   .required("Office phone number is required")
      //   .matches(/^\d{10,}$/, "Enter valid number"),
      // designation: Yup.string().required("Designation is required"),
      // companyName: Yup.string().required("Company name is required"),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      // companyNumber: Yup.string()
      //   .required("Company phone number is required")
      //   .min(6, "Company phone must be at least 6 characters")
      //   .max(16, "Company phone must be at least 16 characters"),
    }),
    onSubmit: async (value) => {
      console.log("hiii");

      formik.setSubmitting(true);
      console.log(value);

      let body = {
        first_name: formik.values.firstName,
        last_name: formik.values.lastName,
        job_title: formik.values.jobTitle,
        location: formik.values.address,
        image: image?.id || "",
      };

      // const data = generateEncryptedKeyBody(body);
      // console.log(data, "boddddyy personal");

      // const decrypt = generateDecryptedBody(data?.sek, data?.hash);
      // console.log(decrypt, "decrypt");

      try {
        setLoading(true);
        const res = await putUpdateMethod(body).unwrap();
        setLoading(false);
        if (res?.code === 200) {
          dispatch(
            setCredentials({
              user: res?.data || null,
              token: JSON.parse(`${token}`) || null,
            })
          );
          successToast("Profile updated successfully");
        }
      } catch (error: any) {
        console.log(error);
        setLoading(false);
        errorToast(error?.data?.message || "");
      }

      formik.setSubmitting(false);
    },
  });

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
  };

  const handleChangeCompanyPhone = (phone: any, country: any) => {
    formik.setFieldValue("companyNumber", phone?.replace(country.dialCode, ""));
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";

    setLoading(true);
    const res = await UploadMedia(file);
    setLoading(false);
    if (res?.code === 200) {
      setImage(res?.data[0]);
    } else {
      errorToast(res?.message);
    }
  };

  useEffect(() => {
    if (userData) {
      setImage(userData?.image);
    }
  }, [userData]);

  return (
    <div className="profilemain info">
      <form onSubmit={formik.handleSubmit}>
        <Loader isLoad={loading} />
        <div className="pImg">
          <figure>
            <img
              src={
                image ? image?.media_file_url : "/static/images/profilepic.png"
              }
              alt="Profile"
            />
            <label htmlFor="icon-button-file">
              <div className="uploader">
                <TextField
                  inputProps={{
                    accept: "image/*",
                  }}
                  id="icon-button-file"
                  type="file"
                  sx={{ display: "none" }}
                  onChange={handleImageUpload}
                />
                <div className="edit">
                  <ModeEditIcon />
                </div>
                {/* <input type="file" id="myfile" name="myfile" /> */}
              </div>
            </label>
          </figure>

          <h3>{userData?.first_name + " " + userData?.last_name || ""}</h3>
        </div>

        <div className="inputdiv">
          <div className="firstname">
            <div className="fdiv">
              <h4>First Name*</h4>
              <TextField
                className="text_field"
                id="firstName"
                name="firstName"
                placeholder="First Name"
                inputProps={{ maxLength: 20 }}
                fullWidth
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
            </div>
            <div className="fdiv">
              <h4>Last Name</h4>
              <TextField
                className="text_field"
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                inputProps={{ maxLength: 15 }}
                fullWidth
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                helperText={formik.touched.lastName && formik.errors.lastName}
                value={formik.values.lastName}
              />
            </div>
          </div>
          <div className="fdiv">
            <h4>Business Email Address*</h4>
            <TextField
              className="text_field"
              id="email"
              name="email"
              placeholder="Business Email Address*"
              fullWidth
              onChange={formik.handleChange}
              disabled
              onBlur={formik.handleBlur}
              value={formik.values.email}
              helperText={formik.touched.email && formik.errors.email}
            />
          </div>
          <div className="fdiv">
            <h4>Phone Number*</h4>
            <PhoneInput
              enableSearch={true}
              country={"us"}
              inputClass="phoneInput"
              buttonClass="phoneBtn"
              placeholder="Phone number"
              value={userData?.office_country_code + formik.values.phone}
              // disableCountryCode
              disableDropdown
              disabled
              onChange={(phone, country) => handleChangePhone(phone, country)}
              onBlur={formik.handleBlur}
              inputStyle={{ marginBottom: "10px" }}
            />
          </div>

          {formik.touched.phone && formik.errors.phone ? (
            <p className="errMsg">{formik.errors.phone}</p>
          ) : null}
          <div className="fdiv">
            <h4>Job Title</h4>
            <TextField
              className="text_field"
              id="jobTitle"
              name="jobTitle"
              placeholder="Job Title (optional)"
              fullWidth
              inputProps={{ maxLength: 50 }}
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else if (isString(val.target.value)) {
                  formik.handleChange(val);
                }
              }}
              onBlur={formik.handleBlur}
              value={formik.values.jobTitle}
              helperText={formik.touched.jobTitle && formik.errors.jobTitle}
            />
          </div>
          <div className="fdiv">
            <h4>Name of the company</h4>
            <TextField
              className="text_field"
              id="companyName"
              name="companyName"
              placeholder="Name of the company (optional)"
              fullWidth
              inputProps={{ maxLength: 50 }}
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else if (isString(val.target.value)) {
                  formik.handleChange(val);
                }
              }}
              disabled
              onBlur={formik.handleBlur}
              value={formik.values.companyName}
              helperText={
                formik.touched.companyName && formik.errors.companyName
              }
            />
          </div>
          <div className="fdiv">
            <h4>Company Phone Number*</h4>
            <PhoneInput
              enableSearch={true}
              country={"us"}
              inputClass="phoneInput"
              buttonClass="phoneBtn"
              placeholder="Company phone number"
              // disableCountryCode
              disableDropdown
              disabled
              value={
                userData?.company_country_code + formik.values.companyNumber
              }
              onChange={(phone, country) =>
                handleChangeCompanyPhone(phone, country)
              }
              onBlur={formik.handleBlur}
              inputStyle={{ marginBottom: "10px" }}
            />
          </div>

          {formik.touched.companyNumber && formik.errors.companyNumber ? (
            <p className="errMsg">{formik.errors.companyNumber}</p>
          ) : null}
          <div className="fdiv">
            <h4>Location*</h4>
            <TextField
              className="text_field adornment"
              id="address"
              name="address"
              placeholder="Location (optional)"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="iconador">
                    <figure style={{ width: "21px", height: "21px" }}>
                      <img src="/static/images/send.png" alt="send"></img>
                    </figure>
                  </InputAdornment>
                ),
              }}
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else if (isString(val.target.value)) {
                  formik.handleChange(val);
                }
              }}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              helperText={formik.touched.address && formik.errors.address}
            />
          </div>
          <div className="btn">
            <div className="btnddiv">
              <button type="submit" disabled={formik.isSubmitting}>
                Update Profile
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PersonalInfo;
