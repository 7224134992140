import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import "./ManageAddress.scss";
import "../../pages/profile/Profile.scss";
import useTranslation from "../../hooks/Translation";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Loader, errorToast, successToast } from "../../helpers";
import { usePostChangePasswordMutation } from "../../service/Auth";

const ChangePassword = () => {
  const translation = useTranslation() as any;

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<boolean>(false);
  const [cnfmPassword, setCnfmPassword] = useState<boolean>(false);

  const [ChangePasswordMutation, { isLoading }] =
    usePostChangePasswordMutation();

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      passwordConfirmation: "",
      oldPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .label("Old Password")
        .required("Old password is required.")

        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "Must contain 8 or more characters, one uppercase, one lowercase and one number. "
        ),
      newPassword: Yup.string()
        .label("new Password")
        .required("New password is required.")
        .notOneOf(
          [Yup.ref("oldPassword"), null],
          "New password cannot be same as old password."
        )
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "Must contain 8 or more characters, one uppercase, one lowercase and one number. "
        ),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match.")
        .required("Confirm password is required."),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      let body = {
        new_password: formik.values.newPassword,
        current_password: formik.values.oldPassword,
      };
      console.log(body, "body");

      try {
        const response = await ChangePasswordMutation(body).unwrap();
        if (response?.code === 200) {
          successToast(response?.message || "");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error, "errorrr");
      }
      formik.setSubmitting(false);
    },
  });

  return (
    <div className="changePass profilemain">
      <Loader isLoad={isLoading} />
      <h3>{translation.changePass.change}</h3>

      <div className="inputdiv">
        <form onSubmit={formik.handleSubmit}>
          <TextField
            className="text_field adornment"
            placeholder="Old Password"
            fullWidth
            id="oldPassword"
            name="oldPassword"
            type={oldPassword ? "text" : "password"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.oldPassword}
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className="iconador">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setOldPassword(!oldPassword)}
                    onMouseDown={(event) => event.preventDefault()}
                    edge="end"
                  >
                    {oldPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className="text_field adornment"
            placeholder="New Password"
            fullWidth
            id="newPassword"
            name="newPassword"
            type={cnfmPassword ? "text" : "password"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className="iconador">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setCnfmPassword(!cnfmPassword)}
                    onMouseDown={(event) => event.preventDefault()}
                    edge="end"
                  >
                    {cnfmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className="text_field adornment"
            placeholder="Confirm Password"
            fullWidth
            id="passwordConfirmation"
            name="passwordConfirmation"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.passwordConfirmation}
            helperText={
              formik.touched.passwordConfirmation &&
              formik.errors.passwordConfirmation
            }
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className="iconador">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(event) => event.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className="btn">
            <div className="btnddiv">
              <button type="submit" disabled={formik.isSubmitting}>
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
