import { useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import "./Layout.scss";
import useCookie from "../hooks/UseCookie";
import { hideCookie, showCookie } from "../utils/cookie";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const Layout = ({ children }: { children: any }) => {
  const [cookies, setCookie] = useCookies(["CRT"]);

  const navigate = useNavigate();
  const cookiesPresent = useCookie();

  const checkCookie = () => {
    const cookie = cookies?.CRT || null;

    if (cookie) {
      hideCookie();
    } else {
      setTimeout(() => {
        showCookie();
      }, 2000);
    }
  };

  useEffect(() => {
    checkCookie();
  }, []);

  return (
    <div className="mn_lyout">
      <Header />
      {children}
      <Footer />
      {!cookiesPresent ? (
        <div className="cookie">
          <p>
            We use cookies to ensure you have the best browsing experience on
            our website. By using our site, you acknowledge that you have read
            and understood our{" "}
            <span onClick={() => navigate("/privacy-policy")}>
              Cookie Policy
            </span>{" "}
            &{" "}
            <span onClick={() => navigate("/privacy-policy")}>
              Privacy Policy
            </span>
          </p>
          <button
            onClick={() => {
              setCookie("CRT", JSON.stringify([]), { path: "/" });
              hideCookie();
            }}
          >
            Got it!
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default Layout;
