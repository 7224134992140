import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../hooks/Store";
import { getTempToken, temporaryToken } from "../../reducers/authSlice";
import { useResetPasswordMutation } from "../../service/Auth";
import { errorToast, successToast } from "../../helpers/showToast";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import { Loader } from "../../helpers";
const ResetPassword = ({ closeModal }: { closeModal: () => void }) => {
  const dispatch = useAppDispatch();
  const encoded_id = useAppSelector(getTempToken);

  const [oldPassword, setOldPassword] = useState<boolean>(false);
  const [cnfmPassword, setCnfmPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [ResetPasswordMutation] = useResetPasswordMutation();
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .label("new Password")
        .required("New password is required.")
        .notOneOf(
          [Yup.ref("password"), null],
          "New password cannot be same as old password."
        )
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "Must contain 8 or more characters, one uppercase, one lowercase and one number. "
        ),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match.")
        .required("Confirm password is required."),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        encoded_id: encoded_id,
        password: formik.values.newPassword,
      };
      console.log(body, "body");
      try {
        setLoading(true);
        const response = await ResetPasswordMutation(body).unwrap();
        setLoading(false);
        if (response?.code === 200) {
          successToast(response?.message || "");
          closeModal();
          dispatch(
            temporaryToken({
              tempToken: null,
            })
          );
        }
      } catch (error: any) {
        setLoading(false);
        errorToast(error?.data?.message || "");
        console.log(error, "errorrr");
      }
      formik.setSubmitting(false);
    },
  });
  return (
    <div className="loginmodal">
      <Loader isLoad={loading} />
      <h2>Reset Password</h2>
      <div className="inputs">
        <form onSubmit={formik.handleSubmit}>
          <TextField
            className="text_field adornment"
            placeholder="New Password"
            fullWidth
            id="newPassword"
            name="newPassword"
            type={oldPassword ? "text" : "password"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className="iconador">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setOldPassword(!oldPassword)}
                    onMouseDown={(event) => event.preventDefault()}
                    edge="end"
                  >
                    {oldPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className="text_field adornment"
            style={{ marginTop: 12 }}
            placeholder="Confirm Password"
            fullWidth
            id="passwordConfirmation"
            name="passwordConfirmation"
            type={cnfmPassword ? "text" : "password"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.passwordConfirmation}
            helperText={
              formik.touched.passwordConfirmation &&
              formik.errors.passwordConfirmation
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className="iconador">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setCnfmPassword(!cnfmPassword)}
                    onMouseDown={(event) => event.preventDefault()}
                    edge="end"
                  >
                    {cnfmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className="btnddiv" style={{ marginTop: 20 }}>
            <button disabled={formik.isSubmitting}>Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ResetPassword;
