// export const API_URL = "https://devenilcon.webdevelopmentsolution.net/api/"; // development
export const API_URL = "https://enilcon.webdevelopmentsolution.net/api/"; // staging

export const END_POINTS = {
  login: "auth/login/",
  signUp: "auth/sign-up/",
  forgotPassword: "user/forgot-password/",
  otp_verify: "user/forgot-verify-otp/",
  resetPassword: "user/change-password/",
  changePassword: "user/update-password/",
  updateProfile: "user/update-profile/",
  getProfile: "get-profile-details-by-token/",
  logout: "user/logout",
  companyDomain: "company/search-by-name/",
  mediaUpload: "upload/media/",
  resendPassword: "user/send-otp-to-old-user/",
  signUp_otp_verify: "user/verify-otp/",

  //  address
  addAddress: "user/create-user-address-details/",
  updateAddress: "user/update-user-address-details/",
  getAllAddress: "user/get-all-user-address-details/",
  getAddressById: "user/get-user-address-details-by-id/",
  deleteAddress: "user/delete-user-address/",

  // home page api
  countryList: "countries/country-of-origin/",
  categorySubCategory: "categories/get-all-categories-and-subcategories/",
  rfpForm: "user/RFP-form/",
  latestProducts: "product/latest-products/",
  recommendedProducts: "product/recommended-products/",
  popularProducts: "product/recommended-products/",
  get_same_supplier: "product/same-suppliers/",
  similar_products: "product/similar-products/",
  getProductByIds: "product/get-product-by-id/2/",
  getAllProducts: "produt/get-website-products-with-pagination-for-all-users/",
  getDetailsOfProductInCart: "cart/get-cart-by-product-id/",
  bulkUploadProducts: "user/bulk-upload-products/",

  // cms api
  getCms: "cms/get-cms/",
  getFaq: "cms/get-faq/",
  contactUs: "user/contact-us-form/",

  // manage users
  getAllUsers: "users/get-all-website-users/",
  getUserById: "/users/get-website-user-by-id/",
  changeUserStatus: "/users/change-approved-status/",
  assignRole: "user/assign-role-to-website-user/",

  //manage products
  getManageProducts: "produt/get-all-website-products-with-pagination/",
  getBrands: "produt/get-all-brand-with-pagination/",
  getProductById: "product/get-product-by-id/",
  getAllCat: "produt/get-all-category-with-pagination/",
  getSubCat: "product/get-product-sub-category-by-category-id/",
  getAllCountries: "countries/get-all-countries-by-pagination/",
  addProduct: "product/create-product-from-website/",
  updateProduct: "product/update-product-from-website/",
  changeProductStatus: "product/change-product-status/",

  //cart
  add_to_cart: "cart/add-to-cart/",
  increase_quantity: "cart/increase-quantity-in-cart/",
  decrease_quantity: "cart/decrease-quantity-from-cart/",
  delete_cart: "cart/remove-from-cart/",
  view_cart: "cart/view-cart/",
  orderPlace: "cart/checkout/",
  payNow: "cart/change-payment-status/",

  // roles
  getRoles: "user/get-website-roles/",
  addRole: "user/create-website-permissions-role/",
  getRoleById: "user/get-website-role-by-id/",
  editRole: "user/edit-website-role-by-id/",
  changeRoleStatus: "user/change-website-role-status/",
  getRolesAndPermissions: "user/get-roles-and-permissions/",
  addRolesAndPermissions: "user/add-permissions-to-roles/",

  // dashboard
  dashboardCount: "website/get-dashboard-counts/",

  // manage orders
  getAllOrders: "order/get-order-details-pagination-on-website/",
  getManageOrderById: "order/get-ordered-product-details-by-id/",
  exportCsvOrders: "user/order-csv-download/",
  userCsv: "user/user-csv-download/",
  productCsv: "user/product-csv-download/",
  cancelOrder: "user/cancel-order-by-product-or-order/",

  //graph
  get_graph: "user/get-revenue-graph/",
  get_revenue: "user/website-order-revenue/",

  //rating
  add_rating: "rating/create-rating/",
  subscribe: "user/subscribe-to-news-letter/",
};
