import { Dispatch, SetStateAction } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CategoryResponse } from "../../types/General";
import { useNavigate } from "react-router-dom";
import { filterData } from "../../reducers/commonSlice";
import { useAppDispatch } from "../../hooks/Store";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";

type ManageProps = {
  categories: CategoryResponse[];
  selected: number;
  setSelected: Dispatch<SetStateAction<number>>;
  scrollPosition: ScrollPosition;
};

const HomeSlider = ({
  categories,
  setSelected,
  selected,
  scrollPosition,
}: ManageProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const pageLocation = window.location.pathname;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2.25,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const subClickHandler = (item: number) => {
    navigate("/products", { state: item });
  };

  const handleCatClick = (id: number) => {
    let data = {
      catId: id,
    };
    if (pageLocation === "/") {
      navigate("/products", { state: id });
    }
    dispatch(
      filterData({
        filterId: data,
      })
    );
  };

  return (
    <>
      <div className="slider-box">
        <h2>Categories</h2>
        {categories?.length ? (
          <Slider {...settings}>
            {categories?.map((item) => (
              <div
                key={item?.id}
                className="sldr-img"
                onClick={() => {
                  subClickHandler(item.id);
                  setSelected(item.id);
                  handleCatClick(item?.id);
                }}
              >
                <LazyLoadImage
                  alt={"Categories"}
                  height={"100%"}
                  src={
                    item?.category_image?.media_file_url ||
                    "/static/images/shopping.png"
                  }
                  width={"100%"}
                  effect="blur"
                  scrollPosition={scrollPosition}
                />
                <p>{item?.name || ""}</p>
              </div>
            ))}
          </Slider>
        ) : (
          <div className="no_data">
            <figure>
              <LazyLoadImage
                alt={"No Categories"}
                height={"100%"}
                src={"/static/images/no_pro.png"}
                width={"100%"}
                effect="blur"
                scrollPosition={scrollPosition}
              />
            </figure>
            <h3>No categories available</h3>
          </div>
        )}
      </div>
    </>
  );
};

export default trackWindowScroll(HomeSlider);
