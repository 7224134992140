import { END_POINTS } from "../contansts/Urls";
import { ManageAddressType } from "../types/User";
import emptySplitApi from "../utils/rtk";

type PostAddressBody = {
  first_name: string;
  last_name: string;
  address: string;
  state: string;
  zipcode: string;
  country_code: string;
  country_iso_code?: string;
  phone_no: string;
  latitude: string;
  longitude: string;
};

type UpdateAddressParams = {
  body: PostAddressBody;
  address_id?: number | undefined;
};

type AddressResponse = {
  code?: number;
  data?: ManageAddressType[];
  message: string;
};

type AddAddressResponse = {
  code?: number;
  data?: ManageAddressType;
  message: string;
};

export const addressApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postAddAddress: builder.mutation<AddAddressResponse, PostAddressBody>({
      query: (body) => ({
        url: END_POINTS.addAddress,
        method: "POST",
        body,
      }),
    }),

    updateAddress: builder.mutation<AddAddressResponse, UpdateAddressParams>({
      query: ({ body, address_id }) => ({
        url: `${END_POINTS.updateAddress}${address_id}/`,
        method: "PUT",
        body,
      }),
    }),

    getAddresses: builder.query<AddressResponse, unknown>({
      query: () => ({
        url: END_POINTS.getAllAddress,
        method: "GET",
      }),
    }),

    getAddressById: builder.query<
      AddAddressResponse,
      { address_id?: number | undefined }
    >({
      query: ({ address_id }) => ({
        url: `${END_POINTS.getAddressById}${address_id}/`,
        method: "GET",
      }),
    }),

    deleteAddressById: builder.mutation<
      AddressResponse,
      { address_id?: number | undefined }
    >({
      query: ({ address_id }) => ({
        url: `${END_POINTS.deleteAddress}${address_id}/`,
        method: "DELETE",
      }),
    }),
  }),
});
export const {
  usePostAddAddressMutation,
  useUpdateAddressMutation,
  useLazyGetAddressesQuery,
  useLazyGetAddressByIdQuery,
  useDeleteAddressByIdMutation,
} = addressApi;
