import { Dispatch, SetStateAction, useState } from "react";
import useTranslation from "../../hooks/Translation";
import { ProductDetail } from "../../types/General";
import moment from "moment";
import { Box, Modal } from "@mui/material";
import { Rating } from "../modals";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@mui/material";

type OrderProps = {
  active?: boolean;
  data: ProductDetail | undefined;
  OrderId: number | undefined;
  type: string | undefined;
  setType: Dispatch<SetStateAction<string | undefined>>;
  handleCancelOrder: (id: number, type: string) => void;
};

const OrderDetails = ({
  active,
  data,
  OrderId,
  handleCancelOrder,
  setType,
  type,
}: OrderProps) => {
  const [open, setOpen] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const [cancelOrderId, setCancelOrderId] = useState<number>();
  const [cancelProdId, setCancelProduId] = useState<number>();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute" as "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    outline: "none",
    p: 4,
    borderRadius: 4,
    width: isSmallScreen ? "70%" : "350px",
  };

  const translation = useTranslation() as any;

  return (
    <div className="orderDetails">
      <div className="card1">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            lineHeight: "22px",
          }}
        >
          <div className="order-id">
            <h3>Order ID : #{data?.order_id || OrderId}</h3>
            <p>{moment(data?.created_at).format("ddd, D MMM") || ""}</p>
          </div>
          <div className="details-status">
            {data?.delivery_status === 5 ||
            data?.delivery_status === 3 ? null : (
              <button
                className="cancel-btn2"
                onClick={() => {
                  setOpen(true);
                  setType("order");
                  setCancelOrderId(Number(data?.order_id));
                }}
              >
                {translation.globals.cancelOrdr}
              </button>
            )}
          </div>
        </div>
        <div className="dividr"></div>

        <div style={{ lineHeight: "25px" }}>
          <h5>Shipping Details</h5>
          <div style={{ marginTop: "10px" }} className="headd">
            <h6>
              {data?.user?.first_name || ""} {data?.user?.last_name || ""}
            </h6>

            <p>
              {data?.address_details
                ? data?.address_details?.order_address || ""
                : ""}
            </p>
            <p>
              Phone No-{" "}
              {data?.address_details
                ? data?.address_details?.order_country_code.includes("+")
                  ? ""
                  : "+" + data?.address_details?.order_country_code || "+91"
                : ""}
              {data?.address_details
                ? " " + data?.address_details?.order_phone_no || ""
                : ""}
            </p>

            <div className="dividr"></div>
          </div>
        </div>
      </div>

      <div className="card2">
        <h5>Price Details</h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <p style={{ paddingBottom: "5px" }}>Booking Amount</p>
          <p>
            $
            {`${
              data?.token_amount
                ? parseFloat(`${data?.token_amount}`).toFixed(2)
                : ""
            }`}
          </p>
        </div>
        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ paddingBottom: "5px" }}>Product Quantity</p>
          <p>{data?.quantity || 0}</p>
        </div> */}
        {/* <div className="totalAmount">
          <p>Total</p>
          <p>
            $ {`${data?.cost ? parseFloat(`${data?.cost}`).toFixed(2) : ""}`}
          </p>
        </div> */}

        <div className="dividr"></div>
        <h5>Payment Method</h5>
        <div style={{ display: "flex", marginTop: "10px" }} className="visadiv">
          <figure className="visa-img">
            <img src="/static/images/visa.png" alt="visa"></img>
          </figure>
          <div className="payment-details">
            <p>•••• •••• •••• 4432</p>
            <p>Personal - 06/23</p>
          </div>
        </div>
        {active ? (
          <>
            <button
              className="cancel-btn"
              // onClick={() => handleCancelOrder(Number(data?.id), "product")}
              onClick={() => {
                setOpen(true);
                setType("product");
                setCancelProduId(Number(data?.id));
              }}
            >
              Cancel Item
            </button>
          </>
        ) : null}
        {data?.delivery_status === 3 ? (
          <div className="rateing">
            <button className="btn" onClick={() => setOpenReview(true)}>
              Rate Now
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      <Modal
        open={openReview}
        onClose={() => setOpenReview(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Rating data={data} setOpenReview={setOpenReview} />
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {type === "order" ? (
            <div className="warning-modal">
              <h3>Are you sure, you want to cancel this order?</h3>
              <div className="btn-div">
                <button
                  className="yes"
                  onClick={() => {
                    setOpen(false);
                    handleCancelOrder(Number(cancelOrderId), "order");
                  }}
                >
                  Yes
                </button>
                <button className="no" onClick={() => setOpen(false)}>
                  No
                </button>
              </div>
            </div>
          ) : (
            <div className="warning-modal">
              <h3>Are you sure, you want to cancel product from this order?</h3>
              <div className="btn-div">
                <button
                  className="yes"
                  onClick={() => {
                    setOpen(false);
                    handleCancelOrder(Number(cancelProdId), "product");
                  }}
                >
                  Yes
                </button>
                <button className="no" onClick={() => setOpen(false)}>
                  No
                </button>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default OrderDetails;
