import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Dispatch, SetStateAction, useState } from "react";
import LoginModal from "./LoginModal";
import SignUpModal from "./SignUpModal";
import ForgotModal from "./ForgotModal";
import OtpModal from "./OtpModal";
import ResetPassword from "./ResetPassword";
import { useAppDispatch } from "../../hooks/Store";
import { toggleAuthModal } from "../../reducers/commonSlice";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@mui/material";

type AuthProps = {
  IsOpen: boolean;
  activeModal?: number;
  setActiveModal: Dispatch<SetStateAction<number>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const AuthModal = ({
  activeModal,
  setActiveModal,
  IsOpen,
  setOpen,
}: AuthProps) => {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState<string>("");
  const [otpModalType, setOtpModalType] = useState<string>("");

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute" as "absolute",
    top: isSmallScreen ? "55%" : "55%",
    left: isSmallScreen ? "50%" : "77%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    outline: "none",
    p: 4,
    borderRadius: 4,
    width: isSmallScreen ? "76%" : "350px",
  };

  const style2 = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    outline: "none",
    borderRadius: 0,
    minHeight: 480,
    overflow: "auto",
  };

  const style3 = {
    position: "absolute" as "absolute",
    top: isSmallScreen ? "55%" : "55%",
    left: isSmallScreen ? "50%" : "77%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    outline: "none",
    p: 4,
    borderRadius: 4,
    width: isSmallScreen ? "70%" : "350px",
  };
  const closeModal = () => {
    setOpen(false);
    setActiveModal(1);
    dispatch(toggleAuthModal({ isAuthModalVisible: false }));
  };

  const handleModal = () => {
    switch (activeModal) {
      case 1:
        return (
          <Box sx={style}>
            <LoginModal
              setActiveModal={setActiveModal}
              closeModal={closeModal}
            />
          </Box>
        );
      case 2:
        return (
          <Box sx={style2}>
            <SignUpModal
              setActiveModal={setActiveModal}
              closeModal={closeModal}
              setOtpModalType={setOtpModalType}
              setEmail={setEmail}
            />
          </Box>
        );
      case 3:
        return (
          <Box sx={style}>
            <ForgotModal
              setActiveModal={setActiveModal}
              setEmail={setEmail}
              setOtpModalType={setOtpModalType}
            />
          </Box>
        );
      case 4:
        return (
          <Box sx={style3}>
            <OtpModal
              setActiveModal={setActiveModal}
              email={email}
              otpModalType={otpModalType}
              closeModal={closeModal}
            />
          </Box>
        );
      case 5:
        return (
          <Box sx={style}>
            <ResetPassword closeModal={closeModal} />
          </Box>
        );
      default:
        return null;
    }
  };
  return (
    <Modal
      open={IsOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>{handleModal()}</Box>
    </Modal>
  );
};

export default AuthModal;
