import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/Store";
import { CartData, CmsData } from "../types/General";

export interface Common {
  cms?: CmsData | null;
  filterId: {
    countryId?: number | null;
    subCatId?: number | null;
    catId?: number | null;
  } | null;
  isAuthModalVisible: boolean;
  cart: CartData[];
  cookiePresent: boolean;
}

const initialState: Common = {
  cms: null,
  filterId: null,
  isAuthModalVisible: false,
  cart: [],
  cookiePresent: true,
};

export interface CMS {
  about_us: string;
  cancelation_policy: string;
  contact_no: string;
  country_code: string;
  email: string;
  id: number;
  privacy: string;
  terms_and_conditions: string;
  executive_phone: string;
  executive_country_code: string;
  executive_country_iso_code: string;
}

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    resetCms: (state, action: PayloadAction<Pick<Common, "cms">>) => {
      state.cms = action.payload.cms;
    },

    filterData: (state, action: PayloadAction<Pick<Common, "filterId">>) => {
      state.filterId = action.payload.filterId;
    },

    toggleAuthModal: (
      state,
      action: PayloadAction<Pick<Common, "isAuthModalVisible">>
    ) => {
      state.isAuthModalVisible = action.payload.isAuthModalVisible;
    },

    resetCart: (state, action: PayloadAction<Pick<Common, "cart">>) => {
      state.cart = action.payload.cart;
    },

    toggleCookieStatus: (
      state,
      action: PayloadAction<Pick<Common, "cookiePresent">>
    ) => {
      state.cookiePresent = action.payload.cookiePresent;
    },

    updateQuantity: (
      state,
      action: PayloadAction<{ productId: number; quantity: number }>
    ) => {
      const { productId, quantity } = action.payload;

      const product = state.cart.find((item) => item.id === productId);

      if (product) {
        product.quantity = quantity;
        product.total_amount =
          parseFloat(product.product.commissioned_product_cost) * quantity;
      }
    },
  },
});

export const {
  resetCms,
  filterData,
  toggleAuthModal,
  resetCart,
  updateQuantity,
  toggleCookieStatus,
} = commonSlice.actions;

export const getCms = (state: RootState) => state.common.cms;
export const getFilterId = (state: RootState) => state.common.filterId;
export const isAuthModalVisible = (state: RootState) =>
  state.common.isAuthModalVisible;
export const getCartData = (state: RootState) => state.common.cart;
export const getCookieStatus = (state: RootState) => state.common.cookiePresent;

export default commonSlice.reducer;
