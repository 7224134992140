export const ar = {
  globals: {
    publish: "ينشر",
    cancel: "يلغي",
    contact: "اتصل الآن",
    cancelOrdr: "الغاء الطلب",
    mngAddress: "يحرر",
    profileBtn: "تعديل الملف الشخصي",
    footer: "اشتراك",
  },
};
