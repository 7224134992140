import Layout from "../../layout";
import { ProductCard } from "../../components";
import { HomeBanner, HomeSlider, ShopNow, SideBar } from "../../features/home";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetLatestProductsQuery,
  useLazyGetRecommendedProductsQuery,
  useLazyGetPopularProductsQuery,
  useLazyGetCategoryListQuery,
} from "../../service/home";
import { useState, useEffect } from "react";
import { ManageProduct, CategoryResponse } from "../../types/General";
import { LazyLoadComponent } from "react-lazy-load-image-component";

const HomePage = () => {
  const [latestProduct, setLatestProduct] = useState<ManageProduct[]>([]);
  const [recommendedProducts, setRecommendedProducts] = useState<
    ManageProduct[]
  >([]);
  const [categories, setCategories] = useState<CategoryResponse[]>([]);

  const [popularProducts, setPopularProducts] = useState<ManageProduct[]>([]);
  const [latestProductMutation] = useLazyGetLatestProductsQuery();
  const [recommendedMutation] = useLazyGetRecommendedProductsQuery();
  const [popularProductMutation] = useLazyGetPopularProductsQuery();
  const [getCatSubCatMethod] = useLazyGetCategoryListQuery();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(0);

  const getLatestProduct = async () => {
    try {
      const res = await latestProductMutation({}).unwrap();
      if (res?.code === 200) {
        setLatestProduct(res?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRecommendedProduct = async () => {
    try {
      const res = await recommendedMutation({}).unwrap();
      if (res?.code === 200) {
        setRecommendedProducts(res?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPopularProducts = async () => {
    try {
      const res = await popularProductMutation({}).unwrap();
      if (res?.code === 200) {
        setPopularProducts(res?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCatSubCatData = async () => {
    try {
      const res = await getCatSubCatMethod({}).unwrap();
      if (res?.code === 200) {
        setCategories(res?.data || []);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLatestProduct();
    getRecommendedProduct();
    getPopularProducts();
    getCatSubCatData();
  }, []);

  return (
    <Layout>
      <HomeBanner />
      <div className="categriessec">
        <div className="container">
          {!categories?.length ? (
            <div className="no_data">
              <figure>
                <img src="/static/images/no_pro.png" alt="No Products" />
              </figure>
              <h3>No products available</h3>
            </div>
          ) : (
            <div className="category">
              <div className="cleft">
                <SideBar
                  categories={categories}
                  setSelected={setSelected}
                  selected={selected}
                />
              </div>
              <div className="cright">
                <HomeSlider
                  categories={categories}
                  setSelected={setSelected}
                  selected={selected}
                />
                {latestProduct.length === 0 &&
                popularProducts.length === 0 &&
                recommendedProducts.length === 0 ? (
                  <div className="no_data">
                    <h2 style={{ textAlign: "left", width: "100%" }}>
                      Products
                    </h2>
                    <figure>
                      <img src="/static/images/no_pro.png" alt="No Products" />
                    </figure>
                    <h3>No products available</h3>
                  </div>
                ) : (
                  <div className="procontainer">
                    <div className="pmain">
                      <div className="tilebar">
                        <h2>Latest Products</h2>
                        {latestProduct?.length < 4 ? null : (
                          <h4 onClick={() => navigate("/products")}>See all</h4>
                        )}
                      </div>

                      <div className="product">
                        {latestProduct?.length
                          ? latestProduct?.slice(0, 4)?.map((item) => {
                              return (
                                <div className="proCard" key={item?.id}>
                                  <ProductCard latestProduct={item} />
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                    <div className="pmain">
                      <div className="tilebar">
                        <h2>Popular Products</h2>
                        {popularProducts?.length < 4 ? null : (
                          <h4 onClick={() => navigate("/products")}>See all</h4>
                        )}
                      </div>
                      <div className="product">
                        {popularProducts?.length
                          ? popularProducts?.slice(0, 4)?.map((item) => {
                              return (
                                <div className="proCard" key={item.id}>
                                  <ProductCard latestProduct={item} />
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                    <div className="pmain">
                      <div className="tilebar">
                        <h2>Recommended Products</h2>
                        {recommendedProducts?.length < 4 ? null : (
                          <h4 onClick={() => navigate("/products")}>See all</h4>
                        )}
                      </div>

                      <div className="product">
                        {recommendedProducts?.length
                          ? recommendedProducts?.slice(0, 4)?.map((item) => {
                              return (
                                <div className="proCard" key={item.id}>
                                  <ProductCard latestProduct={item} />
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="showbanner">
        <LazyLoadComponent>
          <ShopNow />
        </LazyLoadComponent>
      </div>
    </Layout>
  );
};

export default HomePage;
