import { Dispatch, SetStateAction } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  IconButton,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import "./Table.scss";
import { OrderType } from "./ManageOrder";

type props = {
  orders: OrderType[];
  setIsOrderId: Dispatch<SetStateAction<any>>;
  setSelectedId: Dispatch<SetStateAction<number | undefined>>;
};

const OrderTable = ({ setIsOrderId, orders, setSelectedId }: props) => {
  const status = {
    1: "Order placed",
    2: "Shipped",
    3: "Delivered",
    4: "Rejected",
    5: "Cancelled",
  } as any;

  // const handleViewAction = async (id: number) => {
  //   console.log(id, "88888");

  //   setIsOrderId(true);
  //   setSelectedId(id);
  //   return;
  // };

  const tableStyle = {
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
  };

  return (
    <div className="order_table">
      <TableContainer sx={tableStyle}>
        <Table className="tableMain">
          <TableHead>
            <TableRow>
              <TableCell>Order ID</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Brand</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Total Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>

          {orders?.length ? (
            orders?.map((row) => (
              <TableBody key={row?.order_id}>
                <TableRow>
                  <TableCell>#{row?.order_id}</TableCell>
                  <TableCell>{row?.product}</TableCell>
                  <TableCell>{row?.brand}</TableCell>
                  <TableCell>{row?.product_category}</TableCell>
                  <TableCell>{row?.quantity}</TableCell>
                  <TableCell>${row?.cost}</TableCell>
                  {/* <TableCell>{row?.delivery_status}</TableCell> */}
                  <TableCell> {status[row?.delivery_status || 1]}</TableCell>
                  <TableCell>
                    <IconButton>
                      <Visibility
                        onClick={() => {
                          setSelectedId(row?.product_order_id);
                          setIsOrderId(true);
                        }}
                        sx={{ color: "#00931C" }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))
          ) : (
            <TableBody className="noTableDatarole2">
              <h4>Orders not found</h4>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default OrderTable;
