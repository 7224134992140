import React, { useState } from "react";
import PermissionTable from "./PermissionTable";
import { Loader, errorToast, successToast, warnToast } from "../../../helpers";
import { usePostAddRolePermissionsMutation } from "../../../service/sellerPanel/Permissions";

interface TableRow {
  role_id: number;
  module_id: number[];
}

const Roles = () => {
  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [addPermissionMethod] = usePostAddRolePermissionsMutation();

  const handleSaveClick = async () => {
    if (tableData?.length) {
      try {
        setLoading(true);
        const res = await addPermissionMethod(tableData).unwrap();
        setLoading(false);
        if (res?.code === 201 || res?.code === 200) {
          successToast(res?.message || "");
        }
      } catch (error: any) {
        setLoading(false);
        errorToast(error?.data?.message || "");
      }
    } else {
      warnToast("Select permissions first");
    }
  };

  return (
    <div className="addprdct">
      <Loader isLoad={loading} />
      <div className="producthead">
        <h3>Roles & Permission</h3>
        <div className="btndiv">
          <div className="publish">
            <button
              onClick={handleSaveClick}
              className="btnpublish"
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <PermissionTable tableData={tableData} setTableData={setTableData} />
    </div>
  );
};

export default Roles;
