import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import pagenotfound from "./pagenotfound.module.scss";
import useTranslation from "../../hooks/Translation";

export default function PageNotFound() {
  const translation = useTranslation() as any;
  return (
    <main>
      <div className="container">
        <div className={pagenotfound.notfound}>
          <div className={pagenotfound.custome_row}>
            <div className="w_50">
              <figure>
                <img src="/static/images/pagenotfound.jpg" alt="" />
              </figure>
            </div>
            <div className="w_50">
              {/* <h1>404</h1> */}

              <h2>Page Not Found</h2>
              <p>
                The page you are looking for does not exist. How you got here is
                a mystery. But you can click the button below to go back to the
                homepage.
              </p>
              <Link to="/">
                <Button className={pagenotfound.btn}>Home</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
