import React from "react";

const ManageAddressData = () => {
  const details = [
    {
      id: 1,
      title: "Address 1",
      name: "Franklin Jr",
      address: "3012 Broaddus Avenue Saint Joseph, California 4908",
      phone: "5589345698",
    },
    {
      id: 2,
      title: "Address 2",
      name: "Franklin Jr",
      address: "3012 Broaddus Avenue Saint Joseph, California 4908",
      phone: "5589345698",
    },
    {
      id: 3,
      title: "Address 3",
      name: "Franklin Jr",
      address: "3012 Broaddus Avenue Saint Joseph, California 4908",
      phone: "5589345698",
    },
  ];

  return (
    <div className="address-data-wrapper">
      {details.map((items) => (
        <div className="mngaddress-details" key={items.id}>
          <p className="mngaddress-details-p">{items.title}</p>
          <p className="mngaddress-details-name">{items.name}</p>
          <p>{items.address}</p>
          <p>Phone No - {items.phone}</p>
        </div>
      ))}
    </div>
  );
};

export default ManageAddressData;
