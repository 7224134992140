import { useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";

type DomainData = {
  id?: number;
  name: string;
  company_domain: string;
  user_details?: detail | undefined;
};
type detail = {
  id?: number;
  office_country_code?: string;
  office_country_iso_code?: string;
  office_phone_no?: string;
  company_country_code?: string;
  company_country_iso_code?: string;
  company_phone_no?: string;
  job_title?: string;
  location?: string;
};
type CompanyDomainProps = {
  handleClose?: () => void;
  formik?: any;
  companyDomainData: DomainData[];
  setPhoneCode: any;
  setCompanyPhoneCode: any;
};
type selected = {
  id: number;
  key: string;
  val?: string;
  state: string;
};
const CompanyDomainModal = ({
  handleClose,
  formik,
  companyDomainData,
  setCompanyPhoneCode,
  setPhoneCode,
}: CompanyDomainProps) => {
  const [selectedValues, setSelectedValues] = useState<selected[]>([]);
  console.log(selectedValues);
  const handleClick = (item: selected) => {
    const itemExists = selectedValues.some((value) => value.id === item.id);
    if (itemExists) {
      const updatedValues = selectedValues.filter(
        (value) => value.id !== item.id
      );
      setSelectedValues(updatedValues);
      formik.setFieldValue(item?.state, "");
    } else {
      setSelectedValues([...selectedValues, item]);
      if (companyDomainData?.length) {
        if (item?.id === 2) {
          setPhoneCode(
            companyDomainData[0]?.user_details?.office_country_code || ""
          );
        } else if (item?.id === 4) {
          setCompanyPhoneCode(
            companyDomainData[0]?.user_details?.company_country_code || ""
          );
        }
        formik.setFieldValue(item?.state, item?.val);
      }
    }
  };
  const data = [
    {
      id: 1,
      key: "Company Name:",
      val: companyDomainData?.length ? companyDomainData[0]?.name : "",
      state: "companyName",
    },
    {
      id: 2,
      key: "Office Mobile number:",
      val: companyDomainData?.length
        ? companyDomainData[0]?.user_details?.office_phone_no
        : "",
      state: "phone",
    },
    {
      id: 3,
      key: "Job Title:",
      val: companyDomainData?.length
        ? companyDomainData[0]?.user_details?.job_title
        : "",
      state: "jobTitle",
    },
    {
      id: 4,
      key: "Company phone number:",
      val: companyDomainData?.length
        ? companyDomainData[0]?.user_details?.company_phone_no
        : "",
      state: "companyPhone",
    },
    {
      id: 5,
      key: "Location:",
      val: companyDomainData?.length
        ? companyDomainData[0]?.user_details?.location
        : "",
      state: "location",
    },
  ];
  return (
    <div className="domainmodal">
      <div className="modalmian">
        <figure>
          <CloseIcon onClick={handleClose} />
        </figure>
        <div className="maind">
          {data?.map((item, index) => {
            return (
              <div key={index} onClick={() => handleClick(item)}>
                {item.val ? (
                  <div className="dModal">
                    <h2>{item.key}</h2>
                    <div className="values">
                      <h4>{item.val}</h4>
                      <div>
                        {selectedValues.some(
                          (value) => value.id === item.id
                        ) ? (
                          <CheckBoxIcon />
                        ) : (
                          <CheckBoxOutlineBlankIcon />
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default CompanyDomainModal;
