import { END_POINTS } from "../../contansts/Urls";
import { DashboardCount } from "../../types/General";
import emptySplitApi from "../../utils/rtk";

type GetDashboardCountResponse = {
  data?: DashboardCount | undefined;
  code: number;
  message?: string;
};

export const dashboardApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardCount: builder.query<GetDashboardCountResponse, unknown>({
      query: () => ({
        url: END_POINTS.dashboardCount,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetDashboardCountQuery } = dashboardApi;
