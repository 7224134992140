import React, { useState, Dispatch, SetStateAction } from "react";
import { Box, Modal, TextField } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import "../../pages/profile/Profile.scss";
import "../../layout/Layout.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { isString } from "../../utils/validations";
import { usePostRfpMutation } from "../../service/home";
import { errorToast, successToast } from "../../helpers";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@mui/material";
import Loader from "../../helpers/Loader";

type formProps = {
  IsOpen?: boolean;
  setOpen: Dispatch<SetStateAction<any>>;
  onClick?: () => void;
};
const RfpModal = ({ IsOpen = false, onClick, setOpen }: formProps) => {
  const [phoneCode, setPhoneCode] = useState("+91");
  const [countryName, setCountryName] = useState("in");

  const [rfpMutation, rfpMutationData] = usePostRfpMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      productName: "",
      isoCode: "",
      description: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("First name is required")
        .min(2, "Minimum 2 characters are required"),
      lastName: Yup.string().min(2, "Minimum 2 characters are required"),
      description: Yup.string()
        .required("Description is required")
        .min(10, "Minimum 10 characters are required"),
      productName: Yup.string()
        .required("Product name is required")
        .min(3, "Minimum 3 characters are required"),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      phone: Yup.string()
        .required("Phone number is required")
        .min(10, "Phone Number must be at least 10 characters"),
    }),
    onSubmit: async (value) => {
      formik.setSubmitting(true);
      console.log(value);
      // navigate("/");
      let body = {
        first_name: formik.values.firstName,
        last_name: formik.values.lastName,
        email: formik.values.email,
        phone_number: formik.values.phone,
        iso_code: countryName,
        country_code: phoneCode.includes("+") ? phoneCode : "+" + phoneCode,
        product_name: formik.values.productName,
        description: formik.values.description,
      };
      console.log(body, "bdod");

      try {
        const response = await rfpMutation(body).unwrap();
        if (response?.code === 200 || response?.code === 201) {
          successToast(response?.message || "");
          setOpen(false);
          formik.resetForm();
          // navigate("/");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error, "ERROR");
      }
    },
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "70%" : "500px",
    height: isSmallScreen ? "60%" : "85%",
    bgcolor: "background.paper",
    boxShadow: 24,
    outline: "none",
    p: 4,
    borderRadius: 4,
    // height: 500,
    overflowY: "scroll",
  };

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <Loader isLoad={rfpMutationData.isLoading} />
      <Modal
        open={IsOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            className="rfpForm profilemain"
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <h3>RFP Form</h3>
            <form onSubmit={formik.handleSubmit}>
              <div className="inputdiv">
                <div className="firstname"></div>
                <TextField
                  className="text_field"
                  placeholder="First Name"
                  id="firstName"
                  name="firstName"
                  size="small"
                  fullWidth
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (isString(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  inputProps={{ maxLength: 20 }}
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
                <TextField
                  className="text_field"
                  placeholder="Last Name"
                  size="small"
                  id="lastName"
                  name="lastName"
                  inputProps={{ maxLength: 15 }}
                  fullWidth
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (isString(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                />
                <TextField
                  className="text_field"
                  placeholder="Business email address*"
                  fullWidth
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={(val) => {
                    formik.handleBlur(val);
                  }}
                  value={formik.values.email}
                  helperText={formik.touched.email && formik.errors.email}
                />
                <div className="fdiv">
                  <PhoneInput
                    value={phoneCode + formik.values.phone}
                    country={"us"}
                    inputClass="phoneInput"
                    buttonClass="phoneBtn"
                    placeholder="Office phone number"
                    onChange={(phone, country) =>
                      handleChangePhone(phone, country)
                    }
                    onBlur={formik.handleBlur}
                    inputStyle={{ marginBottom: "10px" }}
                  />
                </div>
                {formik.touched.phone && formik.errors.phone ? (
                  <h6 className="err_msg">
                    {formik.touched.phone && formik.errors.phone}
                  </h6>
                ) : (
                  ""
                )}
                <TextField
                  size="small"
                  placeholder="Product Name"
                  name="productName"
                  id="productName"
                  fullWidth
                  className="text_field"
                  value={formik.values.productName}
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                  helperText={
                    formik.touched.productName && formik.errors.productName
                  }
                  onBlur={formik.handleBlur}
                />
                <div className="fdiv">
                  <TextField
                    className="textarea text_field"
                    multiline
                    name="description"
                    id="description"
                    minRows={2}
                    placeholder="Description"
                    fullWidth
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={(val) => {
                      formik.handleBlur(val);
                    }}
                    value={formik.values.description}
                    helperText={
                      formik.touched.email && formik.errors.description
                    }
                  />
                </div>

                <div className="btnddiv">
                  <button type="submit" disabled={formik.isSubmitting}>
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default RfpModal;
