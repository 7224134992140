import { Breadcrumbs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Breadcrumb = () => {
  const navigate = useNavigate();
  const [paths, setPaths] = useState("");

  const location = useLocation();
  const { state } = location;
  const pageLocation = window.location.pathname;

  useEffect(() => {
    if (pageLocation) {
      const route = pageLocation.split("/");
      const name = route[1].split("-");
      let newName = "";
      if (name?.length > 1) {
        newName = name.join(" ");
      } else {
        newName = route[1];
      }
      setPaths(newName);
    }
  }, [pageLocation]);
  return (
    <div className="breadCrumb container">
      {/* <div className="bName">
        <p>Home</p>
      </div>
      <div className="bName">
        <p>Product</p>
      </div>
      <div className="bName">
        <p>Detail Product</p>
      </div> */}
      <Breadcrumbs aria-label="breadcrumb" separator={paths === "" ? "" : ">"}>
        <div
          // style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate("/");
          }}
          className={state === "cms" ? "cms_brcm" : "brdcrmb"}
        >
          {/* <HomeIcon
								style={{ fontSize: '20px', marginRight: '2px' }}
								className={
									state === 'cms' ? 'cms_brcm' : 'brdcrmb'
								}
							/> */}
          Home
        </div>

        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#61439F",
            textTransform: "capitalize",
          }}
          color="text.primary"
        >
          {paths}
        </Typography>
      </Breadcrumbs>
    </div>
  );
};

export default Breadcrumb;
