import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button } from "../../../components";
import "../ManageOrder.scss";
import { useLazyGetProductByIdDataQuery } from "../../../service/sellerPanel/Products";
import { ManageProduct } from "../../../types/General";
import { Loader } from "../../../helpers";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type ManageProps = {
  SetActiveCase: Dispatch<SetStateAction<any>>;
  selectedId: any;
  setIsAdd: Dispatch<SetStateAction<boolean>>;
};

const ProductDetail = ({
  SetActiveCase,
  selectedId,
  setIsAdd,
}: ManageProps) => {
  const [productData, setProductData] = useState<ManageProduct>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDocs, setSelectedDocs] = useState<any[]>([]);
  const [imageRes, setImageRes] = useState<string>();
  console.log(selectedDocs, "selectedDocs");
  const [getProductByIdMethod] = useLazyGetProductByIdDataQuery();

  const getProductByIdData = async (id: any) => {
    try {
      setLoading(true);
      const res = await getProductByIdMethod({
        product_id: id,
      }).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        setProductData(res?.data || "");
        setImageRes(res?.data?.product_certificate_image?.media_file_url);
        const pdfUri = [
          {
            url: res?.data?.product_certificate_image?.media_file_url?.replace(
              "http",
              "https"
            ),
          },
        ];
        setSelectedDocs(
          res?.data?.product_certificate_image === null ? [] : pdfUri
        );
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedId) {
      getProductByIdData(selectedId);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [selectedId]);

  return (
    <>
      <div className="manageProducts">
        <Loader isLoad={loading} />
        <div className="productHead">
          <h3>Product Detail</h3>
          <div className="btn" style={{ width: "19%" }}>
            <Button
              value="Update"
              onClick={() => {
                SetActiveCase(2);
                setIsAdd(false);
              }}
            />
          </div>
        </div>
        <div className="pDetail">
          <div className="pdetailmain">
            <div className="proimgs">
              {productData?.product_images?.length
                ? productData?.product_images?.map((item, index) => {
                    return (
                      <figure key={index}>
                        <img src={item?.image?.media_file_url} alt="products" />
                      </figure>
                    );
                  })
                : null}
            </div>
            <ul>
              <li>
                <h3>Product Id</h3>
                <p>{"#" + productData?.id || ""}</p>
              </li>
              <li>
                <h3>Product Name</h3>
                <p>{productData?.product_name || ""}</p>
              </li>
              <li>
                <h3>Product Category</h3>
                <p>{productData?.product_category?.name || "Other"}</p>
              </li>
              <li>
                <h3>Product Sub-Category</h3>
                <p>{productData?.product_sub_category?.name || "Other"}</p>
              </li>
              <li>
                <h3>Brand</h3>
                <p>{productData?.brand?.name || "Other"}</p>
              </li>
              <li>
                <h3>Short Description</h3>
                <p>{productData?.short_description || ""}</p>
              </li>
              <li>
                <h3>Specification/Material Description</h3>
                <p>{productData?.detailed_description || ""}</p>
              </li>
              <li>
                <h3>Cost</h3>
                <p>{productData?.product_cost || ""}</p>
              </li>
              <li>
                <h3>Stock</h3>
                <p>{productData?.product_stock || ""}</p>
              </li>
              <li>
                <h3>Key Dimensions</h3>
                <p>{productData?.product_key_dimension || ""}</p>
              </li>
              <li>
                <h3>Remarks</h3>
                <p>{productData?.remark || ""}</p>
              </li>
              <li>
                <h3>Grade of Material</h3>
                <p>{productData?.material_grade || ""}</p>
              </li>
              <li>
                <h3>Material of Construction</h3>
                <p>{productData?.material_of_construction || ""}</p>
              </li>
              <li>
                <h3>Unit</h3>
                <p>{productData?.short_description || ""}</p>
              </li>
              <li>
                <h3>Country of origin</h3>
                <p>{productData?.country_of_origin?.name || ""}</p>
              </li>
              <li>
                <h3>NACE</h3>
                <p>{productData?.nance ? "Yes" : "No"}</p>
              </li>
              <li>
                <h3>Test Certificate</h3>
                <figure className="certifi">
                  <figure style={{ width: "100%", height: "100%" }}>
                    {selectedDocs ? (
                      <a
                        href={imageRes}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Document file={selectedDocs[0]}>
                          <Page pageNumber={1} />
                        </Document>
                      </a>
                    ) : null}
                  </figure>
                </figure>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
