import { END_POINTS } from "../contansts/Urls";
import { CartData } from "../types/General";
import emptySplitApi from "../utils/rtk";

type AddToCartResponse = {
  code: number;
  message: string;
  data: CartData;
};

type IncreaseResponse = {
  code: number;
  message: string;
  data: {
    id: number;
    quantity: number;
    total_amount: number;
    is_active: boolean;
    cart_amount: number;
    token_amount: string;
  };
};

type ViewCartResponse = {
  code: number;
  message: string;
  cart_amount: number;
  token_amount: number;
  data: CartData[];
};

type DeleteResponse = {
  code: number;
  message: string;
  data: null;
};

type PostOrderPlaceBody = {
  order_first_name: string;
  order_last_name: string;
  order_address: string;
  order_state: string;
  order_zipcode: string;
  order_country_code: string;
  order_country_iso_code: string;
  order_phone_no: string;
  // cart_id: number;
};

type PostOrderPlaceResponse = {
  code: number;
  message: string;
  data: {
    order_id: number | undefined;
  };
};

type PutPayNowResponse = {
  data?: {
    order_id: number | undefined;
  };
  code: number;
  message: string;
};

export const otherApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addToCart: builder.mutation<
      AddToCartResponse,
      { product?: number | undefined }
    >({
      query: (body) => ({
        url: END_POINTS.add_to_cart,
        method: "POST",
        body,
      }),
    }),
    increaseCartItem: builder.mutation<
      IncreaseResponse,
      { product_id?: number | undefined }
    >({
      query: ({ product_id }) => ({
        url: `${END_POINTS.increase_quantity}${product_id}/`,
        method: "GET",
      }),
    }),
    decreaseCartItem: builder.mutation<
      IncreaseResponse,
      { product_id?: number | undefined }
    >({
      query: ({ product_id }) => ({
        url: `${END_POINTS.decrease_quantity}${product_id}/`,
        method: "GET",
      }),
    }),
    getCartItem: builder.query<ViewCartResponse, {}>({
      query: () => ({
        url: END_POINTS.view_cart,
        method: "GET",
      }),
    }),
    deleteFromCart: builder.mutation<
      DeleteResponse,
      { product_id?: number | undefined }
    >({
      query: ({ product_id }) => ({
        url: `${END_POINTS.delete_cart}${product_id}/`,
        method: "DELETE",
      }),
    }),

    postOrderPlace: builder.mutation<
      PostOrderPlaceResponse,
      PostOrderPlaceBody
    >({
      query: (body) => ({
        url: END_POINTS.orderPlace,
        method: "POST",
        body,
      }),
    }),

    putPaymentStatus: builder.mutation<
      PutPayNowResponse,
      {
        order_id: number | undefined;
        body: {
          payment_status: boolean;
        };
      }
    >({
      query: ({ order_id, body }) => ({
        url: `${END_POINTS.payNow}${order_id}/`,
        method: "PUT",
        body,
      }),
    }),
    getProductInCartItem: builder.query<
      { data: { quantity: number; cart_id: number }; code: number },
      { product_id: number }
    >({
      query: ({ product_id }) => ({
        url: `${END_POINTS.getDetailsOfProductInCart}${product_id}/`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useAddToCartMutation,
  useIncreaseCartItemMutation,
  useDecreaseCartItemMutation,
  useLazyGetCartItemQuery,
  useDeleteFromCartMutation,
  usePostOrderPlaceMutation,
  usePutPaymentStatusMutation,
  useLazyGetProductInCartItemQuery,
} = otherApi;
