import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2C106A",
    },
    secondary: {
      main: "#125BA9",
    },
    info: {
      main: "#0090B7",
    },
    success: {
      main: "#00C9A5",
    },
    error: {
      main: "#E3597A",
    },
    warning: {
      main: "#F9F871",
    },
  },
});

export default theme;
