import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ManageProduct } from "../../../types/General";

type ManageProps = {
  products: ManageProduct[];
};

const ProductsReportTable = ({ products }: ManageProps) => {
  return (
    <TableContainer component={Paper} sx={{ padding: 0 }} className="tablemain">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Product ID</TableCell>
            <TableCell>Product Name</TableCell>
            <TableCell>Brand Name</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Stock</TableCell>
            <TableCell>Total amount</TableCell>
            {/* <TableCell>Action</TableCell> */}
          </TableRow>
        </TableHead>
        {products?.length ? (
          products?.map((item) => (
            <TableBody>
              <TableRow
                key={item?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {"#" + item?.id || ""}
                </TableCell>
                <TableCell>{item?.product_name || ""}</TableCell>
                <TableCell>{item?.brand?.name || ""}</TableCell>
                <TableCell>{item?.product_category?.name || ""}</TableCell>
                <TableCell>{item?.product_stock || ""}</TableCell>
                <TableCell>{item?.product_cost || ""}</TableCell>
                {/* <TableCell>
                  <RemoveRedEyeIcon sx={{ color: "green" }} />
                </TableCell> */}
              </TableRow>
            </TableBody>
          ))
        ) : (
          <TableBody className="order_err">
            <h4>Products not found</h4>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default ProductsReportTable;
