import "../../layout/Layout.scss";
import "./Profile.scss";
import Layout from "../../layout";
import "react-phone-input-2/lib/bootstrap.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { PersonalInfo } from "../../features/seller";

const Profile = () => {
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      designation: "",
      companyName: "",
      email: "",
      address: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First is required"),
      phone: Yup.string()
        .required("Phone number is required")
        .matches(/^\d{8,}$/, "Invalid phone number"),
      designation: Yup.string().required("Designation is required"),
      companyName: Yup.string().required("Company name is required"),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      address: Yup.string().required("Address is required"),
    }),
    onSubmit: (value) => {
      formik.setSubmitting(true);
      console.log(value);
    },
  });

  return (
    <Layout>
      <div className="wrapcls">
        <div className="profilemain  container">
          <PersonalInfo />
        </div>
      </div>
    </Layout>
  );
};
export default Profile;
