import React, { useEffect, useState } from "react";
import "./myOrders.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Layout from "../../layout";
import { TitleBar } from "../../components";
import useTranslation from "../../hooks/Translation";
import { OrderDetails, PastOrderData } from "../../features/myOrders";
import { useGetAllOrdersMutation } from "../../service/ManageOrder";
import { ProductDetail, ProductType } from "../../types/General";
import {
  useLazyGetManageOrdersByIdQuery,
  usePostCancelOrderMutation,
} from "../../service/sellerPanel/Orders";
import { successToast } from "../../helpers";

const MyOrder = () => {
  const translation = useTranslation() as any;
  const [getOrders] = useGetAllOrdersMutation();
  const [getOrderByIdMutation] = useLazyGetManageOrdersByIdQuery();
  const [CancelOrder] = usePostCancelOrderMutation();

  const [active, setActive] = useState(true);
  const [manageOrder, setManageOrder] = useState<ProductType[]>([]);
  const [OrderDetail, setOrderDetail] = useState<ProductDetail>();

  const [OrderId, setOrderId] = useState<number>();
  const [selectedId, setSelectedId] = useState<number>();

  const [type, setType] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  console.log(currentPage, "currentPage");

  const totalPages = Math.ceil(totalCount / 3);

  const goToPage = (page: React.SetStateAction<number>) => {
    setCurrentPage(page);
  };

  const handleactive = () => {
    setActive(true);
  };

  const handleinactive = () => {
    setActive(false);
  };

  const getOrderById = async (id: number) => {
    try {
      const res = await getOrderByIdMutation({ order_id: id }).unwrap();
      if (res?.code === 200) {
        setOrderDetail(res?.data);
        setSelectedId(res?.data?.id);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getOrderList = async () => {
    try {
      let body = {
        start: currentPage,
        length: 3,
        ongoing: active ? true : false,
        past: active ? false : true,
        search: {
          value: "",
          regex: false,
        },
      };

      const res = await getOrders(body).unwrap();
      if (res?.code === 200) {
        const flattenedData = res?.data.flatMap((item) =>
          item.ordered_product.map((product) => ({
            ...product,
            user: product?.user?.first_name + " " + product?.user?.last_name,
            product: product?.product?.product_name || "",
            product_category: product?.product?.product_category?.name || "",
            product_sub_category:
              product?.product?.product_sub_category?.name || "",
            brand: product?.product?.brand?.name || "",
            delivery_status: product?.delivery_status || 0,
            product_name: product?.product?.product_name || "",
            quantity: product?.quantity || 0,
            product_image: product?.product?.product_image || "",
            cost: product?.cost || 0,
            created_at: product?.created_at || "",
            product_id: product?.id || 0,
            order_id: item?.id || 0,
            cancelProduct_id: product?.id || 0,
          }))
        );
        setManageOrder(flattenedData || []);
        setTotalCount(res?.recordsTotal || 0);
        if (flattenedData?.length > 0) {
          const firstProductId = flattenedData[0]?.product_id;
          getOrderById(firstProductId);
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  const handleCancelOrder = async (id: number, type: string) => {
    console.log(id, type, "orrrderid");
    if (type === "order") {
      const body = {
        order_id: Number(id),
      };
      try {
        const res = await CancelOrder(body).unwrap();
        if (res?.code === 200) {
          successToast(res?.message || "");
          getOrderList();
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      const body = {
        product_id: Number(id),
      };
      try {
        const res = await CancelOrder(body).unwrap();
        if (res?.code === 200) {
          successToast(res?.message || "");
          getOrderList();
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getOrderList();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [active, currentPage]);

  return (
    <Layout>
      <div className="wrapcls container">
        <div className="heading-div">
          <TitleBar name="My Orders" />
        </div>

        <div className="orderlistmain">
          <div className="DataSection">
            <div className="button-div">
              <button
                className={active ? "active" : "order-btn "}
                onClick={() => {
                  handleactive();
                }}
              >
                {translation.myOrders.btn}
              </button>
              <button
                className={!active ? "active" : "order-btn "}
                onClick={() => {
                  handleinactive();
                }}
              >
                {translation.myOrders.pastBtn}
              </button>
            </div>
            <PastOrderData
              data={manageOrder}
              getOrderById={getOrderById}
              setOrderId={setOrderId}
              setSelectedId={setSelectedId}
              selectedId={selectedId}
              OrderId={OrderId}
              active={active}
            />
            <div
              className="pagination"
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {manageOrder?.length ? (
                <div className="pages">
                  <button
                    className="prevbtn"
                    onClick={() => goToPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <ArrowBackIosIcon />
                  </button>
                  <div className="count">
                    {totalPages > 6
                      ? Array.from({ length: totalPages })
                          .slice(0, 6)
                          .map((_, index) => (
                            <button
                              className={
                                currentPage === index + 1 ? "actv" : "inActv"
                              }
                              key={index}
                              onClick={() => goToPage(index + 1)}
                              disabled={currentPage === index + 1}
                            >
                              {index + 1}
                            </button>
                          ))
                          .concat(
                            <span key="ellipsis" className="ellipsis">
                              ...
                            </span>,
                            <button
                              className={"actv"}
                              style={{
                                display:
                                  currentPage < 6 || currentPage === totalPages
                                    ? "none"
                                    : undefined,
                              }}
                              key={totalPages}
                            >
                              {currentPage}
                            </button>,
                            <button
                              className={
                                currentPage === totalPages ? "actv" : "inActv"
                              }
                              key={totalPages}
                              onClick={() => goToPage(totalPages)}
                              disabled={currentPage === totalPages}
                            >
                              {totalPages}
                            </button>
                          )
                      : Array.from({ length: totalPages }).map((_, index) => (
                          <button
                            className={
                              currentPage === index + 1 ? "actv" : "inActv"
                            }
                            key={index}
                            onClick={() => goToPage(index + 1)}
                            disabled={currentPage === index + 1}
                          >
                            {index + 1}
                          </button>
                        ))}
                  </div>
                  <button
                    className="prevbtn"
                    onClick={() => goToPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    <ArrowForwardIosIcon
                    // className="prevbtn"
                    // onClick={() => goToPage(currentPage + 1)}
                    />
                  </button>
                </div>
              ) : null}
            </div>
          </div>

          <div className="DetailsSection">
            {manageOrder?.length ? (
              <OrderDetails
                setType={setType}
                type={type}
                handleCancelOrder={handleCancelOrder}
                active={active}
                data={OrderDetail}
                OrderId={OrderId}
              />
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MyOrder;
