import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ManageProducts from "./ManageProducts";
import AddProduct from "./AddProduct";
import ProductDetail from "./ProductDetail";
import {
  useGetAllCountriesMutation,
  useGetBrandsMutation,
  useGetProductCategoriesMutation,
} from "../../../service/sellerPanel/Products";
import { Brand, Category, CountriesResponse } from "../../../types/General";
import { Loader } from "../../../helpers";
type props = {
  activeCase?: number;
  SetActiveCase: Dispatch<SetStateAction<number>>;
};

const ManageProduct = ({ activeCase, SetActiveCase }: props) => {
  const [selectedId, setSelectedId] = useState<number>();
  const [categories, setCategories] = useState<Category[]>([]);
  const [brandsData, setBrandsData] = useState<Brand[]>([]);
  const [countries, setCountries] = useState<CountriesResponse[]>([]);
  const [isAdd, setIsAdd] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [getCatMethod] = useGetProductCategoriesMutation();
  const [getBrandsMethod] = useGetBrandsMutation();
  const [getAllCountriesMethod] = useGetAllCountriesMutation();

  const getCatData = async () => {
    try {
      let body = {
        start: 1,
        length: 50,
        is_active: true,
        search: {
          value: "",
          regex: false,
        },
      };
      setLoading(true);
      const res = await getCatMethod(body).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        setCategories(res?.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getBrandsData = async () => {
    try {
      let body = {
        start: 1,
        length: 50,
        is_active: true,
        search: {
          value: "",
          regex: false,
        },
      };
      setLoading(true);
      const res = await getBrandsMethod(body).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        setBrandsData(res?.data || []);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const getCountriesData = async () => {
    try {
      let body = {
        start: 1,
        length: 200,
        is_active: true,
        search: {
          value: "",
          regex: false,
        },
      };
      setLoading(true);
      const res = await getAllCountriesMethod(body).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        setCountries(res?.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getCatData();
    getBrandsData();
    getCountriesData();
  }, []);

  const handleProductCase = () => {
    switch (activeCase) {
      case 1:
        return (
          <ManageProducts
            SetActiveCase={SetActiveCase}
            setSelectedId={setSelectedId}
            categories={categories}
            brandsData={brandsData}
            setIsAdd={setIsAdd}
          />
        );
      case 2:
        return (
          <AddProduct
            SetActiveCase={SetActiveCase}
            categories={categories}
            brandsData={brandsData}
            countries={countries}
            isAdd={isAdd}
            selectedId={selectedId}
          />
        );
      case 3:
        return (
          <ProductDetail
            SetActiveCase={SetActiveCase}
            selectedId={selectedId}
            setIsAdd={setIsAdd}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <Loader isLoad={loading} /> {handleProductCase()}
    </div>
  );
};

export default ManageProduct;
