import { useState } from "react";
import Layout from "../../layout";
import "../aboutUs/AboutUs.scss";
import "../profile/Profile.scss";
import { TextField } from "@mui/material";
import useTranslation from "../../hooks/Translation";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import { getCms } from "../../reducers/commonSlice";
import { useAppSelector } from "../../hooks/Store";
import { usePostContactUsMutation } from "../../service/Common";
import { successToast } from "../../helpers";
import { errorToast } from "../../helpers";
import { LazyLoadImage } from "react-lazy-load-image-component";
const ContactUs = () => {
  const cms = useAppSelector(getCms);
  const translation = useTranslation() as any;
  const [phoneCode, setPhoneCode] = useState("+91");
  const [contactMutation] = usePostContactUsMutation();
  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    // setCountryName(country?.countryCode);
    // dispatch(loginAction.success(null));
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      company: "",
      address: "",
      message: "",
      isoCode: "",
      location: "",
    },
    validationSchema: Yup.object({
      address: Yup.string()
        .required("Address is required.")
        .label("Address")
        .min(3),
      phone: Yup.string()
        .required("Phone number is required")
        .matches(/^\d{10,}$/, "Invalid phone number"),
      // .min(9)
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      company: Yup.string()
        .required("Company is required")
        .label("Company")
        .min(3),
      message: Yup.string()
        .required("Message is required")
        .label("Message")
        .min(3),
    }),
    onSubmit: async (value: any) => {
      formik.setSubmitting(true);
      console.log(value);
      let body = {
        email: formik.values.email,
        message: formik.values.message,
        location: formik.values.location,
        company_phone_no: formik.values.phone,
        company_country_code: phoneCode.includes("+")
          ? phoneCode
          : "+" + phoneCode,
        company_country_iso_code: formik.values.isoCode,
        company_name: formik.values.company,
      };
      try {
        const response = await contactMutation(body).unwrap();
        if (response?.code === 200 || response?.code === 201) {
          successToast(response?.message || "");
          formik.resetForm();
          // navigate("/");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error, "ERROR");
      }
    },
  });
  // const defaultProps = {
  //   center: {
  //     lat: 10.99835602,
  //     lng: 77.01502627,
  //   },
  //   zoom: 11,
  // };
  return (
    <Layout>
      <div className="contactWrapper wrapcls">
        <div className="container">
          <div className="contactHead">
            <h2>{translation.contactUs.heading}</h2>
            {/* <p>{translation.contactUs.para}</p> */}
          </div>
          {/* <div className="googleMap">
            <figure>
              <LazyLoadImage
                alt={"Map"}
                height={"100%"}
                src={"/static/images/googlemap.png"}
                effect="blur"
              />
            </figure>
            <h3>{translation.contactUs.address}</h3>
            <div className="contactInfo">
              <p
                dangerouslySetInnerHTML={{
                  __html: cms ? cms?.cms_email : "No data found",
                }}
              ></p>
              <p
                dangerouslySetInnerHTML={{
                  __html: cms ? cms?.cms_phone : " ",
                }}
              ></p>
            </div>
          </div> */}
          <div className="contactForm profilemain">
            <h4>{translation.contactUs.help}</h4>
            <form onSubmit={formik.handleSubmit}>
              <div className="inputdiv">
                <div className="firstname">
                  <div className="fdiv">
                    <TextField
                      className="text_field"
                      placeholder="Email"
                      fullWidth
                      id="email"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </div>
                  <div className="fdiv">
                    <PhoneInput
                      enableSearch={true}
                      country={"ae"}
                      inputClass="phoneInput"
                      buttonClass="phoneBtn"
                      placeholder="phone number"
                      value={phoneCode + formik.values.phone}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                      inputStyle={{ marginBottom: "10px" }}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <p className="errMsg">{formik.errors.phone}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="firstname">
                  <div className="fdiv">
                    <TextField
                      className="text_field"
                      placeholder="Company"
                      fullWidth
                      id="company"
                      name="company"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.company}
                      helperText={
                        formik.touched.company && formik.errors.company
                      }
                    />
                  </div>
                  <div className="fdiv">
                    <TextField
                      className="text_field"
                      placeholder="Address"
                      fullWidth
                      id="address"
                      name="address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                    />
                  </div>
                </div>
                <TextField
                  className="text_field2"
                  placeholder="Message"
                  fullWidth
                  multiline
                  minRows={3}
                  id="message"
                  name="message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                  helperText={formik.touched.message && formik.errors.message}
                />
                <div className="btnddiv2">
                  <button type="submit" disabled={formik.isSubmitting}>
                    {translation.globals.contact}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ContactUs;
