import React from "react";
import "../aboutUs/AboutUs.scss";
import Layout from "../../layout";
import useTranslation from "../../hooks/Translation";
import { useAppSelector } from "../../hooks/Store";
import { getCms } from "../../reducers/commonSlice";
const PrivatePolicy = () => {
  const cms = useAppSelector(getCms);
  const translation = useTranslation() as any;
  return (
    <Layout>
      <div className="policyWrapper wrapcls">
        <div className="container">
          <div className="prvtPolicy">
            <h2>{translation.privatePolicy.heading}</h2>
            {/* <div className="policyContent">
              <p className="policyDetails">
                {translation.privatePolicy.policyData}
              </p>
              <p className="policyDetails">
                {translation.privatePolicy.policyData}
              </p>
              <p className="policyDetails">
                {translation.privatePolicy.policyData}
              </p>
            </div> */}
            <div className="policyContent">
              <p
                dangerouslySetInnerHTML={{
                  __html: cms ? cms?.privacy : "No Data Found",
                }}
              ></p>
            </div>
          </div>
          {/* <div className="prsnlData">
            <h3> {translation.privatePolicy.personalData}</h3>
            <p>{translation.privatePolicy.policyData}</p>
            <div className="dataPoints">
              <div className="controller">
                <h4>{translation.privatePolicy.data1}</h4>
                <p>{translation.privatePolicy.content1}</p>
              </div>
              <div className="webTerms">
                <h4>{translation.privatePolicy.data2}</h4>
                <p>{translation.privatePolicy.content2}</p>
              </div>
              <div className="purpose">
                <h4>{translation.privatePolicy.data3}</h4>
                <p>{translation.privatePolicy.content3}</p>
              </div>
              <div className="purpose">
                <h4>{translation.privatePolicy.data3}</h4>
                <p>{translation.privatePolicy.content3}</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </Layout>
  );
};
export default PrivatePolicy;
