import { useState } from "react";
import "../../pages/homePage/Home.scss";
import { Button } from "../../components";
import AuthModal from "../modals/AuthModal";
import useAuth from "../../hooks/UseAuth";

const HomeBanner = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [activeModal, setActiveModal] = useState<number>(0);

  const userData = useAuth();

  const handleOpenModal = () => {
    setOpen(true);
    setActiveModal(1);
  };
  return (
    <div className="banner">
      <video
        poster="/static/images/thumbnail.jpg"
        autoPlay
        muted
        loop
        id="myVideo"
        preload="auto"
      >
        <source src="/static/images/bannervideo.mp4" type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <figure className="bnrImg">
        <img src="/static/images/thumbnail.jpg" alt="" />
      </figure>
      <div className="content">
        <h1>
          <span>e nilcon</span> committed to the social and economic progress
        </h1>
        <p>
          We have a wide range reference in the Construction and Services
          activities at different geographic locations.
        </p>
        {userData ? (
          <></>
        ) : (
          <div className="btn">
            <Button value="Get Started" onClick={handleOpenModal} />
          </div>
        )}
      </div>
      <AuthModal
        setActiveModal={setActiveModal}
        activeModal={activeModal}
        IsOpen={open}
        setOpen={setOpen}
      />
    </div>
  );
};

export default HomeBanner;
