import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";

const PayCard = () => {
  const [selected, setSelected] = useState<number>();
  console.log(selected, "seele");

  const data = [
    {
      id: 1,
      img: "/static/images/visa.png",
      cardNumer: "•••• •••• •••• 4432",
      type: "Personal - 06/23",
    },
    {
      id: 2,
      img: "/static/images/mastercard.png",
      cardNumer: "•••• •••• •••• 4432",
      type: "Personal - 06/23",
    },
  ];
  return (
    <div className="paycard">
      {data.map((item) => {
        return (
          <div
            key={item?.id}
            className="pcard"
            onClick={() => setSelected(Number(item?.id))}
          >
            <div className="pcLeft">
              <figure>
                <img src={item.img} alt="card" />
              </figure>
              <div className="detail">
                <h4>{item.cardNumer}</h4>
                <p>{item.type}</p>
              </div>
            </div>
            <div className="pcRyt">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="female"
                    control={
                      <Radio checked={selected === item?.id ? true : false} />
                    }
                    label=""
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PayCard;
