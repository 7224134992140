import React from "react";
import Layout from "../../layout";
import { TitleBar } from "../../components";
import { ManageAddressData, ManageAddressForm } from "../../features";
import "../profile/Profile.scss";

import "./manageAddress.scss";
import useTranslation from "../../hooks/Translation";

const ManageAddress = () => {
  const translation = useTranslation() as any;

  return (
    <Layout>
      <div className="wrapcls container">
        <TitleBar name={translation.manageAddress.manage} />
        <div className="mngaddress-div">
          <div className="address-data">
            <ManageAddressData />
          </div>
          <div className="address-form profilemain">
            <ManageAddressForm />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ManageAddress;
