import { END_POINTS } from "../contansts/Urls";
import {
  CategoryResponse,
  CountriesResponse,
  ManageProduct,
  ProductBtId,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type GetCountriesResponse = {
  code?: number;
  data?: CountriesResponse[];
  message: string;
};

type GetCatSubCatResponse = {
  code?: number;
  data?: CategoryResponse[];
  message: string;
};

type PostRfpResponse = {
  code: number;
  data?: any;
  message: string;
};

type PostRfpBody = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  iso_code?: string;
  country_code: string;
  product_name: string;
  description: string;
};

type GetProductListResponse = {
  data?: ManageProduct[];
  code: number;
  message?: string;
  recordsTotal?: number | undefined;
  recordsFiltered?: number | undefined;
};

type GetSameSupplierBody = {
  id: number | undefined;
};

type GetProductByIdResponse = {
  data?: ProductBtId;
  code: number;
  message?: string;
};

type SubscribeRes = {
  data: null;
  code: number;
  message: string;
};

export const homeApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCountryList: builder.query<GetCountriesResponse, unknown>({
      query: () => ({
        url: END_POINTS.countryList,
        method: "GET",
      }),
    }),

    getCategoryList: builder.query<GetCatSubCatResponse, unknown>({
      query: () => ({
        url: END_POINTS.categorySubCategory,
        method: "GET",
      }),
    }),

    postRfp: builder.mutation<PostRfpResponse, PostRfpBody>({
      query: (body) => ({
        url: END_POINTS.rfpForm,
        method: "POST",
        body,
      }),
    }),
    getLatestProducts: builder.query<GetProductListResponse, unknown>({
      query: () => ({
        url: END_POINTS.latestProducts,
        method: "GET",
      }),
    }),
    getRecommendedProducts: builder.query<GetProductListResponse, unknown>({
      query: () => ({
        url: END_POINTS.recommendedProducts,
        method: "GET",
      }),
    }),
    getPopularProducts: builder.query<GetProductListResponse, unknown>({
      query: () => ({
        url: END_POINTS.popularProducts,
        method: "GET",
      }),
    }),
    getSameSupplier: builder.mutation<
      GetProductListResponse,
      GetSameSupplierBody
    >({
      query: (body) => ({
        url: END_POINTS.get_same_supplier,
        method: "POST",
        body,
      }),
    }),
    getSimilarProducts: builder.mutation<GetProductListResponse, unknown>({
      query: (body) => ({
        url: END_POINTS.similar_products,
        method: "POST",
        body,
      }),
    }),
    getProductById: builder.query<GetProductByIdResponse, string>({
      query: (product_id) => ({
        url: `${END_POINTS.getProductById}${product_id}/`,
        method: "GET",
      }),
    }),

    getAllProducts: builder.mutation<
      GetProductListResponse,
      {
        length: number;
        start: number;
        search: {
          value: string;
          regex: boolean;
        };
        category: number | null;
        sub_category: number | null;
        country: number | null;
      }
    >({
      query: (body) => ({
        url: END_POINTS.getAllProducts,
        method: "POST",
        body,
      }),
    }),
    postSubscribe: builder.mutation<SubscribeRes, { email: string }>({
      query: (body) => ({
        url: END_POINTS.subscribe,
        method: "POST",
        body,
      }),
    }),
  }),
});
export const {
  useLazyGetCountryListQuery,
  useLazyGetCategoryListQuery,
  usePostRfpMutation,
  useLazyGetLatestProductsQuery,
  useLazyGetRecommendedProductsQuery,
  useLazyGetPopularProductsQuery,
  useGetSameSupplierMutation,
  useGetSimilarProductsMutation,
  useLazyGetProductByIdQuery,
  useGetAllProductsMutation,
  usePostSubscribeMutation,
} = homeApi;
