import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ManageAddressType } from "../../types/User";
import { Dispatch, SetStateAction } from "react";

type CheckoutProps = {
  payment?: boolean;
  onClick?: () => void;
  details?: ManageAddressType | undefined;
  selectedAddress: ManageAddressType | undefined;
  setSelectedAddress: Dispatch<SetStateAction<any>>;
};

const CheckoutCard = ({
  onClick,
  details,
  payment = false,
  selectedAddress,
  setSelectedAddress,
}: CheckoutProps) => {
  const handleSelectedAddress = async () => {
    setSelectedAddress(details);
  };

  return (
    <div
      onClick={handleSelectedAddress}
      style={{ lineHeight: "25px" }}
      className="chekcard"
    >
      <div className="address">
        {/* <p>Address 1</p> */}
        {/* <h6>{payment ? "Change" : "Edit"}</h6> */}
      </div>

      <div className="cardDiv">
        <div key={details?.id} style={{ marginTop: "10px" }}>
          <h5>
            {details?.first_name || ""} {details?.last_name || ""}
          </h5>
          <p>{details?.address || ""}</p>
          <p>
            Phone No- {details?.country_code || ""} {details?.phone_no || ""}
          </p>
        </div>
        {!payment ? (
          <div className="radioicon">
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="female"
                  control={
                    <Radio
                      checked={details === selectedAddress ? true : false}
                    />
                  }
                  label=""
                />
              </RadioGroup>
            </FormControl>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CheckoutCard;
