import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import "./Table.scss";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  useGetAllOrdersListMutation,
  useLazyGetRevenueQuery,
  usePostGraphMutation,
} from "../../service/sellerPanel/Orders";
import OrdersReportTable from "./Reports/OrdersReports";
import { OrderType } from "./ManageOrder";
import { ManageProduct, ManageUser } from "../../types/General";
import { useGetProductsMutation } from "../../service/sellerPanel/Products";
import ProductsReportTable from "./Reports/ProductReports";
import { useGetAllUsersListMutation } from "../../service/sellerPanel/Users";
import UsersReportTable from "./Reports/UsersReports";
import useAuth from "../../hooks/UseAuth";
import { API_URL, END_POINTS } from "../../contansts/Urls";

const Report = () => {
  const user = useAuth();
  const [getOrderList] = useGetAllOrdersListMutation();
  const [getProductMethod] = useGetProductsMutation();
  const [getAllUserMethod] = useGetAllUsersListMutation();
  const [getGraph] = usePostGraphMutation();
  const [getRevenue] = useLazyGetRevenueQuery();

  const [orderData, setOrderData] = useState<OrderType[]>([]);
  const [productData, setProductData] = useState<ManageProduct[]>([]);
  const [users, setUsers] = useState<ManageUser[]>([]);
  const [graphtype, setGraphType] = useState("2");
  const [activeCase, setActiveCase] = useState(1);
  const [values, setValues] = useState<number[]>([]);
  const [revenue, setRevenue] = useState<number>();

  const handleChange = (event: SelectChangeEvent) => {
    setGraphType(event.target.value as string);
    hangleUserGraph(Number(event.target.value));
  };

  const options: ApexOptions = {
    chart: {
      type: "line",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    stroke: {
      colors: ["#61439F"],
      curve: "smooth",
      width: 6,
      lineCap: "round",
    },
    xaxis: {
      categories:
        Number(graphtype) === 2
          ? ["S", "M", "T", "W", "T", "F", "S"]
          : ["W1", "W2", "W3", "W4"],
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    fill: {
      colors: ["#61439F"],
      opacity: 0.9,
      type: "solid",
    },

    tooltip: {
      enabled: true,
      onDatasetHover: {
        highlightDataSeries: false,
      },
    },
    series: [
      {
        name: "Series 1",
        data: values,
      },
    ],
    colors: ["#61439F"],
  };

  const getAllUserData = async () => {
    try {
      let body = {
        limit: 5,
        offset: 1,
      };
      const res = await getAllUserMethod(body).unwrap();
      if (res?.code === 200) {
        setUsers(res?.data || []);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getProductsData = async () => {
    try {
      let body = {
        start: 1,
        brand: "",
        id: "",
        start_date: "",
        end_date: "",
        product_category: "",
        is_approved: true,
        length: 5,
        search: {
          value: "",
          regex: false,
        },
      };
      const res = await getProductMethod(body).unwrap();
      if (res?.code === 200) {
        setProductData(res?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOrderDetail = async () => {
    try {
      let body = {
        start: 1,
        length: 5,
        search: {
          value: "",
          regex: false,
        },
      };
      const res = await getOrderList(body).unwrap();
      if (res?.code === 200) {
        const flattenedData = res?.data.flatMap((item) =>
          item.ordered_product.map((product) => ({
            ...product,
            user: `${product?.user?.first_name || ""} ${
              product?.user?.last_name || ""
            }`,
            product: product?.product?.product_name || "",
            product_category: product?.product?.product_category?.name || "",
            product_sub_category:
              product.product.product_sub_category.name || "",
            brand: product?.product?.brand?.name || "",
            delivery_status: product?.delivery_status || 0,
            order_id: item?.id || 0,
            product_order_id: product?.id || 0,
            quantity: product?.quantity || 0,
            cost: product?.cost || 0,
          }))
        );
        setOrderData(flattenedData || []);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleCsv = async () => {
    if (activeCase === 1) {
      const url = `${API_URL}${END_POINTS.exportCsvOrders}${user?.user_company}/`;
      window.open(url, "_blank");
    } else if (activeCase === 2) {
      const url = `${API_URL}${END_POINTS.productCsv}${user?.user_company}/`;
      window.open(url, "_blank");
    } else {
      const url = `${API_URL}${END_POINTS.userCsv}${user?.user_company}`;
      window.open(url, "_blank");
    }
  };

  const hangleUserGraph = async (val: number) => {
    const body = {
      filter_type: val,
    };
    try {
      const response = await getGraph(body).unwrap();
      if (response?.code === 200) {
        if (response?.data !== null) {
          setValues(Object.values(response?.data));
        }
      }
    } catch (error: any) {
      // showError(error?.data?.message || "");
      console.log(error);
    }
  };

  const handleGetRevenue = async () => {
    try {
      const response = await getRevenue({}).unwrap();
      if (response?.code === 200) {
        setRevenue(response?.data);
      }
    } catch (error: any) {
      // showError(error?.data?.message || "");
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetRevenue();
    hangleUserGraph(Number(graphtype));
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeCase === 1) {
      getOrderDetail();
    } else if (activeCase === 2) {
      getProductsData();
    } else {
      getAllUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCase]);

  return (
    <div className="addprdct">
      <div className="producthead">
        <h3>Report Management</h3>
      </div>
      <div className="misChart">
        <div className="chartHead">
          <div className="revenue">
            <h4>Total Revenue</h4>
            <h2>${revenue || 0}</h2>
          </div>

          <div className="filter">
            <FormControl size="small">
              <Select
                value={graphtype}
                onChange={handleChange}
                sx={{
                  padding: 0,
                  boxShadow: "1px 1px 5px 4px #0000001A",
                }}
              >
                <MenuItem value={2}>Daily</MenuItem>
                <MenuItem value={3}>Weekly</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <ReactApexChart
          options={options}
          series={options.series}
          type="line"
          height={350}
        />
      </div>
      <div className="orderBar">
        <div className="btns">
          <div
            onClick={() => setActiveCase(1)}
            className={`${activeCase === 1 ? "active_tabs" : ""} tabs`}
          >
            <h4>Orders</h4>
          </div>
          <div
            onClick={() => setActiveCase(2)}
            className={`${activeCase === 2 ? "active_tabs" : ""} tabs`}
          >
            <h4>Products</h4>
          </div>
          <div
            onClick={() => setActiveCase(3)}
            className={`${activeCase === 3 ? "active_tabs" : ""} tabs`}
          >
            <h4>Users</h4>
          </div>
        </div>
        <div className="export">
          <span onClick={handleCsv}>Export CSV</span>
          <figure>
            <img src="/static/images/download.svg" alt="download" />
          </figure>
        </div>
      </div>
      {activeCase === 1 ? (
        <OrdersReportTable orders={orderData} />
      ) : activeCase === 2 ? (
        <ProductsReportTable products={productData} />
      ) : (
        <UsersReportTable users={users} />
      )}
    </div>
  );
};

export default Report;
