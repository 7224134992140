import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./utils/protectedRoutes";
import { Loading } from "./components";
import { PageNotFound } from "./pages";

// Lazy load your page components
const HomePage = lazy(() => import("./pages/homePage"));
const ProductDetail = lazy(() => import("./pages/productDetail"));
const CartPage = lazy(() => import("./pages/cartPage"));
const Profile = lazy(() => import("./pages/profile"));
const Checkout = lazy(() => import("./pages/checkout"));
const MyOrder = lazy(() => import("./pages/myOrders"));
const ManageAddress = lazy(() => import("./pages/manageAddress"));
const Payment = lazy(() => import("./pages/payment"));
const About = lazy(() => import("./pages/aboutUs"));
const ContactUs = lazy(() => import("./pages/contactUs"));
const Terms = lazy(() => import("./pages/terms"));
const Faq = lazy(() => import("./pages/faq"));
const PrivatePolicy = lazy(() => import("./pages/privatePolicy"));
const Seller = lazy(() => import("./pages/seller"));
const Products = lazy(() => import("./pages/allProducts"));
const Notifications = lazy(() => import("./pages/notifications"));

const Routing = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<Loading />}>
            <HomePage />
          </Suspense>
        }
      />
      <Route
        path="/:id"
        element={
          <Suspense fallback={<Loading />}>
            <ProductDetail />
          </Suspense>
        }
      />
      <Route
        path="/cart"
        element={
          <ProtectedRoutes>
            <Suspense fallback={<Loading />}>
              <CartPage />
            </Suspense>
          </ProtectedRoutes>
        }
      />
      <Route
        path="/profile"
        element={
          // <ProtectedRoutes>
          <Suspense fallback={<Loading />}>
            <Profile />
          </Suspense>
          // </ProtectedRoutes>
        }
      />
      <Route
        path="/checkout"
        element={
          <ProtectedRoutes>
            <Suspense fallback={<Loading />}>
              <Checkout />
            </Suspense>
          </ProtectedRoutes>
        }
      />
      {/* Rest of the routes with code splitting */}
      <Route
        path="/my-order"
        element={
          <Suspense fallback={<Loading />}>
            <MyOrder />
          </Suspense>
        }
      />
      <Route
        path="/manage-address"
        element={
          <Suspense fallback={<Loading />}>
            <ManageAddress />
          </Suspense>
        }
      />
      <Route
        path="/payment"
        element={
          <Suspense fallback={<Loading />}>
            <Payment />
          </Suspense>
        }
      />
      <Route
        path="/about-us"
        element={
          <Suspense fallback={<Loading />}>
            <About />
          </Suspense>
        }
      />
      <Route
        path="/terms-&-conditions"
        element={
          <Suspense fallback={<Loading />}>
            <Terms />
          </Suspense>
        }
      />
      <Route
        path="/contact-us"
        element={
          <Suspense fallback={<Loading />}>
            <ContactUs />
          </Suspense>
        }
      />
      <Route
        path="/faq"
        element={
          <Suspense fallback={<Loading />}>
            <Faq />
          </Suspense>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <Suspense fallback={<Loading />}>
            <PrivatePolicy />
          </Suspense>
        }
      />
      <Route
        path="/notifications"
        element={
          <Suspense fallback={<Loading />}>
            <Notifications />
          </Suspense>
        }
      />
      <Route
        path="/seller"
        element={
          <ProtectedRoutes>
            <Suspense fallback={<Loading />}>
              <Seller />
            </Suspense>
          </ProtectedRoutes>
        }
      />
      <Route
        path="/products"
        element={
          <Suspense fallback={<Loading />}>
            <Products />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<Loading />}>
            <PageNotFound />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default Routing;
