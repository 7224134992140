import { END_POINTS } from "../contansts/Urls";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type PostLoginBody = {
  email: string;
  password: string;
  deviceToken?: string;
  deviceType?: string;
};
type PostLoginResponse = {
  code: number;
  data?: User;
  message: string;
};

type PostSignupBody = {
  first_name: string;
  last_name?: string;
  business_email: string;
  office_phone_no: string;
  office_country_code: string;
  office_country_iso_code: string;
  company_phone_no?: string;
  company_country_iso_code: string;
  company_country_code: string;
  job_title?: string;
  company_name?: string | null;
  // company_mobile_no: string;
  location?: string;
};

type PostSignupResponse = {
  code: number;
  data?: any;
  message: string;
};

type PostForgotPasswordResponse = {
  code: number;
  data?: User;
  message: string;
};
type PostChangePasswordBody = {
  current_password: string;
  new_password: string;
};
type PostChangePasswordResponse = {
  code: number;
  data?: any;
  message: string;
};
type GetProfileResponse = {
  code: number;
  data?: User;
  message: string;
};
type PutUpdateProfileBody = {
  first_name: string;
  last_name?: string;
  job_title?: string;
  location?: string;
  image?: string | number;
};
type PutUpdateProfileResponse = {
  code: number;
  data?: any;
  message: string;
};
type PostLogoutResponse = {
  code: number;
  data?: any;
  message: string;
};

type PostCompanyDomainResponse = {
  code: number;
  data?: any;
  message: string;
};

type PostOtpResponse = {
  code: number;
  data: string;
  message: string;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postLogin: builder.mutation<PostLoginResponse, PostLoginBody>({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),
    postSignup: builder.mutation<PostSignupResponse, PostSignupBody>({
      query: (body) => ({
        url: END_POINTS.signUp,
        method: "POST",
        body,
      }),
    }),
    postForgotPassword: builder.mutation<
      PostForgotPasswordResponse,
      { email: string; verify?: string }
    >({
      query: (body) => ({
        url: END_POINTS.forgotPassword,
        method: "PUT",
        body,
      }),
    }),
    postChangePassword: builder.mutation<
      PostChangePasswordResponse,
      PostChangePasswordBody
    >({
      query: (body) => ({
        url: END_POINTS.changePassword,
        method: "PUT",
        body,
      }),
    }),
    getProfile: builder.query<GetProfileResponse, unknown>({
      query: () => ({
        url: END_POINTS.getProfile,
        method: "GET",
      }),
    }),
    putUpdateProfile: builder.mutation<
      PutUpdateProfileResponse,
      PutUpdateProfileBody
    >({
      query: (body) => ({
        url: END_POINTS.updateProfile,
        method: "PUT",
        body,
      }),
    }),
    postLogout: builder.mutation<PostLogoutResponse, unknown>({
      query: () => ({
        url: END_POINTS.logout,
        method: "POST",
      }),
    }),
    postCompanyDomain: builder.mutation<
      PostCompanyDomainResponse,
      { company_domain?: string; email: string }
    >({
      query: (body) => ({
        url: END_POINTS.companyDomain,
        method: "POST",
        body,
      }),
    }),
    otpVerfication: builder.mutation<
      PostOtpResponse,
      { email: string | undefined; otp: string }
    >({
      query: (body) => ({
        url: END_POINTS.otp_verify,
        method: "PUT",
        body,
      }),
    }),

    otpSignUpVerification: builder.mutation<
      PostOtpResponse,
      { email: string | undefined; otp: string }
    >({
      query: (body) => ({
        url: END_POINTS.signUp_otp_verify,
        method: "POST",
        body,
      }),
    }),

    resetPassword: builder.mutation<
      {
        code: number;
        data: string;
        message: string;
      },
      { encoded_id: string | null; password: string }
    >({
      query: (body) => ({
        url: END_POINTS.resetPassword,
        method: "PUT",
        body,
      }),
    }),

    resendOtp: builder.mutation<
      { code: number; data: string; message: string },
      { email?: string | undefined }
    >({
      query: (body) => ({
        url: END_POINTS.resendPassword,
        method: "POST",
        body,
      }),
    }),

    logout: builder.mutation<{ code: number }, {}>({
      query: (body) => ({
        url: END_POINTS.logout,
        method: "POST",
        body,
      }),
    }),
  }),
});
export const {
  usePostLoginMutation,
  usePostSignupMutation,
  usePostChangePasswordMutation,
  usePostForgotPasswordMutation,
  useLazyGetProfileQuery,
  usePutUpdateProfileMutation,
  usePostLogoutMutation,
  usePostCompanyDomainMutation,
  useOtpVerficationMutation,
  useResetPasswordMutation,
  useLogoutMutation,
  useOtpSignUpVerificationMutation,
  useResendOtpMutation,
} = authApi;
