import {
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useChangeRoleStatusMutation,
  useEditRoleMutation,
  useLazyGetAllRolesQuery,
  usePostAddRoleMutation,
} from "../../service/sellerPanel/Permissions";
import { Loader, errorToast, successToast } from "../../helpers";
import { RoleData } from "../../types/General";
import { isString } from "../../utils/validations";

const ManageRoles = () => {
  const [roles, setRoles] = useState<RoleData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [selectedId, setSelectedId] = useState<number>();

  const [addRoleMethod] = usePostAddRoleMutation();
  const [getRolesMethod] = useLazyGetAllRolesQuery();
  const [changeStatusMethod] = useChangeRoleStatusMutation();
  const [editRoleMutation] = useEditRoleMutation();

  const formik = useFormik({
    initialValues: {
      addRole: "",
    },
    validationSchema: Yup.object({
      addRole: Yup.string().label("Roles").required().min(2).max(30),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      try {
        let body = {
          name: formik.values.addRole,
        };
        setLoading(true);
        if (!isEdit) {
          const res = await addRoleMethod(body).unwrap();
          setLoading(false);
          if (res?.code === 200 || res?.code === 201) {
            successToast("Role added successfully");
            getRolesData();
            formik.resetForm();
          }
        } else {
          setLoading(true);
          const res = await editRoleMutation({
            body,
            role_id: selectedId,
          }).unwrap();
          setLoading(false);
          if (res?.code === 200 || res?.code === 201) {
            successToast("Role updated successfully");
            getRolesData();
            setIsEdit(false);
            formik.resetForm();
          }
        }
      } catch (error: any) {
        setLoading(false);
        errorToast(error?.data?.message || "");
      }
      formik.setSubmitting(false);
    },
  });

  const handleChangeStatus = async (id: number, item: any) => {
    try {
      let body = {
        is_active: item,
      };
      setLoading(true);
      const res = await changeStatusMethod({ body, role_id: id }).unwrap();
      setLoading(false);
      if (res?.code === 200 || res?.code === 201) {
        successToast(
          `Status ${item ? "activated" : "deactivated"} Successfully`
        );

        getRolesData();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getRolesData = async () => {
    try {
      setLoading(true);
      const res = await getRolesMethod({}).unwrap();
      setLoading(false);
      if (res?.code === 200 || res?.code === 201) {
        setRoles(res?.data || []);
      }
    } catch (error: any) {
      setLoading(false);
      // errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getRolesData();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="addprdct">
      <div className="producthead">
        <h3>Manage Roles</h3>
      </div>
      <Loader isLoad={loading} />
      <form onSubmit={formik.handleSubmit}>
        <div className="addrole">
          <TextField
            className="text_field"
            placeholder="Add role"
            id="addRole"
            name="addRole"
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else if (isString(val.target.value)) {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            value={formik.values.addRole}
            helperText={formik.touched.addRole && formik.errors.addRole}
          />
          {isEdit ? (
            <div>
              <button disabled={formik.values.addRole ? false : true}>
                Update
              </button>
              <button
                onClick={() => {
                  setIsEdit(false);
                  formik.resetForm();
                }}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button type="submit" disabled={formik.isSubmitting}>
              + Add
            </button>
          )}
        </div>
      </form>
      <TableContainer
        component={Paper}
        sx={{ padding: 0 }}
        className="tablemain"
      >
        <Table
          sx={{ minWidth: 650, position: "relative" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          {roles?.length ? (
            roles?.map((item, index) => (
              <TableBody key={index}>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {item?.name || ""}
                  </TableCell>
                  <TableCell>
                    <BorderColorIcon
                      onClick={() => {
                        setIsEdit(true);
                        setSelectedId(item?.id);
                        formik.setFieldValue("addRole", item?.name);
                        // setAddRole(item?.name);
                      }}
                      sx={{ fontSize: 15, color: "#61439F" }}
                    />
                    {/* <RemoveRedEyeIcon sx={{ color: "green" }} /> */}
                  </TableCell>
                  <TableCell>
                    <Switch
                      // defaultChecked
                      checked={item?.is_active}
                      onChange={(e) =>
                        handleChangeStatus(item?.id, e.target.checked)
                      }
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            ))
          ) : (
            <TableBody className="noTableDatarole2">
              <h4>Roles not found</h4>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default ManageRoles;
