import React from 'react'

const TitleBar = (props:any) => {
  return (
    <div className='titleBar'>
      <h2>{props.name}</h2>
    </div>
  )
}

export default TitleBar
