import { useEffect } from "react";
import "./App.scss";
import Routing from "./Routes";
import LanguageContextProvider from "./context/Language";
import { useLazyGetCmsListQuery } from "./service/Common";
import { useAppDispatch } from "./hooks/Store";
import { resetCms } from "./reducers/commonSlice";

function App() {
  const dispatch = useAppDispatch();

  const [getCmsMethod] = useLazyGetCmsListQuery();

  const getCmsData = async () => {
    try {
      const res = await getCmsMethod({}).unwrap();
      if (res?.code === 200) {
        dispatch(resetCms({ cms: res?.data || null }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCmsData();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  // console.log = () => {};

  return (
    <LanguageContextProvider>
      <Routing />
    </LanguageContextProvider>
  );
}

export default App;
