import { END_POINTS } from "../contansts/Urls";
import emptySplitApi from "../utils/rtk";

type OrderProducts = {
  id: number;
  ordered_product: {
    id: number;
    user: {
      id: number;
      first_name: string;
      last_name: string;
    };
    product: {
      id: 42;
      product_name: string;
      product_image: string;
      product_category: {
        id: number;
        name: string;
      };
      product_sub_category: {
        id: number;
        name: string;
      };
      brand: {
        id: number;
        name: string;
      };
    };
    quantity: number;
    cost: number;
    created_at: string;
    delivery_status: number;
  }[];
};

type GetAllOrdersResponse = {
  data: OrderProducts[];
  code: number;
  message: string;
  recordsTotal: number;
};

export const ordersApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllOrders: builder.mutation<
      GetAllOrdersResponse,
      {
        length: number | undefined;
        start: number | undefined;
        search: {
          value: string | null;
          regex: boolean;
        };
      }
    >({
      query: (body) => ({
        url: END_POINTS.getAllOrders,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetAllOrdersMutation } = ordersApi;
