import { MenuItem, Select, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { Dispatch, SetStateAction } from "react";
import { Brand, Category } from "../../../types/General";

type props = {
  setProductId: Dispatch<SetStateAction<string>>;
  productId: string;
  setDates: Dispatch<SetStateAction<any>>;
  dates: any;
  categories: Category[];
  setCategory: Dispatch<SetStateAction<string>>;
  category: string;
  setBrand: Dispatch<SetStateAction<string>>;
  brand: string;
  brandsData: Brand[];
};

const Filterbar = ({
  setProductId,
  productId,
  setDates,
  dates,
  categories,
  category,
  setCategory,
  brand,
  setBrand,
  brandsData,
}: props) => {
  const handleBrand = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setBrand(event.target.value);
  };

  const handleCategory = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCategory(event.target.value);
  };

  return (
    <div className="filtermain">
      <TextField
        value={productId}
        onChange={(e) => setProductId(e.target.value)}
        placeholder="Enter product ID"
        className="txt"
      />
      <div className="brandselect">
        <Select
          sx={{ padding: 0 }}
          value={brand}
          placeholder="Select Brand"
          onChange={handleBrand}
          displayEmpty
          inputProps={{
            "aria-label": "Without label",
          }}
          className="srvc-sel"
        >
          <MenuItem value="" disabled>
            {"Select Brand"}
          </MenuItem>
          {brandsData?.length
            ? brandsData
                ?.filter((ele) => ele.name !== null)
                ?.map((item, index) => {
                  return (
                    <MenuItem key={index} className="srcv-lst" value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  );
                })
            : null}
        </Select>
      </div>
      <div className="cateselect">
        <Select
          sx={{ padding: 0 }}
          value={category}
          placeholder="Select Category"
          onChange={handleCategory}
          displayEmpty
          inputProps={{
            "aria-label": "Without label",
          }}
          className="srvc-sel"
        >
          <MenuItem value="" disabled>
            {"Select Category"}
          </MenuItem>
          {categories?.length
            ? categories?.map((item, index) => {
                return (
                  <MenuItem key={index} className="srcv-lst" value={item?.id}>
                    {item?.name}
                  </MenuItem>
                );
              })
            : null}
        </Select>
      </div>

      <div className="daterange">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["SingleInputDateRangeField"]}>
            <DateRangePicker
              slots={{ field: SingleInputDateRangeField }}
              format="YYYY-MM-DD"
              value={dates}
              onChange={(e) => {
                console.log(e, "lpl");
                setDates(e);
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default Filterbar;
