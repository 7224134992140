import { CartData } from "../types/General";

type OrderProps = {
  status?: boolean;
  data: CartData[] | [];
};

const OrderCard = ({ status = true, data }: OrderProps) => {
  return (
    <div>
      {data?.length
        ? data?.map((items) => (
            <div key={items?.id} className="orders-data">
              <figure className="order-img">
                <img
                  src={
                    items?.product?.product_images?.length
                      ? items?.product?.product_images[0]?.image?.media_file_url
                      : null
                  }
                  alt="data-img"
                ></img>
              </figure>
              <div className="product-details">
                <h4 style={{ color: "#61439F" }}>
                  {items?.product?.product_name || ""}
                </h4>
                <div style={{ width: "70%" }}>
                  <p>
                    {`Size: ${items?.product?.product_key_dimension} ${items?.product?.product_unit}` ||
                      ""}
                  </p>
                </div>
                <p>QTY:{items?.quantity || ""}</p>
                <p>Actual Price: ${items?.total_amount || ""}</p>
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

export default OrderCard;

//  {
//   status?  <div
//   // style={{
//   //   backgroundColor:
//   //     items.status === "compeleted" ? "#FF3131" : "#008000",
//   // }}
//   className="order-status"
// >
//   // <p style={{ fontSize: "12px" }}>{items.status}</p>
//   <p style={{ fontSize: "10px" }}>on Thu , 8 Jul</p>
// </div>: null
//  }
