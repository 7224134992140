import { useState } from "react";
import { Button, OrderCard, TitleBar } from "../../components";
import { AddNewCard, CheckoutCard, PayCard } from "../../features";
import "../checkout/Checkout.scss";
import { Box, Modal } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../layout/header";
import Copyright from "../../components/Copyright";
import useTranslation from "../../hooks/Translation";
import { ManageAddressType } from "../../types/User";
import {
  useLazyGetCartItemQuery,
  usePutPaymentStatusMutation,
} from "../../service/Booking";
import { Loader, errorToast } from "../../helpers";
import { useDispatch } from "react-redux";
import { resetCart } from "../../reducers/commonSlice";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@mui/material";

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const translation = useTranslation() as any;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedAddress, setSelectedAddress] = useState<ManageAddressType>(
    state?.selectedAddress
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [bookingAmount, setBookingAmount] = useState<number>(
    state?.bookingAmount
  );

  const [payNowMethod] = usePutPaymentStatusMutation();
  const [getCart] = useLazyGetCartItemQuery();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "70%" : "500px",
    bgcolor: "background.paper",
    border: "2px solid #fff",
    boxShadow: 24,
    outline: "none",
    p: 4,
    borderRadius: 4,
  };

  const getCartDetail = async () => {
    try {
      const res = await getCart({}).unwrap();
      if (res?.code === 200) {
        console.log(res?.data, "9090");
        setBookingAmount(res?.token_amount || 0);
        dispatch(
          resetCart({
            cart: res?.data || [],
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePayNowButton = async () => {
    try {
      let body = {
        payment_status: true,
        booking_amount: bookingAmount,
      };
      setLoading(true);
      const res = await payNowMethod({ order_id: state?.id, body }).unwrap();
      setLoading(false);
      if (res?.code === 200 || res?.code === 201) {
        getCartDetail();
        handleOpen();
        setTimeout(() => {
          handleClose();
          navigate("/", { replace: true });
        }, 3000);
      }
    } catch (error: any) {
      if (error?.status === 588) {
        getCartDetail();
      }
      errorToast(error?.data?.message || "");
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <Loader isLoad={loading} />
      <div className="checkmain wrapcls container">
        <TitleBar name="Checkout" />
        <div className="chmain">
          <div className="checkBar">
            <div className="cbLeft">
              <h2>{translation.checkout.paymentInfo}</h2>
            </div>
            <div className="cbRyt">
              <h2>{translation.checkout.YourOder}</h2>
            </div>
          </div>
          <div className="checkdetail">
            <div className="cDLeft">
              <CheckoutCard
                setSelectedAddress={setSelectedAddress}
                selectedAddress={selectedAddress}
                // key={index}
                details={state?.selectedAddress}
              />
              {/* {details?.map((item, index) => {
                return (
                  <CheckoutCard
                    setSelectedAddress={setSelectedAddress}
                    selectedAddress={selectedAddress}
                    key={index}
                    details={item}
                  />
                );
              })} */}
              <PayCard />
              <AddNewCard />
            </div>
            <div className="cDRight">
              <OrderCard data={state?.cartDetail} status={false} />
              <div className="Pricedetail">
                <h3>Price Details</h3>
                {/* <div className="pdleft">
                  <h4>Total amount</h4>
                  <h4>{`$ ${
                    state?.totalAmount
                      ? parseFloat(state?.totalAmount).toFixed(2)
                      : ""
                  }`}</h4>
                </div> */}
                <div className="pdleft">
                  <h4>Booking amount</h4>
                  <h4>
                    {`$ ${bookingAmount ? bookingAmount.toFixed(2) : ""}`}
                  </h4>
                </div>
                {/* <div className="pdleft">
                  <h4>Booking Amount Product 1</h4>
                  <h4>$500.00</h4>
                </div>
                <div className="total">
                  <h3>Total</h3>
                  <h3>$1000.00</h3>
                </div> */}
              </div>
              <div className="btn">
                <Button value="Pay Now" onClick={handlePayNowButton} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal */}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="congmodal">
              <figure>
                <img src="/static/images/cong.png" alt="Congratulations" />
              </figure>
              <h3>Congratulations!</h3>
              <p>Payment done successfully</p>
              <div className="btn">
                <Button
                  value="Back to home"
                  onClick={() => navigate("/", { replace: true })}
                />
              </div>
            </div>
          </Box>
        </Modal>
      </div>
      <Copyright />
    </>
  );
};

export default Payment;
