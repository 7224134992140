import CartInfo from "../../features/cartDetail/CartInfo";
import "./CartPage.scss";
import Header from "../../layout/header";

const CartPage = () => {
  return (
    <>
      <Header />
      <CartInfo />
    </>
  );
};

export default CartPage;
