import { END_POINTS } from "../../contansts/Urls";
import { RoleData } from "../../types/General";
import emptySplitApi from "../../utils/rtk";

type GetRolesByIdResponse = {
  data?: RoleData;
  code: number;
  message?: string;
};

type AddRoleResponse = {
  data?: RoleData;
  code: number;
  message?: string;
};

type GetAllRolesResponse = {
  data?: RoleData[];
  code: number;
  message?: string;
  count?: number;
};

type PostChangeRoleBody = {
  is_active?: boolean;
};

type PostChangeRoleParams = {
  role_id?: number;
  body: PostChangeRoleBody;
};

type EditRoleParams = {
  role_id?: number;
  body: { name: string };
};

type AddRoleBody = { role_id: number; module_id: Number[] };

export const permissionsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllRoles: builder.query<GetAllRolesResponse, unknown>({
      query: () => ({
        url: END_POINTS.getRoles,
        method: "GET",
      }),
    }),

    getRolesById: builder.query<
      GetRolesByIdResponse,
      { role_id?: number | undefined }
    >({
      query: ({ role_id }) => ({
        url: `${END_POINTS.getRoleById}${role_id}/`,
        method: "GET",
      }),
    }),

    postAddRole: builder.mutation<AddRoleResponse, { name: string }>({
      query: (body) => ({
        url: END_POINTS.addRole,
        method: "POST",
        body,
      }),
    }),

    changeRoleStatus: builder.mutation<
      GetRolesByIdResponse,
      PostChangeRoleParams
    >({
      query: ({ body, role_id }) => ({
        url: `${END_POINTS.changeRoleStatus}${role_id}/`,
        method: "PUT",
        body,
      }),
    }),

    editRole: builder.mutation<GetRolesByIdResponse, EditRoleParams>({
      query: ({ body, role_id }) => ({
        url: `${END_POINTS.editRole}${role_id}/`,
        method: "PUT",
        body,
      }),
    }),

    getRolesPermissions: builder.query<GetAllRolesResponse, unknown>({
      query: () => ({
        url: END_POINTS.getRolesAndPermissions,
        method: "GET",
      }),
    }),

    postAddRolePermissions: builder.mutation<AddRoleResponse, AddRoleBody[]>({
      query: (body) => ({
        url: END_POINTS.addRolesAndPermissions,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  // useLazyGetUsersByIdQuery,
  // useChangeUserStatusMutation,

  useLazyGetAllRolesQuery,
  useGetRolesByIdQuery,
  useChangeRoleStatusMutation,
  usePostAddRoleMutation,
  useEditRoleMutation,
  useLazyGetRolesPermissionsQuery,
  usePostAddRolePermissionsMutation,
} = permissionsApi;
