import { END_POINTS } from "../contansts/Urls";
import { CmsData, FaqData } from "../types/General";
import emptySplitApi from "../utils/rtk";
type PostUploadMediaBody = {
  file: string;
};
type PostUploadImageResponse = {
  statusCode: number;
  data?: any;
  message: string;
};
type GetCmsResponse = {
  data?: CmsData | undefined;
  code: number;
  message?: string;
};
type GetFaqResponse = {
  data?: FaqData[];
  code: number;
  message?: string;
};
type PostContactUsResponse = {
  code: number;
  data?: any;
  message: string;
};
type PostContactUsBody = {
  email: string;
  message: string;
  location: string;
  company_phone_no: string;
  company_country_code: string;
  company_country_iso_code: string;
  company_name: string;
};
export const commonApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postUploadMedia: builder.mutation<
      PostUploadImageResponse,
      PostUploadMediaBody
    >({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),
    getFaqList: builder.query<GetFaqResponse, unknown>({
      query: () => ({
        url: END_POINTS.getFaq,
        method: "GET",
      }),
    }),
    getCmsList: builder.query<GetCmsResponse, unknown>({
      query: () => ({
        url: END_POINTS.getCms,
        method: "GET",
      }),
    }),
    postContactUs: builder.mutation<PostContactUsResponse, PostContactUsBody>({
      query: (body) => ({
        url: END_POINTS.contactUs,
        method: "POST",
        body,
      }),
    }),
  }),
});
export const {
  usePostUploadMediaMutation,
  useLazyGetFaqListQuery,
  useLazyGetCmsListQuery,
  usePostContactUsMutation,
} = commonApi;
