import { useEffect, useState } from "react";
import { Button, TitleBar } from "../../components";
import { CheckoutCard, CheckoutProduct } from "../../features";
import AddIcon from "@mui/icons-material/Add";
import "./Checkout.scss";
import "../profile/Profile.scss";
import { useNavigate } from "react-router-dom";
import Header from "../../layout/header";
import Copyright from "../../components/Copyright";
import useTranslation from "../../hooks/Translation";
import {
  useLazyGetCartItemQuery,
  usePostOrderPlaceMutation,
} from "../../service/Booking";
import { CartData } from "../../types/General";
import { useLazyGetAddressesQuery } from "../../service/Address";
import { ManageAddressType } from "../../types/User";
import { Loader, errorToast, warnToast } from "../../helpers";

const Checkout = () => {
  const navigate = useNavigate();
  const translation = useTranslation() as any;
  // const [address, setAddress] = useState(false);
  const [cartDetail, setCartDetail] = useState<CartData[]>([]);
  const [totalAmount, setTotalAmount] = useState<number>();
  const [bookingAmount, setBookingAmount] = useState<number>();
  const [getAddresses, setGetAddresses] = useState<ManageAddressType[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<ManageAddressType>();

  const [getCartMethod] = useLazyGetCartItemQuery();
  const [getAllAddressMethod] = useLazyGetAddressesQuery();
  const [placeOrderMethod] = usePostOrderPlaceMutation();

  const handleAddClick = () => {
    navigate("/seller", { state: "address" });
  };

  const handleOrderClick = async () => {
    if (!getAddresses?.length) {
      warnToast("Please add address");
    } else {
      try {
        let body = {
          order_first_name: selectedAddress?.first_name || "",
          order_last_name: selectedAddress?.last_name || "",
          order_address: selectedAddress?.address || "",
          order_state: selectedAddress?.state || "",
          order_zipcode: selectedAddress?.zipcode || "",
          order_country_code: selectedAddress?.country_code || "",
          order_country_iso_code: selectedAddress?.country_iso_code || "",
          order_phone_no: selectedAddress?.phone_no || "",
          // cart_id: number;
        };
        const res = await placeOrderMethod(body).unwrap();
        if (res?.code === 200 || res?.code === 201) {
          let selected = {
            selectedAddress,
            cartDetail,
            id: res?.data?.order_id,
            totalAmount,
            bookingAmount,
          };
          navigate("/payment", { state: selected });
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        setLoading(false);
      }
    }
  };

  const getCartDetail = async () => {
    try {
      setLoading(true);
      const res = await getCartMethod({}).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        setCartDetail(res?.data);
        setBookingAmount(res?.token_amount || 0);
        setTotalAmount(res?.cart_amount || 0);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getAllAddressData = async () => {
    setLoading(true);
    try {
      const res = await getAllAddressMethod({}).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        setGetAddresses(res?.data || []);
        if (res?.data?.length) {
          setSelectedAddress(res?.data[0]);
        }
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error, "lpplperror");
    }
  };

  useEffect(() => {
    getCartDetail();
    getAllAddressData();
  }, []);

  return (
    <>
      <Header />
      <Loader isLoad={loading} />
      <div className="checkmain wrapcls container">
        <TitleBar name="Checkout" />
        <div className="chmain">
          <div className="checkBar">
            <div className="cbLeft">
              <h2>{translation.checkout.billingdetail}</h2>
            </div>
            <div className="cbRyt">
              <h2>{translation.checkout.YourOder}</h2>
            </div>
          </div>
          <div className="checkdetail">
            <div className="cDLeft">
              {getAddresses?.length
                ? getAddresses?.map((item, index) => {
                    return (
                      <CheckoutCard
                        setSelectedAddress={setSelectedAddress}
                        selectedAddress={selectedAddress}
                        key={index}
                        details={item}
                      />
                    );
                  })
                : null}
              <div className="addaddress" onClick={handleAddClick}>
                <AddIcon />
                <h3>Add Address</h3>
              </div>
            </div>

            <div className="cDRight">
              <CheckoutProduct cartDetail={cartDetail} />
              <div className="Pricedetail">
                <h3>Price Details</h3>
                {/* <div className="pdleft">
                  <h4>Total amount</h4>
                  <h4>{`$ ${
                    totalAmount ? parseFloat(`${totalAmount}`).toFixed(2) : ""
                  }`}</h4>
                </div> */}
                <div className="pdleft">
                  <h4>Booking amount</h4>
                  <h4>{`$ ${
                    bookingAmount
                      ? parseFloat(`${bookingAmount}`).toFixed(2)
                      : ""
                  }`}</h4>
                </div>
                {/* <div className="total">
                  <h3>Total</h3>
                  <h3>$1000.00</h3>
                </div> */}
              </div>
              <div className="btn">
                <Button value="Place Order" onClick={handleOrderClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Copyright />
    </>
  );
};

export default Checkout;
