import { TextField } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";
import ReactStars from "react-stars";
import { usePostReviewMutation } from "../../service/sellerPanel/Orders";
import { errorToast, successToast } from "../../helpers";
import { ProductDetail } from "../../types/General";

type OrderProps = {
  data: ProductDetail | undefined;
  setOpenReview: Dispatch<SetStateAction<boolean>>;
};

const Rating = ({ data, setOpenReview }: OrderProps) => {
  const [error, setError] = useState(false);
  const [ratingCount, setratingCount] = useState(0);
  const [feedback, setFeedback] = useState("");

  const [addReview] = usePostReviewMutation();

  const ratingChanged = (newRating: number) => {
    setratingCount(newRating);
  };

  const handleSubmit = async () => {
    if (ratingCount === 0) {
      setError(true);
    } else {
      const body = {
        user: data?.user?.id || 0,
        product: data?.product?.id || 0,
        rating: ratingCount || 0,
        review: feedback.length ? feedback : "",
      };
      try {
        const response = await addReview(body).unwrap();
        if (response?.code === 201) {
          successToast(response?.message || "");
          console.log(response?.data, "review");
          setOpenReview(false);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  return (
    <div>
      <div className="auth">
        <h3 className="logout2">Rate this product</h3>
        <div className="rating">
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={40}
            color1={"#dfdfdf"}
            color2={"#ffd700"}
            value={ratingCount}
            half={false}
          />
        </div>
        {error && ratingCount === 0 ? (
          <h6 className="err_msg2">Rating is required.</h6>
        ) : (
          ""
        )}

        <div className="fControl">
          <TextField
            placeholder="Give us feedback"
            className="text_area"
            type="text"
            fullWidth
            margin="none"
            name="rate"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            rows={4}
            multiline
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
        </div>

        <div className="submitBtn">
          <button className="btn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Rating;
