import { store } from "../app/Store";
import { toggleCookieStatus } from "../reducers/commonSlice";

const showCookie = () => {
  store.dispatch(toggleCookieStatus({ cookiePresent: false }));
};

const hideCookie = () => {
  store.dispatch(toggleCookieStatus({ cookiePresent: true }));
};

export { showCookie, hideCookie };
