import * as Yup from "yup";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { Dispatch, SetStateAction } from "react";
import { usePostForgotPasswordMutation } from "../../service/Auth";
import Loader from "../../helpers/Loader";
import { errorToast, successToast } from "../../helpers/showToast";

type forgotPasswordProps = {
  setActiveModal: Dispatch<SetStateAction<number>>;
  setEmail: Dispatch<SetStateAction<string>>;
  setOtpModalType: Dispatch<SetStateAction<string>>;
};

const ForgotModal = ({
  setActiveModal,
  setEmail,
  setOtpModalType,
}: forgotPasswordProps) => {
  const [ForgotMutation, ForgotMutationData] = usePostForgotPasswordMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .required("Email is required.")
        .label("Email")
        .matches(
          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
          "Must be a valid email."
        ),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      let body = {
        email: formik.values.email,
      };
      try {
        const response = await ForgotMutation(body).unwrap();
        if (response?.code === 200) {
          successToast(response?.message || "");
          setEmail(body?.email || "");
          setOtpModalType("Forgot");
          setActiveModal(4);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error, "errorrr");
      }

      formik.setSubmitting(false);
    },
  });
  return (
    <>
      <Loader isLoad={ForgotMutationData.isLoading} />
      <div className="loginmodal">
        <h2>Forgot Password</h2>
        <p className="para">
          Forgot your password? Don't worry we will send an otp on your
          registered email.
        </p>

        <form onSubmit={formik.handleSubmit}>
          <div className="inputs2">
            <div className="inputdiv">
              <TextField
                className="text_field"
                placeholder="Enter your email"
                fullWidth
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
            <div onClick={() => setActiveModal(1)}>
              <h4> Back to Login</h4>
            </div>

            <div className="forpadding" />
            <div className="btnddiv">
              <button
                type="submit"
                disabled={formik.isSubmitting}
                className="btn"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ForgotModal;
