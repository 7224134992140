import dayjs from "dayjs";
import "./Copyright.scss";
const Copyright = () => {
  return (
    <div className="bottombar">
      <div className="container">
        <h5>Copyright {dayjs().year()} - All right Recived by e nilcon</h5>
      </div>
    </div>
  );
};

export default Copyright;
