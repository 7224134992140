import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import type { RootState } from "../app/Store";
import { API_URL } from "../contansts/Urls";
import { errorToast } from "../helpers";
import { generateEncryptedKey, generateEncryptedKeyBody } from "./encryption";

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const { token } = (getState() as RootState).auth;
    const encryptedData = generateEncryptedKey();
    if (token) {
      headers.set(
        "authorization",
        `Bearer ${String(token).replaceAll('"', "")}`
      );
    }
    headers.set("Hash", `${encryptedData?.hash}`);
    headers.set("Sek", ` ${encryptedData?.sek}`);
    return headers;
  },
});
const baseQueryWithAuth: BaseQueryFn<
  string | FetchArgs | any,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  if (args.body) {
    const encryptedData = generateEncryptedKeyBody(args.body);
    if (encryptedData) {
      args.body = encryptedData;
    } else {
      return {
        error: {
          status: 400,
          data: { detail: "Failed to encrypt request body" },
        },
      };
    }
  }
  const result = await baseQuery(args, api, extraOptions);

  if (result.error?.status === 400) {
    const errors = Object.values(result?.error?.data || {});
    console.log({ errors });
    if (errors?.length > 1 && errors[1] === 400) {
      return result;
    }
    if (errors?.length) {
      const error = errors[0] as any;
      if (error?.length) {
        // ShowToast(error[0]);
      }
    }
  }
  if (result.error?.status === 401) {
    // api.dispatch(resetAuth());
    // if (window) {
    //   window.location.replace("/");
    // }
  }
  // if (result.error?.status === 401) {
  //  ShowToast(result?.error?.data?.detail || 'Something went wrong!');
  //  // navigate('SignInAndSignUp', undefined);
  // }
  if (result.error?.status && result?.error?.status === 502) {
    errorToast("Server error!");
  }
  // if (
  //   result.error &&
  //   result.error.status === 401 &&
  //   (api.getState() as RootState).auth.user
  // )
  return result;
};
const emptySplitApi = createApi({
  baseQuery: baseQueryWithAuth,
  tagTypes: ["UNAUTHORIZED", "UNKNOWN_ERROR"],
  endpoints: () => ({}),
});
export default emptySplitApi;
