import { useEffect, useState } from "react";
import "./ManageOrder.scss";
import { Visibility } from "@mui/icons-material";
import {
  IconButton,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import UserDetails from "./userDetails";
import {
  useAssignRoleMutation,
  useChangeUserStatusMutation,
  useGetAllUsersListMutation,
  useLazyGetUsersByIdQuery,
} from "../../service/sellerPanel/Users";
import { ManageUser, RoleData } from "../../types/General";
import { Loader, errorToast, successToast } from "../../helpers";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useLazyGetRolesPermissionsQuery } from "../../service/sellerPanel/Permissions";

type props = {
  activeTab: number;
};

const ManageUsers = ({ activeTab }: props) => {
  const [userId, setUserId] = useState<boolean>(false);
  const [users, setUsers] = useState<ManageUser[]>([]);
  const [userByIdData, setUserByIdData] = useState<ManageUser>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<RoleData[]>([]);
  const totalPages = Math.ceil(totalCount / 10);

  const goToPage = (page: React.SetStateAction<number>) => {
    setCurrentPage(page);
  };

  const [getAllUserMethod] = useGetAllUsersListMutation();
  const [getUserByIdMethod] = useLazyGetUsersByIdQuery();
  const [changeStatusMethod] = useChangeUserStatusMutation();
  const [assignRoleMethod] = useAssignRoleMutation();
  const [getRolesMethod] = useLazyGetRolesPermissionsQuery();

  const handleChange = async (e: any, userId: any) => {
    try {
      let body = {
        user_id: Number(userId),
        role_id: Number(e.target.value),
      };
      const res = await assignRoleMethod(body).unwrap();
      if (res?.code === 200 || res?.code === 201) {
        successToast(res?.message || "");
        getAllUserData();
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const tableStyle = {
    backgroundColor: "#FFFFFF",
    borderRadius: "12px",
  };

  const getAllUserData = async () => {
    try {
      let body = {
        limit: 10,
        offset: currentPage,
      };
      setLoading(true);
      const res = await getAllUserMethod(body).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        setUsers(res?.data || []);

        setTotalCount(res?.count || 0);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChangeStatus = async (id: number, item: any) => {
    try {
      let body = {
        is_active: item,
      };
      const res = await changeStatusMethod({ body, user_id: id }).unwrap();
      if (res?.code === 200) {
        successToast("Status changed successfully");
        getAllUserData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewAction = async (id: number) => {
    setLoading(true);
    try {
      const res = await getUserByIdMethod({ user_id: id }).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        setUserByIdData(res?.data || undefined);
        setUserId(true);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getRolesData = async () => {
    try {
      setLoading(true);
      const res = await getRolesMethod({}).unwrap();
      setLoading(false);
      if (res?.code === 200 || res?.code === 201) {
        setRoles(res?.data || []);
      }
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRolesData();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllUserData();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    setUserId(false);
  }, [activeTab]);

  return (
    <>
      {!userId ? (
        <div className="addprdct">
          <div className="producthead">
            <h3>Manage Users</h3>
          </div>
          <Loader isLoad={loading} />
          <TableContainer sx={tableStyle} className="tablemain">
            <Table className="tableMain" sx={{ position: "relative" }}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email ID</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>

              {users?.length ? (
                users?.map((row) => (
                  <TableBody key={row.id}>
                    <TableRow>
                      <TableCell>
                        {row?.first_name || ""} {row?.last_name || ""}
                      </TableCell>
                      <TableCell>{row?.email || ""}</TableCell>
                      <TableCell>
                        {row?.office_country_code || ""}{" "}
                        {row?.office_phone_no || ""}
                      </TableCell>
                      <TableCell>{row?.location || ""}</TableCell>
                      <TableCell sx={{ width: "30px", padding: 0 }}>
                        <Select
                          sx={{
                            padding: 0,
                            width: "110px",
                            border: "1px solid #B4B4B4",
                          }}
                          value={row?.user_website_roles?.id || "Assign Role"}
                          placeholder="Assign Role"
                          onChange={(e) => handleChange(e, row?.id)}
                          displayEmpty
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          className={
                            row?.user_website_roles?.id
                              ? "mageuser_slt"
                              : "mageuserslt"
                          }
                        >
                          <MenuItem value="" disabled>
                            {"Assign Role"}
                          </MenuItem>
                          {roles?.length
                            ? roles
                                ?.filter((item) => item.name !== "")
                                ?.map((item, index) => (
                                  <MenuItem
                                    key={index}
                                    className="srcv-lst"
                                    value={item?.id?.toString()}
                                  >
                                    {item?.name || ""}
                                  </MenuItem>
                                ))
                            : null}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleViewAction(row?.id)}>
                          <Visibility sx={{ color: "#00931C" }} />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Switch
                          // defaultChecked
                          checked={row?.is_active}
                          onChange={(item) =>
                            handleChangeStatus(row?.id, item.target.checked)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))
              ) : (
                <TableBody className="noTableDatarole2">
                  <h4>Users not found</h4>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <div
            className="pagination"
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {users?.length ? (
              <div className="pages">
                <button
                  className="prevbtn"
                  onClick={() => goToPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <ArrowBackIosIcon />
                </button>
                <div className="count">
                  {totalPages > 6
                    ? Array.from({ length: totalPages })
                        .slice(0, 6)
                        .map((_, index) => (
                          <button
                            className={
                              currentPage === index + 1 ? "actv" : "inActv"
                            }
                            key={index}
                            onClick={() => goToPage(index + 1)}
                            disabled={currentPage === index + 1}
                          >
                            {index + 1}
                          </button>
                        ))
                        .concat(
                          <span key="ellipsis" className="ellipsis">
                            ...
                          </span>,
                          <button
                            className={"actv"}
                            style={{
                              display:
                                currentPage < 6 || currentPage === totalPages
                                  ? "none"
                                  : undefined,
                            }}
                            key={totalPages}
                          >
                            {currentPage}
                          </button>,
                          <button
                            className={
                              currentPage === totalPages ? "actv" : "inActv"
                            }
                            key={totalPages}
                            onClick={() => goToPage(totalPages)}
                            disabled={currentPage === totalPages}
                          >
                            {totalPages}
                          </button>
                        )
                    : Array.from({ length: totalPages }).map((_, index) => (
                        <button
                          className={
                            currentPage === index + 1 ? "actv" : "inActv"
                          }
                          key={index}
                          onClick={() => goToPage(index + 1)}
                          disabled={currentPage === index + 1}
                        >
                          {index + 1}
                        </button>
                      ))}
                </div>
                <button
                  className="prevbtn"
                  onClick={() => goToPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <ArrowForwardIosIcon
                  // className="prevbtn"
                  // onClick={() => goToPage(currentPage + 1)}
                  />
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <UserDetails data={userByIdData} setUserId={setUserId} />
      )}
    </>
  );
};

export default ManageUsers;
