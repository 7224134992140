import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import { Button, ProductCard, TitleBar } from "../../components";
import Slider from "react-slick";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ManageProduct, ProductBtId } from "../../types/General";
import { Box, IconButton, Modal, Tooltip } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import ReactStars from "react-stars";
import {
  useAddToCartMutation,
  useDecreaseCartItemMutation,
  useDeleteFromCartMutation,
  useIncreaseCartItemMutation,
  useLazyGetCartItemQuery,
  useLazyGetProductInCartItemQuery,
} from "../../service/Booking";
import { Loader, errorToast, successToast, warnToast } from "../../helpers";
import useAuth from "../../hooks/UseAuth";
import { useAppDispatch, useAppSelector } from "../../hooks/Store";
import usePermissions from "../../hooks/Permissions";
import {
  getCartData,
  resetCart,
  toggleAuthModal,
  updateQuantity,
} from "../../reducers/commonSlice";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@mui/material";
import { PermissionEnums } from "../../contansts/permissions";
import {
  LazyLoadComponent,
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type ProductProps = {
  verified?: boolean;
  onClick?: () => void;
  similarProduct: ManageProduct[];
  sameSupplierData: ManageProduct[];
  data?: ProductBtId | undefined;
  scrollPosition: ScrollPosition;
};

const ProductInfo = ({
  similarProduct,
  sameSupplierData,
  data,
  scrollPosition,
}: ProductProps) => {
  const user = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const permissions = usePermissions();

  const [addCartMutation, { isLoading }] = useAddToCartMutation();
  const [increaseCartItem] = useIncreaseCartItemMutation();
  const [decreaseCartItem] = useDecreaseCartItemMutation();
  const [getProductInCart] = useLazyGetProductInCartItemQuery();
  const [deleteFromCart] = useDeleteFromCartMutation();
  const [getCart] = useLazyGetCartItemQuery();

  const [open, setOpen] = useState(false);
  const [addToCartId, setAddToCartId] = useState<number>(0);
  const [cartCount, setCartCount] = useState<number>(0);
  const [maxIncrease, setMaxIncrease] = useState<string>();
  const [currentSlide, setCurrentSlide] = useState(0);
  const cart = useAppSelector(getCartData);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedDocs, setSelectedDocs] = useState<any[]>([]);
  const [imageRes, setImageRes] = useState<string>();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    afterChange: (current: any) => setCurrentSlide(current),
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    border: "none",
    outline: "none",
    borderRadius: 2,
    width: isSmallScreen ? "60%" : "650px",
    height: isSmallScreen ? "60%" : "400px",
  };

  const addToCart = async (id: number | undefined) => {
    const body = {
      product: Number(id),
    };
    try {
      const response = await addCartMutation(body).unwrap();
      if (response?.code === 200) {
        successToast(response?.message || "");
        setAddToCartId(response?.data?.id);
        setMaxIncrease(data?.product_stock);
        setCartCount(response?.data?.quantity);
        dispatch(
          resetCart({
            cart: [...cart, response?.data],
          })
        );
      }
    } catch (error: any) {
      errorToast(error?.data?.detail || "");
      console.log(error, "ERROR");
    }
  };

  const increaseItem = async (id: number | undefined, quan: number) => {
    if (cartCount !== Number(maxIncrease)) {
      try {
        const response = await increaseCartItem({ product_id: id }).unwrap();
        if (response?.code === 200) {
          const maxIncreaseNumber = Number(maxIncrease);

          const quantity = Math.min(
            response?.data?.quantity || 0,
            maxIncreaseNumber || Infinity
          );
          setCartCount(quantity);
          dispatch(
            updateQuantity({
              productId: Number(id),
              quantity: Number(quan) + 1,
            })
          );
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error, "ERROR");
      }
    } else {
      warnToast("You have reached the limit");
    }
  };

  const decreaseItem = async (id: number | undefined, quan: number) => {
    if (cartCount === 1) {
      if (addToCartId) {
        deleteItem(addToCartId);
      }
      return;
    }
    try {
      const response = await decreaseCartItem({ product_id: id }).unwrap();
      if (response?.code === 200) {
        setCartCount(response?.data?.quantity);
        dispatch(
          updateQuantity({
            productId: Number(id),
            quantity: Number(quan) - 1,
          })
        );
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
      console.log(error, "ERROR");
    }
  };

  const deleteItem = async (id: number) => {
    try {
      const response = await deleteFromCart({ product_id: id }).unwrap();
      if (response?.code === 200) {
        checkProductInCart();
      }
    } catch (error: any) {
      console.log(error, "ERROR");
    }
  };

  const checkProductInCart = async () => {
    try {
      if (data?.id) {
        const response = await getProductInCart({
          product_id: data.id,
        }).unwrap();
        console.log(response);
        if (response?.code === 200) {
          setCartCount(response?.data?.quantity);
          setAddToCartId(response?.data?.cart_id);
        } else {
          setCartCount(0);
          setAddToCartId(0);
        }
      }
    } catch (error) {
      console.log(error);
      setCartCount(0);
      setAddToCartId(0);
    }
  };

  const scrollToSameSupplier = () => {
    const ele = document.getElementById("same_supplier");
    if (ele) {
      ele.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const getCartDetail = async () => {
    try {
      const res = await getCart({}).unwrap();
      if (res?.code === 200) {
        dispatch(
          resetCart({
            cart: res?.data || [],
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDecrease = () => {
    decreaseItem(addToCartId, Number(cartCount));
    getCartDetail();
  };

  useEffect(() => {
    if (user) {
      checkProductInCart();
    }
  }, [user]);

  useEffect(() => {
    setImageRes(
      data?.product_certificate_image?.media_file_url?.replace("http", "https")
    );
    const pdfUri = [
      {
        url: data?.product_certificate_image?.media_file_url?.replace(
          "http",
          "https"
        ),
      },
    ];
    setSelectedDocs(data?.product_certificate_image === null ? [] : pdfUri);
  }, [data]);

  return (
    <div className="productInfo wrapcls">
      <Loader isLoad={isLoading} />
      <div className="addcart_main">
        <div className="addCart">
          <div className="acLeft">
            <button disabled={!addToCartId} onClick={() => handleDecrease()}>
              <RemoveIcon />
            </button>
            <input disabled value={cartCount || 0} />
            <button
              style={{
                cursor:
                  !addToCartId ||
                  parseInt(data?.product_stock || "0") < 1 ||
                  cartCount === Number(maxIncrease)
                    ? "not-allowed"
                    : "pointer",
              }}
              disabled={
                !addToCartId ||
                parseInt(data?.product_stock || "0") < 1 ||
                cartCount === Number(maxIncrease)
              }
              onClick={() => increaseItem(addToCartId, Number(cartCount))}
            >
              <AddIcon />
            </button>
          </div>
          <div className="acRight">
            <h5 onClick={() => setOpen(true)}>
              View Material Test Certificate
            </h5>
            {parseInt(data?.product_stock || "0") > 0 ? (
              <div className="btn">
                {!cartCount ? (
                  <Tooltip
                    title={
                      user?.user_company === data?.by_company?.id
                        ? "This product is added by your company, you can't buy this product."
                        : ""
                    }
                  >
                    <IconButton style={{ width: "100%" }}>
                      <Button
                        disabled={user?.user_company === data?.by_company?.id}
                        value="Add to Cart"
                        onClick={() => {
                          if (user) {
                            if (
                              user?.is_company_owner ||
                              permissions?.includes(
                                `${PermissionEnums.buyProducts}`
                              )
                            ) {
                              addToCart(data?.id);
                            } else {
                              warnToast(
                                "You don't have permission to buy products"
                              );
                            }
                          } else {
                            dispatch(
                              toggleAuthModal({ isAuthModalVisible: true })
                            );
                          }
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Button value="View Cart" onClick={() => navigate("/cart")} />
                )}
              </div>
            ) : (
              <button className="stock_out">Out of stock</button>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        {/* <Breadcrumb /> */}
        <TitleBar name="Product Detail" />
        <div className="pMain">
          <div className="pLeft">
            <Slider {...settings}>
              {data?.product_images?.length
                ? data?.product_images?.map((item) => (
                    <div key={item?.id}>
                      <figure>
                        <LazyLoadImage
                          alt={"Product"}
                          height={"100%"}
                          src={item?.image?.media_file_url || ""}
                          width={"100%"}
                          effect="blur"
                          scrollPosition={scrollPosition}
                        />
                      </figure>
                    </div>
                  ))
                : null}
            </Slider>
            {data?.product_images?.length === 1 ? null : (
              <p>{`${currentSlide + 1} / ${data?.product_images?.length}`}</p>
            )}
          </div>

          <div className="pRight">
            <div className="info">
              <div className="infoLeft">
                <h3>{data?.product_name || ""}</h3>
                <h3>{`${data?.rating || "No ratings"}`}</h3>
                <li>
                  <ReactStars
                    count={5}
                    size={30}
                    color1={"#dfdfdf"}
                    color2={"#ffd700"}
                    value={data?.rating}
                    half={true}
                    edit={false}
                  />
                </li>
                <p>
                  {/* {`${data?.product_key_dimension} ${data?.product_unit}` || ""} */}
                </p>
                <h5>
                  <strong>
                    Product amount: $
                    {`${
                      data?.commissioned_product_cost
                        ? parseFloat(
                            `${data?.commissioned_product_cost}`
                          ).toFixed(2)
                        : ""
                    }`}
                  </strong>
                </h5>
                <h5>
                  <strong>
                    Booking amount: $
                    {`${
                      data?.token_amount
                        ? parseFloat(`${data?.token_amount}`).toFixed(2)
                        : ""
                    }`}
                  </strong>
                </h5>
              </div>
              <div className="infoRight">
                <figure>
                  <img src="/static/images/verified.png" alt="verified" />
                </figure>
              </div>
            </div>
            <div className="cartData">
              <div className="feature">
                <p>
                  <span>Category :</span> {data?.product_category?.name || ""}
                </p>
                <p>
                  <span>Sub-Category:</span>{" "}
                  {data?.product_sub_category?.name || ""}
                </p>
                <p>
                  <span>Brand:</span> {data?.brand?.name || ""}
                </p>
                <p>
                  <span> Stock:</span>{" "}
                  {parseInt(data?.product_stock || "0") > 0
                    ? data?.product_stock
                    : "Out of stock"}
                </p>
                <p>
                  <span>Origin:</span> {data?.country_of_origin?.name || ""}
                </p>
                {/* <p>
                  Product Location:{" "}
                  {data?.by_company?.user_details?.location || ""}
                </p> */}
                <a onClick={scrollToSameSupplier}>
                  Other products from same supplier
                </a>
                <div className="description">
                  <h3>Product Short Description</h3>
                  <p>{data?.short_description || ""}</p>
                </div>
                <div className="description">
                  <h3>Product Material Description</h3>
                  <p>{data?.detailed_description || ""}</p>
                </div>
                <div className="description">
                  <h3>Product Specification</h3>
                  <p>
                    <span>Product Unit </span>: {data?.product_unit || ""}
                  </p>
                  <p>
                    <span>Material Grade</span>: {data?.material_grade || ""}
                  </p>
                  <p>
                    <span>Material of construction</span>:{" "}
                    {data?.material_of_construction || ""}
                  </p>
                  <p>
                    <span>NACE</span>: {data?.nance ? "Yes" : "No" || ""}
                  </p>
                  <p>
                    <span>Remark</span>: {data?.remark || ""}
                  </p>
                </div>
              </div>
              {data?.assign_sales_consultant?.office_phone_no ? (
                <div
                  className="call"
                  onClick={() => {
                    console.log(
                      data?.assign_sales_consultant?.office_country_iso_code,
                      data?.assign_sales_consultant?.office_phone_no
                    );
                    window.open(
                      `tel:${data?.assign_sales_consultant?.office_country_iso_code}${data?.assign_sales_consultant?.office_phone_no}`,
                      "_blank"
                    );
                  }}
                >
                  <div className="cLeft">
                    <WifiCalling3Icon />
                  </div>
                  <div className="cRight">
                    <h6>Contact Sale Consultant</h6>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="sproduct">
          <div className="tilebar">
            <TitleBar name="Similar Products" />
            <h4 onClick={() => navigate("/products")}>See all</h4>
          </div>
          <div className="prdocts">
            {similarProduct?.length
              ? similarProduct.map((item) => {
                  return (
                    <div
                      onClick={() => setCartCount(0)}
                      className="proCard"
                      key={item.id}
                    >
                      <ProductCard latestProduct={item} />
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
        <div className="sproduct">
          <div className="tilebar">
            <TitleBar name="Other Products From Same Supplier" />
            <h4>See all</h4>
          </div>
          <div className="prdocts">
            {sameSupplierData?.length
              ? sameSupplierData?.map((item) => {
                  return (
                    <div
                      onClick={() => setCartCount(0)}
                      className="proCard"
                      key={item.id}
                    >
                      <ProductCard latestProduct={item} />
                    </div>
                  );
                })
              : ""}
            <div id="same_supplier" />
          </div>
        </div>
      </div>
      <LazyLoadComponent>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="certi_modal" style={{ position: "relative" }}>
              <ClearIcon onClick={() => setOpen(false)} className="cross_icn" />
              <figure style={{ width: "100%", height: "100%" }}>
                {selectedDocs?.length ? (
                  <a href={imageRes} target="_blank" rel="noopener noreferrer">
                    <Document file={selectedDocs[0]}>
                      <Page pageNumber={1} />
                    </Document>
                  </a>
                ) : null}
              </figure>
            </div>
          </Box>
        </Modal>
      </LazyLoadComponent>
    </div>
  );
};

export default trackWindowScroll(ProductInfo);
