import "./ManageOrder.scss";
import { ManageUser } from "../../types/General";

type props = {
  data?: ManageUser;
  setUserId: any;
};

const UserDetails = ({ data, setUserId }: props) => {
  const productData = [
    {
      id: 1,
      label: [
        {
          id: 1,
          type: "Full Name",
          user: `${data?.first_name || ""} ${data?.last_name || ""}`,
        },
        {
          id: 2,
          type: "Email Address",
          user: data?.email || "",
        },
        {
          id: 3,
          type: "Phone Number",
          user: `${data?.office_country_code || ""} ${
            data?.office_phone_no || ""
          }`,
        },
        {
          id: 4,
          type: "Location",
          user: data?.location || "",
        },
        {
          id: 5,
          type: "Role",
          user: "Manager Sales",
        },
      ],
      productimg:
        data?.image?.media_file_url || "/static/images/profilepic.png",
    },
  ];

  return (
    <>
      <div className="manageUser">
        <div className="userHead">
          <h3>User Details</h3>
          <div className="addrole">
            <button onClick={() => setUserId(false)}>Back</button>
          </div>
        </div>
        <div className="userDetail">
          {productData.map((item) => {
            return (
              <div className="userMain" key={item?.id}>
                <div className="userImg">
                  <figure>
                    <img src={item?.productimg} alt="product" />
                  </figure>
                </div>
                <ul>
                  {item?.label?.map((value, i) => {
                    return (
                      <li key={value.id}>
                        <h3>{value?.type}</h3>
                        <p>{value?.user}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default UserDetails;
