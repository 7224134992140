import { Button as _Button } from "@mui/material";

type ButtonProps = {
  value: string;
  gradient?: boolean;
  fontSize?: number;
  onClick?: () => void;
  disabled?: boolean;
};

const Button = ({
  value,
  onClick,
  gradient = false,
  fontSize = 15,
  disabled,
}: ButtonProps) => {
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <_Button
      disabled={disabled}
      onClick={onClick}
      style={{
        background: gradient
          ? "linear-gradient(180deg, #FA6866 0%, #F7895A 100%)"
          : "transparent linear-gradient(180deg, #6045A0 0%, #47B8E9 100%) 0% 0% no-repeat padding-box",
        fontFamily: "regular",
        fontWeight: "normal",
        fontSize,
        textTransform: "capitalize",
        width: "100%",
        padding: "8px",
      }}
      variant="contained"
    >
      {value}
    </_Button>
  );
};

export default Button;
