import { END_POINTS } from "../../contansts/Urls";
import {
  Brand,
  Category,
  CountriesResponse,
  ManageProduct,
  SubCategory,
} from "../../types/General";
import emptySplitApi from "../../utils/rtk";

type GetAllProductsResponse = {
  data: ManageProduct[];
  code: number;
  message?: string;
  count?: number;
  recordsTotal?: number;
  pending_product_obj_count?: number;
};

type GetAllProductsBody = {
  start: number;
  brand?: number | string | undefined;
  id?: number | string | undefined;
  start_date?: string;
  end_date?: string;
  product_category?: number | string | undefined;
  is_unapproved?: boolean;
  is_approved?: boolean;
  length: number;
  search: any;
};

type GetBrandsResponse = {
  data: Brand[];
  code: number;
  message?: string;
  count?: number;
  recordsTotal?: number;
};

type GetFiltersPaginatedBody = {
  start: number;
  length: number;
  is_active?: boolean;
  search: {
    value: string;
    regex: boolean;
  };
};

type GetProductByIdResponse = {
  data: ManageProduct;
  code: number;
  message?: string;
};

type GetCategoryResponse = {
  data: Category[];
  code: number;
  message?: string;
  count?: number;
  recordsTotal?: number;
};

type GetCountriesResponse = {
  data: CountriesResponse[];
  code: number;
  message?: string;
  count?: number;
  recordsTotal?: number;
};

type GetSubCategoryResponse = {
  data: SubCategory[];
  code: number;
  message?: string;
};

type ProductImage = {
  image: number;
};

type PostAddProductBody = {
  product_name: string;
  short_description: string;
  product_category: number | null;
  product_sub_category: number | null;
  detailed_description: string;
  product_cost: string;
  brand: number | null;
  product_stock: string;
  product_key_dimension: string;
  remark: string;
  material_grade: string;
  material_of_construction: string;
  product_unit: string;
  country_of_origin: number;
  nance: boolean;
  product_certificate_image?: number;
  by_company?: number;
  product_images?: ProductImage[];
};

type PutUpdateParams = {
  body: PostAddProductBody;
  product_id?: number;
};

type PostAddProductResponse = {
  code?: number;
  message?: string;
};

export const productsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.mutation<GetAllProductsResponse, GetAllProductsBody>({
      query: (body) => ({
        url: END_POINTS.getManageProducts,
        method: "POST",
        body,
      }),
    }),

    getBrands: builder.mutation<GetBrandsResponse, GetFiltersPaginatedBody>({
      query: (body) => ({
        url: END_POINTS.getBrands,
        method: "POST",
        body,
      }),
    }),

    getProductByIdData: builder.query<
      GetProductByIdResponse,
      { product_id?: number | undefined }
    >({
      query: ({ product_id }) => ({
        url: `${END_POINTS.getProductById}${product_id}/`,
        method: "GET",
      }),
    }),

    getProductCategories: builder.mutation<
      GetCategoryResponse,
      GetFiltersPaginatedBody
    >({
      query: (body) => ({
        url: END_POINTS.getAllCat,
        method: "POST",
        body,
      }),
    }),

    getSubCatById: builder.query<
      GetSubCategoryResponse,
      { category_id?: number | undefined }
    >({
      query: ({ category_id }) => ({
        url: `${END_POINTS.getSubCat}${category_id}/`,
        method: "GET",
      }),
    }),

    getAllCountries: builder.mutation<
      GetCountriesResponse,
      GetFiltersPaginatedBody
    >({
      query: (body) => ({
        url: END_POINTS.getAllCountries,
        method: "POST",
        body,
      }),
    }),

    postAddProduct: builder.mutation<
      PostAddProductResponse,
      PostAddProductBody
    >({
      query: (body) => ({
        url: END_POINTS.addProduct,
        method: "POST",
        body,
      }),
    }),

    putUpdateProduct: builder.mutation<PostAddProductResponse, PutUpdateParams>(
      {
        query: ({ body, product_id }) => ({
          url: `${END_POINTS.updateProduct}${product_id}/`,
          method: "PUT",
          body,
        }),
      }
    ),
    putChangeProductStatus: builder.mutation<
      PostAddProductResponse,
      { body: { is_active: boolean }; product_id: string }
    >({
      query: ({ body, product_id }) => ({
        url: `${END_POINTS.changeProductStatus}${product_id}/`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetProductsMutation,
  useGetBrandsMutation,
  useGetProductCategoriesMutation,
  useLazyGetSubCatByIdQuery,
  useGetAllCountriesMutation,
  usePostAddProductMutation,
  usePutUpdateProductMutation,
  useLazyGetProductByIdDataQuery,
  usePutChangeProductStatusMutation,
} = productsApi;
