import React from "react";
import Layout from "../../layout";
import "./Notifications.scss";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { Card } from "@mui/material";
const Notifications = () => {
  const messages = [
    {
      id: 1,
      title: "New notification",
      message:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      id: 2,
      title: "New notification",
      message:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      id: 3,
      title: "New notification",
      message:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      id: 4,
      title: "New notification",
      message:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
  ];
  return (
    <Layout>
      <div className="notificationWrapper wrapcls">
        <div className="container">
          <div className="notificationHead">
            <h2>Notifications</h2>
          </div>
          <div className="body">
            {messages?.map((items) => (
              <Card key={items.id} className="notificationBody">
                <CircleNotificationsIcon
                  sx={{ color: "#61439F", fontSize: "40px", padding: "4px" }}
                />
                <div className="content">
                  <h3>{items.title}</h3>
                  <p>{items.message}</p>
                </div>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Notifications;
