import { useState } from "react";
import { Button } from "../../components";
import RfpModal from "../modals/RfpModal";

const ShopNow = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  return (
    <div className="container">
      <div className="shopmain">
        <div className="shopmaiCcontent">
          <h2>We're Here To Help</h2>
          <p>
            In case you are unable to find your desired product, kindly fill out
            the RFP (Request for Product) form. This will enable our teams to
            complete your request.
          </p>

          <div className="btn">
            <Button value="RFP Form" onClick={handleOpen} />
          </div>
        </div>
      </div>
      <div>
        <RfpModal IsOpen={open} setOpen={setOpen} />
      </div>
    </div>
  );
};

export default ShopNow;
