import secureLocalStorage from "react-secure-storage";

export const STORAGE_KEYS = {
  token: "TOKEN",
  userData: "USER_DATA",
  credentials: "REMEMBER_ME",
  tempToken: "TEMP_TOKEN",
  fcmToken: "FCM_TOKEN",
  language: "LANGUAGE",
  isAuthrorised: "IS_AUTH",
};

export const setToStorage = (key: string, data: any) => {
  secureLocalStorage.setItem(key, data);
};

export const getFromStorage = (key: string) => {
  return secureLocalStorage.getItem(key);
};

export const removeFromStorage = (key: string) => {
  secureLocalStorage.removeItem(key);
};
// export function storeData(key: string, value: any) {
//   localStorage.setItem(key, value);
// }
// export function getData(key: string) {
//   return localStorage.getItem(key);
// }
// export function clearData() {
//   const keys = [STORAGE_KEYS.token];
//   localStorage.multiRemove(keys);
// }
// export function removeData(key: string) {
//   localStorage.removeItem(key);
// }
