import { useEffect, useState } from "react";
import { Button, TitleBar } from "../../components";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import {
  useDecreaseCartItemMutation,
  useDeleteFromCartMutation,
  useIncreaseCartItemMutation,
  useLazyGetCartItemQuery,
} from "../../service/Booking";
import { CartData } from "../../types/General";
import { Loader, errorToast, successToast, warnToast } from "../../helpers";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Modal } from "@mui/material";
import { resetCart, updateQuantity } from "../../reducers/commonSlice";
import { useAppDispatch } from "../../hooks/Store";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@mui/material";

const CartInfo = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [getCart, { isLoading }] = useLazyGetCartItemQuery();
  const [deleteFromCart, deletaFromCartData] = useDeleteFromCartMutation();
  const [increaseCartItem] = useIncreaseCartItemMutation();
  const [decreaseCartItem] = useDecreaseCartItemMutation();

  const [cartDetail, setCartDetail] = useState<CartData[]>([]);

  const [open, setOpen] = useState(false);
  const [totalAmount, setTotalAmount] = useState<number>();
  const [bookingAmount, setBookingAmount] = useState<number>();
  const [selectedId, setSelectedId] = useState<number>();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "77%" : "400px",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
    outline: "none",
    borderRadius: 2,
  };

  const getCartDetail = async () => {
    try {
      const res = await getCart({}).unwrap();
      if (res?.code === 200) {
        setCartDetail(res?.data);
        dispatch(
          resetCart({
            cart: res?.data || [],
          })
        );
        setBookingAmount(res?.token_amount || 0);
        setTotalAmount(res?.cart_amount || 0);
      }
    } catch (error) {
      setCartDetail([]);
      setBookingAmount(0);
      setTotalAmount(0);
      console.log(error);
    }
  };

  const deleteItem = async (id: number) => {
    try {
      const response = await deleteFromCart({ product_id: id }).unwrap();
      if (response?.code === 200) {
        successToast(response?.message || "");
      }
      getCartDetail();
      dispatch(
        resetCart({
          cart: response?.data || [],
        })
      );
    } catch (error: any) {
      errorToast("Product is removed from cart successfully" || "");
      console.log(error, "ERROR");
    }
  };

  const increaseItem = async (ele: CartData | undefined) => {
    console.log(ele?.quantity, "state");
    if (ele?.quantity !== Number(ele?.product?.product_stock)) {
      try {
        const response = await increaseCartItem({
          product_id: ele?.id,
        }).unwrap();
        if (response?.code === 200) {
          const updatedCartDetail = cartDetail.map((item) => {
            if (item.id === ele?.id) {
              return {
                ...item,
                quantity: response?.data?.quantity || 0,
              };
            }
            return item;
          });
          getCartDetail();
          setCartDetail(updatedCartDetail);

          // setBookingAmount(Number(response?.data?.token_amount || 0));
          // setTotalAmount(response?.data?.cart_amount || 0);
          dispatch(
            updateQuantity({
              productId: Number(ele?.id),
              quantity: Number(ele?.quantity) + 1,
            })
          );
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error, "ERROR");
      }
    } else {
      warnToast("You have reached the limit");
    }
  };

  const decreaseItem = async (ele: CartData | undefined) => {
    if (ele?.quantity === 1) {
      deleteItem(ele?.id);
    } else {
      try {
        const response = await decreaseCartItem({
          product_id: ele?.id,
        }).unwrap();
        if (response?.code === 200) {
          const updatedCartDetail = cartDetail.map((item) => {
            if (item.id === ele?.id) {
              return {
                ...item,
                quantity: response?.data?.quantity || 0,
              };
            }
            return item;
          });
          getCartDetail();
          setCartDetail(updatedCartDetail);
          // setBookingAmount(Number(response?.data?.token_amount || 0));
          // setTotalAmount(response?.data?.cart_amount || 0);
          dispatch(
            updateQuantity({
              productId: Number(ele?.id),
              quantity: Number(ele?.quantity) - 1,
            })
          );
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error, "ERROR");
      }
    }
  };

  useEffect(() => {
    getCartDetail();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="wrapcls cartInfo ptb">
      <Loader isLoad={isLoading || deletaFromCartData?.isLoading} />
      <div className="container">
        <TitleBar name="Cart" />
        {cartDetail?.length ? (
          <div className="table_rep">
            <table>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Product Name</th>
                  <th>Booking Amount</th>
                  <th>Quantity</th>
                  <th>Action</th>
                </tr>
              </thead>
              {cartDetail.length
                ? cartDetail.map((item) => {
                    return (
                      <tbody key={item.id}>
                        <tr>
                          <td>
                            {item?.product?.product_images?.length
                              ? item?.product?.product_images
                                  ?.slice(0, 1)
                                  .map((item, i) => {
                                    return (
                                      <figure key={i}>
                                        <img
                                          src={
                                            item?.image?.media_file_url || ""
                                          }
                                          alt={
                                            item?.product?.product_name || ""
                                          }
                                        />
                                      </figure>
                                    );
                                  })
                              : null}
                          </td>
                          <td>
                            {item?.product?.product_name || ""}
                            <span>
                              {`Size: ${item?.product?.product_key_dimension} ${item?.product?.product_unit}` ||
                                ""}
                            </span>
                          </td>
                          <td>
                            ${" "}
                            {`${
                              item?.product?.token_amount
                                ? parseFloat(
                                    `${item?.product?.token_amount}`
                                  ).toFixed(2)
                                : 0
                            }`}
                          </td>
                          <td>
                            <div className="acLeft">
                              <button>
                                <RemoveIcon
                                  onClick={() => decreaseItem(item)}
                                />
                              </button>
                              <h3>{item?.quantity || 0}</h3>
                              <button>
                                <AddIcon onClick={() => increaseItem(item)} />
                              </button>
                            </div>
                          </td>
                          <td>
                            <DeleteIcon
                              style={{ color: "red" }}
                              // onClick={() => deleteItem(item?.id)}
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(Number(item?.id));
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    );
                  })
                : null}
            </table>
          </div>
        ) : (
          <div className="noProduct">
            <figure>
              <img src="/static/images/cartno.jpeg" alt="cart-img" />
            </figure>
            <h3>Cart is empty</h3>
          </div>
        )}
      </div>
      {totalAmount || bookingAmount ? (
        <div className="addcart_main">
          <div className="addCart ">
            <div className="acLeft2">
              {/* <h3>${totalAmount || 0} (Total amount)</h3> */}
              <h3>
                {"$" + Number(bookingAmount).toFixed(2) || ""} (Total Booking
                amount)
              </h3>
            </div>
            <div className="acRight2">
              <div className="btn">
                <Button
                  value="Checkout"
                  onClick={() => navigate("/checkout")}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="warning-modal">
            <h3>Are you sure, you want to remove this product from cart?</h3>
            <div className="btn-div">
              <button
                className="yes"
                onClick={() => {
                  setOpen(false);
                  deleteItem(Number(selectedId));
                }}
              >
                Yes
              </button>
              <button className="no" onClick={() => setOpen(false)}>
                No
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CartInfo;
