const Loading = () => {
  return (
    <div className="load_logo">
      <figure>
        <img src="/static/images/logo.png" alt="logo" />
      </figure>
    </div>
  );
};

export default Loading;
