import { Checkbox, InputAdornment, TextField } from "@mui/material";

const AddNewCard = () => {
  return (
    <div className="addcar profilemain">
      <h3>Add New Card</h3>
      <div className="inputdiv">
        <TextField
          className="adornment"
          placeholder="Card number"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" className="iconador">
                <figure>
                  <img src="/static/images/lock.svg" alt="lock" />
                </figure>
              </InputAdornment>
            ),
          }}
        />
        <TextField placeholder="Card Holder Name" fullWidth />
        <div className="firstname">
          <div className="fdiv">
            <TextField placeholder="Expiration (MM / YY)" fullWidth />
          </div>
          <div className="fdiv">
            <TextField
              className="adornment"
              placeholder="CVV"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="iconador">
                    <figure>
                      <img src="/static/images/warn.png" alt="warning" />
                    </figure>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className="checkbox">
          <Checkbox />
          <h5>Save this information</h5>
        </div>
      </div>
    </div>
  );
};

export default AddNewCard;
