import { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./ManageOrder.scss";
import { ProductDetail } from "../../types/General";
import { useLazyGetManageOrdersByIdQuery } from "../../service/sellerPanel/Orders";

type props = {
  setIsOrderId: Dispatch<SetStateAction<any>>;
  selectedId: number | undefined;
};

const OderDetail = ({ setIsOrderId, selectedId }: props) => {
  const status = {
    1: "Order placed",
    2: "Shipped",
    3: "Delivered",
    4: "Rejected",
    5: "Cancelled",
  } as any;

  const [getOrderByIdMutation] = useLazyGetManageOrdersByIdQuery();

  const [OrderDetail, setOrderDetail] = useState<ProductDetail>();

  const getOrderById = async (id: number) => {
    try {
      const res = await getOrderByIdMutation({ order_id: id }).unwrap();
      if (res?.code === 200) {
        setOrderDetail(res?.data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderById(Number(selectedId));
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  const productData = [
    {
      id: 1,
      label: [
        {
          id: 1,
          type: "Full Name",
          user: `${OrderDetail?.user?.first_name} ${OrderDetail?.user?.last_name}`,
        },
        {
          id: 3,
          type: "Phone Number",
          user: OrderDetail?.address_details
            ? `${
                "+" + OrderDetail?.address_details?.order_country_code || ""
              } ${OrderDetail?.address_details?.order_phone_no || ""}`
            : "",
        },
        {
          id: 4,
          type: "Location",
          user: OrderDetail?.address_details?.order_address || "",
        },
        {
          id: 5,
          type: "Order Status",
          user: status[OrderDetail?.delivery_status || 1],
        },
      ],
      productimg:
        OrderDetail?.product?.product_image || "/static/images/profilepic.png",
    },
  ];

  return (
    <>
      <div className="manageUser">
        <div className="userHead">
          <h3>Order Details</h3>
          <div className="addrole">
            <button onClick={() => setIsOrderId(false)}>Back</button>
          </div>
        </div>
        <div className="userDetail">
          {productData?.map((item) => {
            return (
              <div className="userMain" key={item?.id}>
                <div className="userImg">
                  <figure>
                    <img src={item?.productimg} alt="Product" />
                  </figure>
                </div>
                <ul>
                  {item?.label?.map((value, i) => {
                    return (
                      <li key={value.id}>
                        <h3>{value?.type ? value?.type : ""}</h3>
                        <p>{value?.user ? value?.user : ""}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default OderDetail;
