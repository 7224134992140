import { useMemo } from "react";
import { useAppSelector } from "./Store";
import { getPermissions } from "../reducers/authSlice";

function usePermissions() {
  const permissions = useAppSelector(getPermissions);

  return useMemo(() => permissions, [permissions]);
}

export default usePermissions;
