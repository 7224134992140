import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "./Faq.scss";
import useTranslation from "../../hooks/Translation";
import { useLazyGetFaqListQuery } from "../../service/Common";
import { FaqData } from "../../types/General";
import { Loader } from "../../helpers";

const Faq = () => {
  const [faqs, setFaqs] = useState<FaqData[]>([]);

  const [getFaqMethod, getFaqDataMutation] = useLazyGetFaqListQuery();

  const translation = useTranslation() as any;

  const style = {
    borderRadius: "14px",
    boxShadow: "1px 1px 5px 4px #0000001A",
  };

  const border = {
    borderTop: "1px solid #DEDEDE",
  };

  const getFaqListing = async () => {
    try {
      const res = await getFaqMethod({}).unwrap();
      if (res?.code === 200) {
        setFaqs(res?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFaqListing();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Loader isLoad={getFaqDataMutation?.isLoading} />
      <div className="wrapcls">
        <div className="faqmain container">
          <div className="faqHead">
            <h2>{translation.faq.heading}</h2>
          </div>

          {faqs?.length ? (
            faqs?.map((items) => (
              <div key={items?.id} className="accrd">
                <Accordion sx={style}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>{items?.question || ""}</p>
                  </AccordionSummary>
                  <AccordionDetails sx={border}>
                    <Typography>{items?.answer || ""}</Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))
          ) : (
            <p>No faq's listing found</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Faq;
