import { useNavigate } from "react-router-dom";
import { ManageProduct } from "../types/General";
import ReactStars from "react-stars";
import {
  LazyLoadImage,
  ScrollPosition,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

type ProductProps = {
  verified?: boolean;
  onClick?: () => void;
  latestProduct: ManageProduct;
  scrollPosition: ScrollPosition;
};

const ProductCard = ({
  verified = true,
  latestProduct,
  scrollPosition,
}: ProductProps) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="pCard"
        key={latestProduct.id}
        onClick={() => {
          navigate(`/${latestProduct?.id}`);
          // navigate(
          //   `/${latestProduct?.product_name
          //     ?.toLocaleLowerCase()
          //     ?.split(" ")
          //     ?.join("-")}/${latestProduct?.id}`
          // );
        }}
      >
        <div className="pImg">
          {latestProduct?.product_images?.length
            ? latestProduct?.product_images?.slice(0, 1).map((item: any) => {
                return (
                  <figure key={item?.id}>
                    {/* <img src={item?.image?.media_file_url} alt="Product" /> */}
                    <LazyLoadImage
                      alt={`${latestProduct?.product_name || ""}`}
                      height={"100%"}
                      src={item?.image?.media_file_url || ""}
                      width={"100%"}
                      effect="blur"
                      scrollPosition={scrollPosition}
                    />
                  </figure>
                );
              })
            : ""}
        </div>
        <div className="pData">
          {verified ? (
            <figure>
              <img src="/static/images/verified.png" alt="verified" />
            </figure>
          ) : null}
          <h3> {latestProduct?.product_name || ""}</h3>

          <ul>
            <li>
              <span>Category : </span>
              {"  "}
              {latestProduct?.product_category?.name || ""}
            </li>
            {/* <li>
              <span> Sub-Category : </span>{" "}
              {latestProduct?.product_sub_category?.name || ""}
            </li> */}
            <li>
              <span>Stock : </span>{" "}
              {parseInt(latestProduct?.product_stock) > 0 ? (
                latestProduct?.product_stock
              ) : (
                <p style={{ color: "#ff3131", fontWeight: 600 }}>
                  Out of stock
                </p>
              )}
            </li>
            <li className="orgin">
              <span>Origin : </span>{" "}
              {latestProduct?.country_of_origin?.name || ""}
            </li>
            <li>
              <ReactStars
                count={5}
                size={30}
                color1={"#dfdfdf"}
                color2={"#ffd700"}
                value={latestProduct?.rating}
                half={true}
                edit={false}
              />
            </li>
          </ul>
        </div>
        <div className="rating"></div>

        <div
          className="viewPrice"
          onClick={() => navigate(`/${latestProduct?.id}`)}
        >
          <p>View Price</p>
        </div>
      </div>
    </>
  );
};

export default trackWindowScroll(ProductCard);
