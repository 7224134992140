import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, MenuItem, Select, TextField } from "@mui/material";
import "./AddProduct.scss";
import { Button } from "../../../components";
import useTranslation from "../../../hooks/Translation";
import * as Yup from "yup";
import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import {
  Brand,
  Category,
  CountriesResponse,
  ManageProduct,
  SubCategory,
} from "../../../types/General";
import {
  useLazyGetProductByIdDataQuery,
  useLazyGetSubCatByIdQuery,
  usePostAddProductMutation,
  usePutUpdateProductMutation,
} from "../../../service/sellerPanel/Products";
import { Loader, errorToast, successToast } from "../../../helpers";
import { UploadMedia, Uploadpdf } from "../../../utils/mediaUpload";
import { ImageResponse } from "../../../types/User";
import { isNumber, isString } from "../../../utils/validations";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type ManageProps = {
  SetActiveCase: Dispatch<SetStateAction<any>>;
  categories: Category[];
  brandsData: Brand[];
  countries: CountriesResponse[];
  isAdd: boolean;
  selectedId: any;
};

interface MyImage {
  file: File | number;
  url: string;
}

const AddProduct = ({
  SetActiveCase,
  categories,
  brandsData,
  countries,
  isAdd,
  selectedId,
}: ManageProps) => {
  const [myImages, setMyImages] = useState<MyImage[]>([]);

  const [category, setCategory] = useState("");
  const [imageRes, setImageRes] = useState<string>();

  const [brand, setBrand] = useState("");
  const [subcategory, setSubCategory] = useState("");
  const [origin, setOrigin] = useState("");
  const [nance, setNance] = useState("1");
  const [error, setError] = useState(false);
  const [certificate, setCertificate] = useState<ImageResponse | null>();
  const [subCatArr, setSubCatArr] = useState<SubCategory[]>([]);
  const [productData, setProductData] = useState<ManageProduct>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDocs, setSelectedDocs] = useState<any[]>([]);

  const translation = useTranslation() as any;
  const [getSubCatMethod] = useLazyGetSubCatByIdQuery();
  const [addProductMethod] = usePostAddProductMutation();
  const [updateProductMethod] = usePutUpdateProductMutation();
  const [getProductByIdMethod] = useLazyGetProductByIdDataQuery();

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : null;
    if (file) {
      setLoading(true);
      const res = await Uploadpdf(file);
      setLoading(false);
      if (res?.code === 200) {
        console.log(res, "ooooo");
        const pdfUri = [
          { url: res?.data[0]?.media_file_url?.replace("http", "https") },
        ];
        setSelectedDocs(pdfUri);
        setImageRes(res?.data[0]?.media_file_url);
        // setSelectedDocs()
        setCertificate(res?.data[0]);
      } else {
        errorToast(res?.message);
      }
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const uploadedImages = Array.from(files).map((file) => ({
        file,
        url: URL.createObjectURL(file),
      }));
      setMyImages([...myImages, ...uploadedImages]);
    }
  };

  const handleCrossClick = async (e: any, i: any) => {
    e.stopPropagation();
    if (myImages.includes(i)) {
      let arr = [];
      arr = myImages.filter((name) => name !== i);
      setMyImages(arr);
    }
  };

  const getSubCatByCat = async (id: any) => {
    try {
      setLoading(true);
      const res = await getSubCatMethod({
        category_id: Number(id),
      }).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        setSubCatArr(res?.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleCategory = async (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCategory(event.target.value);
    getSubCatByCat(event.target.value);
  };

  const handleSubCategory = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSubCategory(event.target.value);
  };

  const handleBrand = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setBrand(event.target.value);
  };

  const handleOrigin = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setOrigin(event.target.value);
  };

  const handleNance = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setNance(event.target.value);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      productName: productData?.product_name || "",
      productCategory: "",
      subcategory: "",
      productCost: productData?.product_cost || "",
      productQty: productData?.product_unit || "",
      productOrigin: "",
      stock: productData?.product_stock || "",
      keyDimension: productData?.product_key_dimension || "",
      constructionMaterial: productData?.material_of_construction || "",
      nance: "",
      materialGrade: productData?.material_grade || "",
      remarks: productData?.remark || "",
      shortDescription: productData?.short_description || "",
      specification: productData?.detailed_description || "",
    },
    validationSchema: Yup.object({
      productName: Yup.string()
        .required("Product name is required")
        .label("Product name")
        .min(3)
        .max(50),
      productCost: Yup.number()
        .typeError("Must be a valid number.")
        .positive("Must be a valid number.")
        .integer("Must be a valid number.")
        .required("Product cost is required")

        .label("Product Cost"),
      productQty: Yup.string()
        .required("Product unit is required")

        .label("Product unit"),
      stock: Yup.number()
        .typeError("Must be a valid number.")
        .positive("Must be a valid number.")
        .integer("Must be a valid number.")
        .required("Stock is required")

        .label("Stock"),
      // Yup.string().required("Stock is required").label("Stock"),
      keyDimension: Yup.string()
        .required("Key dimensions is required")
        .label("Key dimensions"),
      // .min(3),
      constructionMaterial: Yup.string()
        .required("Construction material is required")
        .label("Construction material")
        .min(3),
      materialGrade: Yup.string()
        .required("Material grade is required")
        .label("Material grade"),
      // .min(3),
      remarks: Yup.string()
        .required("Remarks is required")
        .label("Remarks")
        .min(3),
      shortDescription: Yup.string()
        .required("Description is required")
        .label("Short description")
        .min(3)
        .max(1000),
      specification: Yup.string()
        .required("Specification is required")
        .label("Specification")
        .min(3)
        .max(1000),
    }),
    onSubmit: async (value) => {
      formik.setSubmitting(true);
      let images: { image: any }[] = [];

      if (myImages?.length) {
        await Promise.all(
          myImages?.map(async (item) => {
            if (typeof item?.file === "number") {
              images?.push({
                image: item?.file,
              });
              return;
            }
            const res = await UploadMedia(item?.file);
            // console.log(res?.data[0]?.id);

            if (res?.code === 200 && res?.data?.length) {
              images?.push({
                image: res?.data[0]?.id,
              });
            }
          })
        );
      }

      if (!nance || !origin || !selectedDocs?.length || !images?.length) {
        setError(true);
        return;
      }
      setError(false);
      try {
        let body = {
          product_name: isAdd
            ? `${formik.values.productName}:::`
            : formik.values.productName,
          short_description: isAdd
            ? `${formik.values.shortDescription}:::`
            : formik.values.shortDescription,
          product_category: Number(category) || null,
          product_sub_category: Number(subcategory) || null,
          detailed_description: isAdd
            ? `${formik.values.specification}:::`
            : formik.values.specification,
          product_cost: isAdd
            ? `${formik.values.productCost}:::`
            : formik.values.productCost,
          brand: Number(brand) || null,
          product_stock: isAdd
            ? `${formik.values.stock}:::`
            : formik.values.stock,
          product_key_dimension: isAdd
            ? `${formik.values.keyDimension}:::`
            : formik.values.keyDimension,
          remark: isAdd ? `${formik.values.remarks}:::` : formik.values.remarks,
          material_grade: isAdd
            ? `${formik.values.materialGrade}:::`
            : formik.values.materialGrade,
          material_of_construction: isAdd
            ? `${formik.values.constructionMaterial}:::`
            : formik.values.constructionMaterial,
          product_unit: isAdd
            ? `${formik.values.productQty}:::`
            : formik.values.productQty,
          country_of_origin: Number(origin),
          nance: nance === "1" ? true : false,
          product_certificate_image: Number(certificate?.id) || undefined,
          product_images: images,
        };
        setLoading(true);
        console.log(body, "body");

        if (isAdd) {
          const res = await addProductMethod(body).unwrap();
          setLoading(false);
          if (res?.code === 201 || res?.code === 200) {
            successToast(res?.message || "");
            SetActiveCase(1);
          }
        } else {
          const res = await updateProductMethod({
            body,
            product_id: selectedId,
          }).unwrap();
          setLoading(false);
          if (res?.code === 201 || res?.code === 200) {
            successToast(res?.message || "");
            SetActiveCase(1);
          }
        }
      } catch (error: any) {
        setLoading(false);
        errorToast(error?.data?.message || "");
      }
    },
  });

  const getProductByIdData = async () => {
    try {
      setLoading(true);
      const res = await getProductByIdMethod({
        product_id: selectedId,
      }).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        setProductData(res?.data || "");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (productData) {
      setCategory(productData?.product_category?.id?.toString() || "");
      setBrand(productData?.brand?.id?.toString() || "");
      setSubCategory(productData?.product_sub_category?.id?.toString() || "");
      setOrigin(productData?.country_of_origin?.id?.toString() || "");
      setCertificate(productData?.product_certificate_image || undefined);
      const pdfUri = [
        {
          url:
            productData?.product_certificate_image?.media_file_url?.replace(
              "http",
              "https"
            ) || null,
        },
      ];
      setSelectedDocs(
        productData?.product_certificate_image === null ? [] : pdfUri
      );
      setImageRes(
        productData?.product_certificate_image?.media_file_url?.replace(
          "http",
          "https"
        )
      );
      if (productData?.product_category) {
        getSubCatByCat(productData?.product_category?.id);
      }

      if (productData?.product_images?.length) {
        if (productData?.product_images?.length) {
          let newObj = productData?.product_images?.map((item) => {
            return {
              file: (item?.image as any)?.id as number,
              url: (item?.image as any)?.media_file_url as string,
            };
          });

          setMyImages([...myImages, ...newObj]);
        }
      }
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [productData]);

  console.log(selectedDocs, "selectedDocs");

  useEffect(() => {
    if (!isAdd) {
      getProductByIdData();
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [isAdd]);

  const Input = styled("input")({
    // display: "none",
  });

  return (
    <div className="addprdct">
      <Loader isLoad={loading} />
      <form onSubmit={formik.handleSubmit}>
        <div className="producthead">
          <h3>{isAdd ? "Add Product" : "Edit Product"}</h3>
          <div className="btndiv">
            <div className="publish">
              <button
                className="btnpublish"
                type="submit"
                disabled={formik.isSubmitting}
                onClick={() => setError(true)}
              >
                {isAdd ? "Publish" : "Update"}
              </button>
            </div>
            <div className="cancel">
              <Button
                onClick={() => SetActiveCase(1)}
                value={translation.globals.cancel}
              />
            </div>
          </div>
        </div>

        <h4>{translation.addProduct.uploadimgs}</h4>
        <div className="flexdiv">
          {myImages?.length ? (
            <>
              {myImages?.map((item, i) => {
                return (
                  <li key={i}>
                    <div className="uploaded">
                      <ClearIcon onClick={(e) => handleCrossClick(e, item)} />
                      <figure>
                        <img src={item?.url} alt="upload" />
                      </figure>
                    </div>
                  </li>
                );
              })}
            </>
          ) : null}
          <label htmlFor="icon-button-files">
            <div className="uploadImg">
              <Input
                id="icon-button-files"
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={handleFileUpload}
                // onChange={(val) => {
                //   if (val.target.files[0].type.includes("image")) {
                //     setFileName(val.target.files[0].name);
                //     imageUpload(val.target.files[0]);
                //     setImage(URL.createObjectURL(val.target.files[0]));
                //   }
                // }}
                // inputProps={{
                //   accept: "image/*",
                // }}
              />

              <div className="bussinessimg">
                <ul>
                  {myImages?.length < 6 ? (
                    <li>
                      <div className="upload">
                        <figure>
                          <img src="/static/images/plus.png" alt="add" />
                        </figure>
                      </div>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </label>
        </div>
        {error && !myImages?.length ? (
          <h6 className="err_msg" style={{ marginTop: 10 }}>
            This field is compulsory.
          </h6>
        ) : (
          ""
        )}

        <div className="inputdivs">
          <div className="inputcontainer">
            <label>{translation.addProduct.pName}</label>
            <TextField
              placeholder={translation.addProduct.entrProductName}
              id="productName"
              name="productName"
              className="text_field"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.productName}
              helperText={
                formik.touched.productName && formik.errors.productName
              }
            />
          </div>
          <div className="inputcontainer">
            <label>{translation.addProduct.pCategory}</label>
            <Box sx={{ minWidth: "90%" }} className="addselectbox">
              <Select
                value={category}
                onChange={handleCategory}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
              >
                {categories?.length
                  ? categories?.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          className="srcv-lst"
                          value={item?.id}
                        >
                          {item?.name}
                        </MenuItem>
                      );
                    })
                  : null}
                <MenuItem value="">{"Other"}</MenuItem>
              </Select>
            </Box>
          </div>
          <div className="inputcontainer">
            <label>{translation.addProduct.subCate}</label>
            <Box sx={{ minWidth: "90%" }} className="addselectbox">
              <Select
                value={subcategory}
                onChange={handleSubCategory}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
              >
                {subCatArr?.length
                  ? subCatArr?.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          className="srcv-lst"
                          value={item?.id}
                        >
                          {item?.name}
                        </MenuItem>
                      );
                    })
                  : null}
                <MenuItem value="">{"Other"}</MenuItem>
              </Select>
            </Box>
          </div>

          <div className="inputcontainer">
            <label>{"Brand"}</label>
            <Box sx={{ minWidth: "90%" }} className="addselectbox">
              <Select
                value={brand}
                onChange={handleBrand}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
              >
                {brandsData?.length
                  ? brandsData
                      ?.filter((ele) => ele.name !== null)
                      ?.map((item, index) => {
                        return (
                          <MenuItem
                            key={index}
                            className="srcv-lst"
                            value={item?.id}
                          >
                            {item?.name}
                          </MenuItem>
                        );
                      })
                  : null}
                <MenuItem value="">{"Other"}</MenuItem>
              </Select>
            </Box>
          </div>

          <div className="inputcontainer">
            <label>{translation.addProduct.proCost}</label>
            <TextField
              placeholder={translation.addProduct.proCost}
              id="productCost"
              name="productCost"
              className="text_field"
              fullWidth
              onBlur={formik.handleBlur}
              value={formik.values.productCost}
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else if (isNumber(val.target.value)) {
                  formik.handleChange(val);
                }
              }}
              helperText={
                formik.touched.productCost && formik.errors.productCost
              }
            />
          </div>

          <div className="inputcontainer">
            <label>{translation.addProduct.countryOrigin}</label>
            <Box sx={{ minWidth: "90%" }} className="addselectbox">
              <Select
                value={origin}
                onChange={handleOrigin}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
              >
                <MenuItem value="" disabled>
                  {"Country of origin"}
                </MenuItem>
                {countries?.length
                  ? countries?.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          className="srcv-lst"
                          value={item?.id}
                        >
                          {item?.name}
                        </MenuItem>
                      );
                    })
                  : null}
              </Select>
              {/* <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={countries}
                sx={{ width: 300 }}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField {...params}  />
                )}
              /> */}
            </Box>
            {error && !origin ? (
              <h6 className="err_msg">This field is compulsory.</h6>
            ) : (
              ""
            )}
          </div>
          <div className="inputcontainer">
            <label>Stock</label>
            <TextField
              placeholder="Stock"
              id="stock"
              name="stock"
              className="text_field"
              fullWidth
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else if (isNumber(val.target.value)) {
                  formik.handleChange(val);
                }
              }}
              onBlur={formik.handleBlur}
              value={formik.values.stock}
              helperText={formik.touched.stock && formik.errors.stock}
            />
          </div>
          <div className="inputcontainer">
            <label>Key Dimensions</label>
            <TextField
              placeholder="Key Dimensions"
              id="keyDimension"
              name="keyDimension"
              className="text_field"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.keyDimension}
              helperText={
                formik.touched.keyDimension && formik.errors.keyDimension
              }
            />
          </div>
          <div className="inputcontainer">
            <label>Unit</label>
            <TextField
              placeholder="Unit"
              id="productQty"
              name="productQty"
              fullWidth
              className="text_field"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.productQty}
              helperText={formik.touched.productQty && formik.errors.productQty}
            />
          </div>
          <div className="inputcontainer">
            <label>Material of Construction</label>
            <TextField
              placeholder="Material of Construction"
              id="constructionMaterial"
              name="constructionMaterial"
              fullWidth
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else if (isString(val.target.value)) {
                  formik.handleChange(val);
                }
              }}
              onBlur={formik.handleBlur}
              value={formik.values.constructionMaterial}
              className="text_field"
              helperText={
                formik.touched.constructionMaterial &&
                formik.errors.constructionMaterial
              }
            />
          </div>
          <div className="inputcontainer">
            <label>NACE</label>
            <Box sx={{ minWidth: "90%" }} className="addselectbox">
              <Select
                value={nance}
                onChange={handleNance}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
              >
                <MenuItem className="srcv-lst" value="1">
                  Yes
                </MenuItem>
                <MenuItem className="srcv-lst" value="2">
                  No
                </MenuItem>
              </Select>
              {error && !nance ? (
                <h6 className="err_msg">This field is compulsory.</h6>
              ) : (
                ""
              )}
            </Box>
          </div>
          <div className="inputcontainer">
            <label>Grade of Material</label>
            <TextField
              placeholder="Grade of Material"
              id="materialGrade"
              name="materialGrade"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="text_field"
              value={formik.values.materialGrade}
              helperText={
                formik.touched.materialGrade && formik.errors.materialGrade
              }
            />
          </div>
          <div className="inputcontainer">
            <label>Remarks</label>
            <TextField
              placeholder="Remarks"
              id="remarks"
              name="remarks"
              fullWidth
              className="text_field"
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else if (isString(val.target.value)) {
                  formik.handleChange(val);
                }
              }}
              onBlur={formik.handleBlur}
              value={formik.values.remarks}
              helperText={formik.touched.remarks && formik.errors.remarks}
            />
          </div>
        </div>
        <div className="sepecifaction">
          <label>{translation.addProduct.specification}</label>
          <TextField
            placeholder={translation.addProduct.specification}
            fullWidth
            className="textfiled text_field"
            multiline
            rows={3}
            id="specification"
            name="specification"
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else if (isString(val.target.value)) {
                formik.handleChange(val);
              }
            }}
            onBlur={formik.handleBlur}
            value={formik.values.specification}
            helperText={
              formik.touched.specification && formik.errors.specification
            }
          />
        </div>
        <div className="sepecifaction">
          <label>{translation.addProduct.enterDes}</label>
          <TextField
            placeholder={translation.addProduct.enterDes}
            fullWidth
            className="textfiled text_field"
            multiline
            rows={4}
            id="shortDescription"
            name="shortDescription"
            onChange={(val) => {
              if (val.target.value === " " || val.target.value === ".") {
              } else {
                formik.handleChange(val);
              }
              //  if (isString(val.target.value)) {
              // }
            }}
            onBlur={formik.handleBlur}
            value={formik.values.shortDescription}
            helperText={
              formik.touched.shortDescription && formik.errors.shortDescription
            }
          />
        </div>
        <div className="sepecifaction">
          <label>{translation.addProduct.meritaltest}</label>
          <div className="dragndrop">
            <div className="pImg">
              <div>
                {selectedDocs?.length ? (
                  <div className="drag-img">
                    <ClearIcon
                      onClick={() => setSelectedDocs([])}
                      className="cross_icn"
                    />
                    {/* {isAdd ? ( */}
                    <a
                      href={imageRes}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Document
                        file={selectedDocs[0]}
                        // onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page pageNumber={1} />
                      </Document>
                    </a>
                  </div>
                ) : (
                  <label htmlFor="icon-button-file">
                    <div className="uploader">
                      <TextField
                        inputProps={{
                          accept: "application/pdf",
                        }}
                        id="icon-button-file"
                        type="file"
                        sx={{ display: "none" }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => handleImageUpload(event)}
                      />
                      <h4>Click to upload.</h4>
                      <p>File Types: .pdf file supported.</p>
                    </div>
                  </label>
                )}
              </div>
            </div>
          </div>
          {error && !selectedDocs?.length ? (
            <h6 className="err_msg">This field is compulsory.</h6>
          ) : (
            ""
          )}
        </div>
      </form>
    </div>
  );
};

export default AddProduct;
