import React from "react";
import { CartData } from "../../types/General";
import { LazyLoadImage } from "react-lazy-load-image-component";

type ManageProps = {
  cartDetail: CartData[] | [];
};

const CheckoutProduct = ({ cartDetail }: ManageProps) => {
  return (
    <>
      <div className="check_main">
        {cartDetail?.length
          ? cartDetail?.map((item) => (
              <div className="checoutproduct" key={item.id}>
                <div className="chleft">
                  <figure>
                    <LazyLoadImage
                      alt={"Product"}
                      height={"100%"}
                      src={
                        item?.product?.product_images?.length
                          ? item?.product?.product_images[0]?.image
                              ?.media_file_url
                          : ""
                      }
                      width={"100%"}
                      effect="blur"
                    />
                  </figure>
                </div>
                <div className="chrightt">
                  <h3>{item?.product?.product_name || ""}</h3>
                  <p>
                    {`Size: ${item?.product?.product_key_dimension} ${item?.product?.product_unit}` ||
                      ""}
                  </p>
                  <p>Qty : {item?.quantity || ""}</p>
                  <h5>
                    Actual Price : $
                    {`${
                      item?.total_amount
                        ? parseFloat(`${item?.total_amount}`).toFixed(2)
                        : ""
                    }`}
                  </h5>
                </div>
              </div>
            ))
          : null}
      </div>
    </>
  );
};

export default CheckoutProduct;
