export const PermissionEnums = {
  addProduct: 1,
  editProduct: 2,
  markProductObsolete: 3,
  changeCompanyInfo: 4,
  viewOrderStatus: 5,
  buyProducts: 6,
  writeReviews: 7,
  changeShippingAddress: 8,
};
