import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductInfo } from "../../features";
import { errorToast } from "../../helpers";
import Layout from "../../layout";
import {
  useGetSameSupplierMutation,
  useGetSimilarProductsMutation,
  useLazyGetProductByIdQuery,
} from "../../service/home";
import { ManageProduct, ProductBtId } from "../../types/General";
import "./ProductDetail.scss";

const ProductDetail = () => {
  const { id } = useParams();

  const [similarProducts] = useGetSameSupplierMutation();
  const [sameSupplier] = useGetSimilarProductsMutation();
  const [similarProduct, setSimilarProduct] = useState<ManageProduct[]>([]);
  const [sameSupplierData, setSameSupplierData] = useState<ManageProduct[]>([]);
  const [productIdDetails, setProductIdDetails] = useState<ProductBtId>();
  const [productDetailMutation] = useLazyGetProductByIdQuery();

  const getSimilarProducts = async () => {
    const body = {
      id: Number(id),
    };

    try {
      const response = await similarProducts(body).unwrap();
      if (response?.code === 200 || response?.code === 201) {
        setSimilarProduct(response?.data || []);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
      console.log(error, "ERROR");
    }
  };

  const getSameSupplier = async () => {
    const body = {
      id: id,
    };
    try {
      const response = await sameSupplier(body).unwrap();
      if (response?.code === 200 || response?.code === 201) {
        setSameSupplierData(response?.data || []);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
      console.log(error, "ERROR");
    }
  };

  const getProductsById = async () => {
    try {
      const res = await productDetailMutation(`${id || ""}`).unwrap();
      if (res?.code === 200) {
        setProductIdDetails(res?.data || undefined);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSimilarProducts();
    getSameSupplier();
    getProductsById();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Layout>
      <ProductInfo
        similarProduct={similarProduct}
        sameSupplierData={sameSupplierData}
        data={productIdDetails}
      />
    </Layout>
  );
};

export default ProductDetail;
