import { END_POINTS } from "../../contansts/Urls";
import { GraphRes, ProductDetail } from "../../types/General";
import emptySplitApi from "../../utils/rtk";

export type GetOrdersByIdResponse = {
  data?: OrderProducts[];
  code: number;
  message?: string;
};

export type getOrderDetail = {
  data: ProductDetail;
  code: number;
  message?: string;
};

export interface Orders {
  id: number;
  user: number;
  ordered_product: OrderProducts[] | [];
}

export interface OrderProducts {
  id: number;
  ordered_product: {
    id: number;
    user: {
      id: number;
      first_name: string;
      last_name: string;
    };
    product: {
      id: 42;
      product_name: string;
      product_image: string;
      product_category: {
        id: number;
        name: string;
      };
      product_sub_category: {
        id: number;
        name: string;
      };
      brand: {
        id: number;
        name: string;
      };
    };
    quantity: number;
    cost: number;
    created_at: string;
    delivery_status: number;
  }[];
}

type GetAllOrdersResponse = {
  data: OrderProducts[];
  code: number;
  message: string;
  recordsTotal: number;
};

export type GraphResponse = {
  code: number;
  message: string;
  data: GraphRes;
};

export type Graphtype = {
  name: string;
  value: number;
};

type GraphBody = {
  filter_type: number;
};

type ReviewResponse = {
  code: number;
  message: string;
  data: Review;
};

type Review = {
  id: number;
  rating: number;
  review: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  is_active: boolean;
  is_deleted: boolean;
  user: number;
  product: number;
};

type ReviewBody = {
  user: number;
  product: number;
  rating: number;
  review: string;
};

type RevenueResponse = {
  data: number;
  code: number;
  message: string;
};

type CancelOrderBody = {
  order_id?: number;
  product_id?: number;
};

type CancelOrderResponse = {
  data: null;
  code: 200;
  message: string;
};

export const ordersApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllOrdersList: builder.mutation<
      GetAllOrdersResponse,
      {
        length: number | undefined;
        start: number | undefined;
        search: {
          value: string | null;
          regex: boolean;
        };
      }
    >({
      query: (body) => ({
        url: END_POINTS.getAllOrders,
        method: "POST",
        body,
      }),
    }),

    getManageOrdersById: builder.query<
      getOrderDetail,
      { order_id?: number | undefined }
    >({
      query: ({ order_id }) => ({
        url: `${END_POINTS.getManageOrderById}${order_id}/`,
        method: "GET",
      }),
    }),

    downloadOrdersCsv: builder.query<GetOrdersByIdResponse, {}>({
      query: () => ({
        url: `${END_POINTS.exportCsvOrders}`,
        method: "GET",
      }),
    }),
    postGraph: builder.mutation<GraphResponse, GraphBody>({
      query: (body) => ({
        url: END_POINTS.get_graph,
        method: "POST",
        body,
      }),
    }),
    postReview: builder.mutation<ReviewResponse, ReviewBody>({
      query: (body) => ({
        url: END_POINTS.add_rating,
        method: "POST",
        body,
      }),
    }),
    getRevenue: builder.query<RevenueResponse, {}>({
      query: () => ({
        url: `${END_POINTS.get_revenue}`,
        method: "GET",
      }),
    }),
    postCancelOrder: builder.mutation<CancelOrderResponse, CancelOrderBody>({
      query: (body) => ({
        url: END_POINTS.cancelOrder,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetManageOrdersByIdQuery,
  useLazyDownloadOrdersCsvQuery,
  useGetAllOrdersListMutation,
  usePostGraphMutation,
  usePostReviewMutation,
  useLazyGetRevenueQuery,
  usePostCancelOrderMutation,
} = ordersApi;
