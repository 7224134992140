import { useState } from "react";
import Layout from "../../layout";
import "./Seller.scss";
import {
  ChangePassword,
  Dashboard,
  ManageAddress,
  ManageOrder,
  ManageProduct,
  ManageRoles,
  ManageUsers,
  PersonalInfo,
  Report,
  Roles,
  SellerSidebar,
} from "../../features/seller";

const Seller = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [activeCase, SetActiveCase] = useState(1);

  const handleAccountTab = () => {
    switch (activeTab) {
      case 1:
        return <Dashboard />;
      case 2:
        return <ManageOrder />;
      case 3:
        return (
          <ManageProduct
            SetActiveCase={SetActiveCase}
            activeCase={activeCase}
          />
        );
      case 4:
        return <Roles />;
      case 5:
        return <ManageUsers activeTab={activeTab} />;
      case 6:
        return <Report />;
      case 7:
        return <PersonalInfo />;
      case 8:
        return <ManageAddress />;
      case 9:
        return <ChangePassword />;
      case 12:
        return <ManageRoles />;
      default:
        return null;
    }
  };
  return (
    <Layout>
      <div className="accnt wrapcls">
        <div className="container ">
          <div className="accMain">
            <div className="accleft">
              <SellerSidebar
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                SetActiveCase={SetActiveCase}
              />
            </div>
            <div className="accright">{handleAccountTab()}</div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Seller;
