import { END_POINTS } from "../../contansts/Urls";
import { ManageUser } from "../../types/General";
import emptySplitApi from "../../utils/rtk";

type GetUserByIdResponse = {
  data?: ManageUser | undefined;
  code: number;
  message?: string;
};

type GetAllUsersResponse = {
  data?: ManageUser[];
  code: number;
  message?: string;
  count?: number;
};

type PostChangeStatusBody = {
  is_active?: boolean;
};

type PostChangeStatusParams = {
  user_id?: number;
  body: PostChangeStatusBody;
};

type AssignRoleBody = {
  user_id?: number;
  role_id?: number;
};

export const usersApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsersList: builder.mutation<
      GetAllUsersResponse,
      { limit?: number; offset?: number }
    >({
      query: (body) => ({
        url: END_POINTS.getAllUsers,
        method: "POST",
        body,
      }),
    }),

    getUsersById: builder.query<
      GetUserByIdResponse,
      { user_id?: number | undefined }
    >({
      query: ({ user_id }) => ({
        url: `${END_POINTS.getUserById}${user_id}/`,
        method: "GET",
      }),
    }),

    changeUserStatus: builder.mutation<
      GetUserByIdResponse,
      PostChangeStatusParams
    >({
      query: ({ body, user_id }) => ({
        url: `${END_POINTS.changeUserStatus}${user_id}/`,
        method: "PUT",
        body,
      }),
    }),

    assignRole: builder.mutation<GetUserByIdResponse, AssignRoleBody>({
      query: (body) => ({
        url: END_POINTS.assignRole,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetUsersByIdQuery,
  useGetAllUsersListMutation,
  useChangeUserStatusMutation,
  useAssignRoleMutation,
} = usersApi;
