import { IconButton, InputAdornment, Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Dispatch, SetStateAction, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import {
  usePostCompanyDomainMutation,
  usePostSignupMutation,
} from "../../service/Auth";
import CompanyDomainModal from "./CompanyDomainModal";
import { Loader, errorToast, warnToast } from "../../helpers";
import { isString } from "../../utils/validations";
import { LazyLoadComponent } from "react-lazy-load-image-component";

type SignUpProps = {
  setActiveModal: Dispatch<SetStateAction<number>>;
  closeModal: () => void;
  setOtpModalType: Dispatch<SetStateAction<any>>;
  setEmail: Dispatch<SetStateAction<any>>;
};

type DomainData = {
  id?: number;
  name: string;
  company_domain: string;
  user_details?: any;
};

const SignUpModal = ({
  setActiveModal,
  setOtpModalType,
  setEmail,
  closeModal,
}: SignUpProps) => {
  const [SignUpMutation, SignUpMutationData] = usePostSignupMutation();
  const [countryName, setCountryName] = useState("in");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [companyCountryName, setCompanyCountryName] = useState("in");
  const [companyPhoneCode, setCompanyPhoneCode] = useState("+91");
  const [companyDomainMutation] = usePostCompanyDomainMutation();
  const [isDomainModal, setIsDomainModal] = useState<boolean>(false);
  const [companyDomainData, setCompanyDomainData] = useState<DomainData[]>([]);
  const handleClose = () => setIsDomainModal(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      jobTitle: "",
      companyName: "",
      companyPhone: "",
      location: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("First name is required")
        .min(2, "Minimum 2 characters are required"),
      lastName: Yup.string().min(2, "Minimum 2 characters are required"),
      jobTitle: Yup.string().min(2, "Minimum 2 characters are required"),
      companyName: Yup.string().min(3, "Minimum 3 characters are required"),
      location: Yup.string().min(2, "Minimum 2 characters are required"),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
      companyPhone: Yup.string()
        .required("Company phone number is required")
        .min(6, "Company phone number must be at least 6 characters")
        .max(16, "Company phone number must be at least 16 characters"),
    }),
    onSubmit: async (value) => {
      formik.setSubmitting(true);
      console.log(value);
      // navigate("/");

      let body = {
        first_name: formik.values.firstName,
        last_name: formik.values.lastName,
        business_email: formik.values.email,
        office_phone_no: formik.values.phone,
        office_country_code: phoneCode.includes("+")
          ? phoneCode
          : "+" + phoneCode,
        office_country_iso_code: countryName,
        job_title: formik.values.jobTitle,
        company_phone_no: formik.values.companyPhone,
        location: formik.values.location,
        company_name: formik.values.companyName
          ? formik.values.companyName
          : null,
        company_country_iso_code: companyCountryName,
        company_country_code: companyPhoneCode.includes("+")
          ? companyPhoneCode
          : "+" + companyPhoneCode,
      };
      try {
        const response = await SignUpMutation(body).unwrap();
        if (response?.code === 200) {
          setEmail(body?.business_email || "");
          setOtpModalType("SignUp");
          setActiveModal(4);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error, "errorrr");
      }
    },
  });

  // for country picker
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
    setCountryName(country?.countryCode);
  };

  const handleChangeCompanyName = (phone: any, country: any) => {
    setCompanyPhoneCode(country?.dialCode);
    setCompanyCountryName(country?.countryCode);
    formik.setFieldValue("companyPhone", phone?.replace(country.dialCode, ""));
  };
  //-----end-----//

  const onEmailCheck = async () => {
    if (
      formik.values.email.includes("@") &&
      formik.values.email.includes(".") &&
      !formik.errors.email
    ) {
      let body = {
        email: formik.values.email,
        company_domain: formik.values.email?.split("@")?.length
          ? formik.values.email?.split("@")[1]
          : formik.values.email,
      };

      try {
        const res = await companyDomainMutation(body).unwrap();
        if (res?.data?.length && res?.code === 200) {
          setCompanyDomainData(res?.data);
          setIsDomainModal(true);
        } else if (res?.code === 201) {
          warnToast(res?.message || "");
        }
      } catch (error: any) {
        errorToast(error?.message);

        console.log(error);
      }
    } else {
      // setIsDomainModal(false);
    }
    if (formik.touched.email && !formik.errors.email) {
    }
  };

  return (
    <div className="signmain">
      <Loader isLoad={SignUpMutationData.isLoading} />
      <LazyLoadComponent>
        <div className="signleft">
          <div className="crossbtn">
            <IconButton onClick={closeModal}>
              <CloseIcon sx={{ color: "#ffff" }} />
            </IconButton>
          </div>
          <h2>
            Find out how products can help you set up & manage your online
            business
          </h2>
          <p>
            Have our business experts on the phone to understand your company's
            operations and guide you through a demo customized to your business
            industry.
          </p>
        </div>
      </LazyLoadComponent>
      <div className="signRyt">
        <h3>Sign up for a new account</h3>
        <div className="crossbtn">
          <IconButton onClick={closeModal}>
            <CloseIcon sx={{ color: "#ffff" }} />
          </IconButton>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="inputdiv">
            <div className="firstname">
              <div className="fdiv">
                <h4>First Name*</h4>
                <TextField
                  className="text_field"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  fullWidth
                  // onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (isString(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  inputProps={{ maxLength: 20 }}
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </div>
              <div className="fdiv">
                <h4>Last Name</h4>
                <TextField
                  className="text_field"
                  placeholder="Last Name"
                  id="lastName"
                  name="lastName"
                  inputProps={{ maxLength: 15 }}
                  fullWidth
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else if (isString(val.target.value)) {
                      formik.handleChange(val);
                    }
                  }}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                />
              </div>
            </div>
            <div className="fdiv">
              <h4>Business Email Address*</h4>
              <TextField
                className="text_field"
                placeholder="Business Email Address*"
                fullWidth
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={(val) => {
                  formik.handleBlur(val);
                  onEmailCheck();
                }}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
            <div className="fdiv">
              <h4>Phone Number*</h4>
              <PhoneInput
                enableSearch={true}
                value={phoneCode + formik.values.phone}
                country={"us"}
                inputClass="phoneInput"
                buttonClass="phoneBtn"
                placeholder="Phone Number"
                onChange={(phone, country) => handleChangePhone(phone, country)}
                // value={formik.values.companyPhone}
                // onChange={(value) => formik.setFieldValue("companyPhone", value)}
                onBlur={formik.handleBlur}
                inputStyle={{ marginBottom: "10px" }}
              />
            </div>
            {formik.touched.phone && formik.errors.phone ? (
              <h6 className="err_msg">
                {formik.touched.phone && formik.errors.phone}
              </h6>
            ) : (
              ""
            )}
            <div className="fdiv">
              <h4>Job Title</h4>
              <TextField
                className="text_field"
                id="jobTitle"
                name="jobTitle"
                placeholder="Job Title (optional)"
                fullWidth
                inputProps={{ maxLength: 50 }}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.jobTitle}
                helperText={formik.touched.jobTitle && formik.errors.jobTitle}
              />
            </div>
            <div className="fdiv">
              <h4>Name of the company</h4>
              <TextField
                className="text_field"
                id="companyName"
                name="companyName"
                placeholder="Name of the company (optional)"
                fullWidth
                inputProps={{ maxLength: 50 }}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                helperText={
                  formik.touched.companyName && formik.errors.companyName
                }
                value={formik.values.companyName}
              />
            </div>
            <div className="fdiv">
              <h4>Company Phone Number*</h4>
              <PhoneInput
                enableSearch={true}
                value={companyPhoneCode + formik.values.companyPhone}
                country={"us"}
                inputClass="phoneInput"
                buttonClass="phoneBtn"
                placeholder="Company phone number"
                onChange={(phone, country) =>
                  handleChangeCompanyName(phone, country)
                }
                // value={formik.values.companyPhone}
                // onChange={(value) => formik.setFieldValue("companyPhone", value)}
                onBlur={formik.handleBlur}
                inputStyle={{ marginBottom: "10px" }}
              />
            </div>
            {formik.touched.companyPhone && formik.errors.companyPhone ? (
              <h6 className="err_msg">
                {formik.touched.companyPhone && formik.errors.companyPhone}
              </h6>
            ) : (
              ""
            )}
            <div className="fdiv">
              <h4>Location</h4>
              <TextField
                className="text_field adornment"
                placeholder="Location (optional)"
                fullWidth
                id="location"
                name="location"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.location}
                helperText={formik.touched.location && formik.errors.location}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="iconador">
                      <figure style={{ width: "21px", height: "21px" }}>
                        <img src="/static/images/send.png" alt="send"></img>
                      </figure>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="btn">
              <div className="btnddiv">
                <button type="submit" disabled={formik.isSubmitting}>
                  Sign up
                </button>
              </div>
            </div>

            <div className="bottomline">
              <p>
                Already have an account?{" "}
                <span onClick={() => setActiveModal(1)}>Log in</span>
              </p>
            </div>
          </div>
        </form>
      </div>
      <Modal
        open={isDomainModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <CompanyDomainModal
            handleClose={handleClose}
            formik={formik}
            companyDomainData={companyDomainData || []}
            setPhoneCode={setPhoneCode}
            setCompanyPhoneCode={setCompanyPhoneCode}
          />
        </div>
      </Modal>
    </div>
  );
};

export default SignUpModal;
