import {
  Badge,
  Menu,
  MenuItem,
  SwipeableDrawer,
  TextField,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LogoutIcon from "@mui/icons-material/Logout";
import { Breadcrumb, Button } from "../../components";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuIcon from "@mui/icons-material/Menu";
import {
  STORAGE_KEYS,
  getFromStorage,
  removeFromStorage,
} from "../../contansts/Storage";
import { useAppDispatch, useAppSelector } from "../../hooks/Store";
import {
  getToken,
  setCredentials,
  setPermissions,
} from "../../reducers/authSlice";
import useAuth from "../../hooks/UseAuth";
import { AuthModal } from "../../features/modals";
import { useLazyGetProfileQuery } from "../../service/Auth";
import { useGetAllProductsMutation } from "../../service/home";
import { ManageProduct } from "../../types/General";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@mui/material";
import {
  getCartData,
  isAuthModalVisible,
  resetCart,
} from "../../reducers/commonSlice";
import { isString } from "../../utils/validations";
import usePermissions from "../../hooks/Permissions";
import { PermissionEnums } from "../../contansts/permissions";
import { warnToast } from "../../helpers";
import { Box, Modal } from "@mui/material";
import { useLazyGetCartItemQuery } from "../../service/Booking";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useCookies } from "react-cookie";

const Header = () => {
  const userData = useAuth();
  const [cookies, setCookie, removeCookie] = useCookies(["CRT"]);
  const ReduxCart = useAppSelector(getCartData);
  const totalQuantity = ReduxCart.reduce(
    (acc: any, item: any) => acc + item.quantity,
    0
  );

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const permissions = usePermissions();
  const isModalVisible = useAppSelector(isAuthModalVisible);
  const logindata = useAppSelector(getToken);

  const [getProfileMethod] = useLazyGetProfileQuery();
  const [getAllProductsMethod] = useGetAllProductsMutation();
  const [getCart] = useLazyGetCartItemQuery();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [activeModal, setActiveModal] = useState<number>(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [getAllProducts, setGetAllProducts] = useState<ManageProduct[]>([]);
  const [searchResults, setSearchResults] = useState<number>(0);

  const [sidebarVisible, setSidebarVisible] = useState(false);

  const style = {
    position: "absolute" as "absolute",
    top: isSmallScreen ? "50%" : "50%",
    left: isSmallScreen ? "50%" : "50%",
    transform: "translate(-50%, -50%)",
    // width: 350,
    bgcolor: "background.paper",
    boxShadow: 24,
    outline: "none",
    p: 4,
    borderRadius: 4,
    width: isSmallScreen ? "77%" : "350px",
    // height: isSmallScreen ? "70%" : "60%",
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const menu = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const menuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    menuClose();
    setOpenModal(false);
    setSidebarVisible(false);
    removeFromStorage(STORAGE_KEYS?.token);
    removeFromStorage(STORAGE_KEYS.userData);
    dispatch(
      setCredentials({
        user: null,
        token: null,
      })
    );
    navigate("/", { replace: true });
  };

  const getProfileData = async (token: any) => {
    try {
      const res = await getProfileMethod({}).unwrap();

      if (res?.code === 200) {
        console.log(res?.data?.user_website_roles?.permissions);

        dispatch(
          setCredentials({
            user: res?.data || null,
            token: token || null,
          })
        );

        if (res?.data?.user_website_roles?.permissions?.length) {
          dispatch(setPermissions(res?.data?.user_website_roles?.permissions));
        }
      }
    } catch (error) {
      console.log(error, "lpllp");
    }
  };

  const NavigateProfile = () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    getProfileData(`${token}`);
    navigate("/seller", { state: "profile" });
    menuClose();
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const data = getFromStorage(STORAGE_KEYS.userData);
    if (token) {
      dispatch(
        setCredentials({
          user: JSON.parse(`${data}`) || null,
          token,
        })
      );
      getProfileData(`${token}`);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [open]);

  const handleOpenModal = () => {
    setSidebarVisible(false);
    setOpen(true);
  };

  const getAllProductsData = async () => {
    try {
      let body = {
        length: 5,
        start: 1,
        search: {
          value: debouncedSearchTerm.trim(),
          regex: false,
        },
        category: null,
        sub_category: null,
        country: null,
      };
      const res = await getAllProductsMethod(body).unwrap();

      if (res?.code === 200) {
        setGetAllProducts(res?.data || []);
        setSearchResults(res?.recordsFiltered || 0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCartDetail = async () => {
    try {
      const res = await getCart({}).unwrap();
      if (res?.code === 200) {
        const cookie = cookies?.CRT || null;
        if (res?.data?.length) {
          const values = res?.data?.map((item) => {
            return {
              name: item?.product?.product_name,
              price: item?.product?.token_amount,
              quantity: item?.quantity,
            };
          });
          if (cookie) {
            setCookie("CRT", JSON.stringify(values), { path: "/" });
          }
        }
        dispatch(
          resetCart({
            cart: res?.data || [],
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm?.length) {
      getAllProductsData();
    } else {
      setGetAllProducts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  useEffect(() => {
    if (logindata) {
      getCartDetail();
    }
  }, [logindata]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  //toggle drawe
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setSidebarVisible(open);
    };

  const list = () => (
    <Box sx={{ width: 350 }} role="presentation">
      <div className="res_header">
        <figure onClick={() => navigate("/")} className="res_logo">
          <LazyLoadImage
            alt={"Logo"}
            height={"100%"}
            src={"/static/images/logo.png"}
            width={"100%"}
            effect="blur"
          />
        </figure>
        <div className="hBRight">
          <ul>
            {logindata ? (
              <li
                onClick={() => {
                  setSidebarVisible(false);
                  navigate("/cart");
                }}
                className="cart"
              >
                <Badge badgeContent={totalQuantity} color="primary">
                  <ShoppingCartIcon />
                </Badge>

                <h4>Cart</h4>
              </li>
            ) : null}
            {logindata ? (
              <li className="usr_select">
                <div className="user_data">
                  <button
                    className="dropdwn user_btn"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <div className="user_img">
                      <figure>
                        <LazyLoadImage
                          alt={"Profile"}
                          height={"100%"}
                          src={
                            userData?.image?.media_file_url ||
                            "/static/images/profilepic.png"
                          }
                          width={"100%"}
                          effect="blur"
                        />
                        {/* <img
                          src={
                            userData?.image?.media_file_url ||
                            "/static/images/profilepic.png"
                          }
                          alt=" "
                        /> */}
                      </figure>
                    </div>
                    <h5>
                      {userData?.first_name
                        ? userData?.first_name + " " + userData?.last_name
                        : ""}
                    </h5>
                    <ArrowDropDownIcon />
                  </button>
                </div>
              </li>
            ) : (
              <li onClick={() => handleOpenModal()} className="cart">
                <LogoutIcon />
                <h4>Login</h4>
              </li>
            )}

            <li className="pst">
              <Button
                value="+ Post a Listing"
                fontSize={16}
                onClick={() => {
                  if (userData) {
                    if (
                      permissions?.includes(`${PermissionEnums.addProduct}`) ||
                      userData?.is_company_owner
                    ) {
                      navigate("/seller", { state: "list" });
                    } else {
                      warnToast(
                        "You don't have the permission to add a listing, please contact your company owner."
                      );
                    }
                  } else {
                    handleOpenModal();
                  }
                }}
              />
            </li>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={menu}
              onClose={menuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem className="menu_item" onClick={NavigateProfile}>
                Profile
              </MenuItem>
              <MenuItem
                className="menu_item"
                onClick={() => {
                  navigate("/my-order");
                  menuClose();
                }}
              >
                My Orders
              </MenuItem>
              {/* <MenuItem
                className="menu_item"
                onClick={() => {
                  navigate("/notifications");
                  menuClose();
                }}
              >
                Notifications
              </MenuItem> */}
              <MenuItem
                className="menu_item"
                onClick={() => {
                  setSidebarVisible(false);
                  setOpenModal(true);
                }}
              >
                Logout
              </MenuItem>
              <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="warning-modal">
                    <h3>Are you sure, you want to logout from this account</h3>
                    <div className="btn-div">
                      <button className="yes" onClick={() => handleLogout()}>
                        Yes
                      </button>
                      <button
                        className="no"
                        onClick={() => setOpenModal(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </Box>
              </Modal>
            </Menu>
          </ul>
        </div>
      </div>
    </Box>
  );
  return (
    <>
      <div className="headerwrap">
        <header className="headermain">
          <div className="headertop">
            <div className="container">
              <div className="htmain">
                <div className="htleft">
                  {userData?.designation ? (
                    <>
                      <figure>
                        <img
                          src="/static/images/location_icon.png"
                          alt="location"
                        />
                      </figure>
                      <p>{userData?.designation || ""}</p>
                    </>
                  ) : null}

                  {/* <KeyboardArrowDownIcon style={{ color: "white" }} /> */}
                </div>
                <div className="htright">
                  <ul>
                    <li
                      onClick={() =>
                        navigate("/about-us ", { state: "aboutus" })
                      }
                    >
                      About us
                    </li>
                    <li>Industries</li>
                    <li onClick={() => navigate("/contact-us")}>Contact us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="headerBtm ">
            <div className="container">
              <div className="hbmain">
                <div className="hBleft">
                  <div className="hmbrg" onClick={toggleDrawer(true)}>
                    <MenuIcon />
                  </div>

                  <figure onClick={() => navigate("/")}>
                    <LazyLoadImage
                      alt={"No Categories"}
                      height={"100%"}
                      src={"/static/images/logo.png"}
                      width={"100%"}
                      effect="blur"
                    />
                  </figure>
                  <div className="inputField">
                    <TextField
                      placeholder="Search by product"
                      className="search"
                      value={searchTerm}
                      onChange={(val: any) => {
                        if (isString(val.target.value)) {
                          setSearchTerm(val.target.value);
                        }
                      }}
                    />
                    {getAllProducts?.length || debouncedSearchTerm?.length ? (
                      <div className="product_srch">
                        {searchResults ? (
                          <div className="items_div">
                            <p>{searchResults} items</p>
                          </div>
                        ) : null}
                        {getAllProducts?.length ? (
                          getAllProducts?.map((item) => {
                            return (
                              <ul key={item?.id}>
                                <li
                                  className="main_li"
                                  onClick={() => {
                                    setDebouncedSearchTerm("");
                                    setSearchTerm("");
                                    setGetAllProducts([]);
                                    navigate(`/${item?.id}`);
                                  }}
                                >
                                  <div className="li_left">
                                    {item?.product_images?.length
                                      ? item?.product_images
                                          ?.slice(0, 1)
                                          .map((val: any) => {
                                            return (
                                              <figure key={val?.id}>
                                                {/* <img
                                                  src={
                                                    val?.image?.media_file_url
                                                  }
                                                  alt="Product"
                                                /> */}
                                                <LazyLoadImage
                                                  alt={"No Categories"}
                                                  height={"100%"}
                                                  src={
                                                    val?.image
                                                      ?.media_file_url || ""
                                                  }
                                                  width={"100%"}
                                                  effect="blur"
                                                />
                                              </figure>
                                            );
                                          })
                                      : ""}
                                  </div>
                                  <div className="li_ryt">
                                    <div className="stock_flex">
                                      <h3>{item?.product_name || ""}</h3>
                                      <p>QTY: {item?.product_stock || "0"}</p>
                                    </div>
                                    <p>{item?.product_category?.name || ""}</p>
                                  </div>
                                </li>
                              </ul>
                            );
                          })
                        ) : (
                          <h2 className="no_record">No product found</h2>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="hBRight">
                  <ul>
                    {logindata ? (
                      <li onClick={() => navigate("/cart")}>
                        <Badge badgeContent={totalQuantity} color="primary">
                          <ShoppingCartIcon />
                        </Badge>

                        <h4>Cart</h4>
                      </li>
                    ) : null}
                    {logindata ? (
                      <li>
                        <div className="user_data">
                          <button
                            className="dropdwn user_btn"
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                          >
                            <div className="user_img">
                              <figure>
                                {/* <img
                                  src={
                                    userData?.image?.media_file_url ||
                                    "/static/images/profilepic.png"
                                  }
                                  alt=" "
                                /> */}
                                <LazyLoadImage
                                  alt={"Profile"}
                                  height={"100%"}
                                  src={
                                    userData?.image?.media_file_url ||
                                    "/static/images/profilepic.png"
                                  }
                                  width={"100%"}
                                  effect="blur"
                                />
                              </figure>
                            </div>
                            <h5>
                              {userData?.first_name
                                ? userData?.first_name +
                                  " " +
                                  userData?.last_name
                                : ""}
                            </h5>
                            <ArrowDropDownIcon />
                          </button>
                        </div>
                      </li>
                    ) : (
                      <li onClick={() => handleOpenModal()}>
                        <LogoutIcon />
                        <h4>Login</h4>
                      </li>
                    )}

                    <li>
                      <Button
                        value="+ Post a Listing"
                        fontSize={16}
                        onClick={() => {
                          if (userData) {
                            if (
                              permissions?.includes(
                                `${PermissionEnums.addProduct}`
                              ) ||
                              userData?.is_company_owner
                            ) {
                              navigate("/seller", { state: "list" });
                            } else {
                              warnToast(
                                "You don't have the permission to add a listing, please contact your company owner."
                              );
                            }
                          } else {
                            handleOpenModal();
                          }
                        }}
                      />
                    </li>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={menu}
                      onClose={menuClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem className="menu_item" onClick={NavigateProfile}>
                        Profile
                      </MenuItem>
                      <MenuItem
                        className="menu_item"
                        onClick={() => {
                          navigate("/my-order");
                          menuClose();
                        }}
                      >
                        My Orders
                      </MenuItem>
                      <MenuItem
                        className="menu_item"
                        onClick={() => setOpenModal(true)}
                      >
                        Logout
                      </MenuItem>
                      <Modal
                        open={openModal}
                        onClose={() => setOpenModal(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <div className="warning-modal">
                            <h3>
                              Are you sure, you want to logout from this account
                            </h3>
                            <div className="btn-div">
                              <button
                                className="yes"
                                onClick={() => handleLogout()}
                              >
                                Yes
                              </button>
                              <button
                                className="no"
                                onClick={() => setOpenModal(false)}
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </Box>
                      </Modal>
                    </Menu>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
        <SwipeableDrawer
          anchor="left"
          open={sidebarVisible}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list()}
        </SwipeableDrawer>
        <Breadcrumb />
      </div>
      <AuthModal
        setActiveModal={setActiveModal}
        activeModal={activeModal}
        IsOpen={open || isModalVisible}
        setOpen={setOpen}
      />
    </>
  );
};

export default Header;
