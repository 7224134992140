import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  useGetProductsMutation,
  usePutChangeProductStatusMutation,
} from "../../../service/sellerPanel/Products";
import { ManageProduct } from "../../../types/General";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useTranslation from "../../../hooks/Translation";
import { Loader, successToast } from "../../../helpers";
import useAuth from "../../../hooks/UseAuth";
import usePermissions from "../../../hooks/Permissions";
import { PermissionEnums } from "../../../contansts/permissions";

type ManageProps = {
  edit?: boolean;
  SetActiveCase: Dispatch<SetStateAction<any>>;
  value?: number;
  productId?: string;
  dates?: any;
  category?: string;
  brand?: string;
  setSelectedId: Dispatch<SetStateAction<number>>;
  setIsAdd: Dispatch<SetStateAction<boolean>>;
  setPendintCount: Dispatch<SetStateAction<number | undefined>>;
};

const ManageTable = ({
  SetActiveCase,
  edit = true,
  value,
  productId,
  dates,
  category,
  brand,
  setPendintCount,
  setSelectedId,
  setIsAdd,
}: ManageProps) => {
  const user = useAuth();
  const permissions = usePermissions();
  const translation = useTranslation() as any;

  const [productData, setProductData] = useState<ManageProduct[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  console.log(productData, "productData");

  const [currentPage, setCurrentPage] = useState(1);

  const [loading, setLoading] = useState<boolean>(false);

  const totalPages = Math.ceil(totalCount / 10);

  const goToPage = (page: React.SetStateAction<number>) => {
    setCurrentPage(page);
  };

  const [getProductMethod] = useGetProductsMutation();
  const [updateProductStatus] = usePutChangeProductStatusMutation();

  const getProductsData = async () => {
    try {
      let body = {
        start: currentPage,
        brand: Number(brand) || "",
        id: Number(productId) || "",
        start_date: dates?.length ? dates[0]?.format("YYYY-MM-DD") : "",
        end_date: dates?.length ? dates[1]?.format("YYYY-MM-DD") : "",
        product_category: Number(category) || "",
        is_unapproved: value === 2 ? true : false,
        is_approved: value === 1 ? true : false,
        length: 10,
        search: {
          value: "",
          regex: false,
        },
      };
      console.log(body, "bb");

      const res = await getProductMethod(body).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        setProductData(res?.data);
        setTotalCount(res?.recordsTotal || 0);
        setPendintCount(res?.pending_product_obj_count || 0);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChangeStatus = async (id: number, item: boolean) => {
    try {
      let body = {
        is_active: item,
      };
      setLoading(true);
      const res = await updateProductStatus({
        body,
        product_id: `${id}`,
      }).unwrap();
      setLoading(false);
      if (res?.code === 200 || res?.code === 201) {
        successToast(
          `Status ${item ? "activated" : "deactivated"} Successfully`
        );

        getProductsData();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProductsData();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [value, productId, currentPage, dates, category, brand]);

  return (
    <div className="tabledv">
      <TableContainer
        component={Paper}
        sx={{ padding: 0 }}
        className="tablemain"
      >
        <Loader isLoad={loading} />
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          className="ta_ble"
        >
          <TableHead className="mange_usr">
            <TableRow>
              <TableCell>Product ID</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Brand Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Stock</TableCell>
              <TableCell>Total amount</TableCell>
              <TableCell>Action</TableCell>
              <TableCell
                sx={{
                  display:
                    user?.is_company_owner ||
                    permissions?.includes(
                      `${PermissionEnums.markProductObsolete}`
                    )
                      ? "inline"
                      : "none",
                }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>

          {productData?.length ? (
            productData?.map((item) => (
              <TableBody>
                <TableRow
                  key={item?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {"#" + item?.id || ""}
                  </TableCell>
                  <TableCell>{item?.product_name || ""}</TableCell>
                  <TableCell>{item?.brand?.name || "Other"}</TableCell>
                  <TableCell>
                    {item?.product_category?.name || "Other"}
                  </TableCell>
                  <TableCell>{item?.product_stock || ""}</TableCell>
                  <TableCell>{item?.product_cost || ""}</TableCell>
                  <TableCell>
                    {edit ? (
                      <BorderColorIcon
                        onClick={() => {
                          setIsAdd(false);
                          SetActiveCase(2);
                          setSelectedId(item?.id);
                        }}
                        sx={{
                          display:
                            user?.is_company_owner ||
                            permissions?.includes(
                              `${PermissionEnums.editProduct}`
                            )
                              ? "inline"
                              : "none",
                        }}
                      />
                    ) : null}
                    {/* <Visibility sx={{ color: "#00931C" }} /> */}
                    <RemoveRedEyeIcon
                      onClick={() => {
                        SetActiveCase(3);
                        setSelectedId(item?.id);
                      }}
                      sx={{ color: "green" }}
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={item?.is_active}
                      onChange={(e) =>
                        handleChangeStatus(item?.id, e.target.checked)
                      }
                      sx={{
                        display:
                          user?.is_company_owner ||
                          permissions?.includes(
                            `${PermissionEnums.markProductObsolete}`
                          )
                            ? "flex"
                            : "none",
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            ))
          ) : (
            <TableBody className="noTableData">
              <h4>Products not found</h4>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <div className="pagination" style={{ marginTop: "20px" }}>
        <p>
          {translation.manageOrder.Showing}{" "}
          <span>{productData?.length ? `1-${productData?.length}` : 0} </span>
          {translation.manageOrder.from} <span>{totalCount} </span>
          {translation.manageOrder.data}
        </p>
        <div className="pagdrp">
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label">Select</InputLabel> */}
            <Select
              className="dropse"
              style={{ width: 20 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              inputProps={{
                "aria-label": "Without label",
              }}
              displayEmpty
              value={currentPage}
              onChange={(page: any) => setCurrentPage(page.target.value)}
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              {Array.from({ length: totalPages }).map((_, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {productData?.length ? (
            <div className="pages">
              <button
                className="prevbtn"
                onClick={() => goToPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <ArrowBackIosIcon />
              </button>
              <div className="count">
                {totalPages > 4
                  ? Array.from({ length: totalPages })
                      .slice(0, 4)
                      .map((_, index) => (
                        <button
                          className={
                            currentPage === index + 1 ? "actv" : "inActv"
                          }
                          key={index}
                          onClick={() => goToPage(index + 1)}
                          disabled={currentPage === index + 1}
                        >
                          {index + 1}
                        </button>
                      ))
                      .concat(
                        <span key="ellipsis" className="ellipsis">
                          ...
                        </span>,
                        <button
                          className={"actv"}
                          style={{
                            display:
                              currentPage < 4 || currentPage === totalPages
                                ? "none"
                                : undefined,
                          }}
                          key={totalPages}
                        >
                          {currentPage}
                        </button>,
                        <button
                          className={
                            currentPage === totalPages ? "actv" : "inActv"
                          }
                          key={totalPages}
                          onClick={() => goToPage(totalPages)}
                          disabled={currentPage === totalPages}
                        >
                          {totalPages}
                        </button>
                      )
                  : Array.from({ length: totalPages }).map((_, index) => (
                      <button
                        className={
                          currentPage === index + 1 ? "actv" : "inActv"
                        }
                        key={index}
                        onClick={() => goToPage(index + 1)}
                        disabled={currentPage === index + 1}
                      >
                        {index + 1}
                      </button>
                    ))}
              </div>
              <button
                className="prevbtn"
                onClick={() => goToPage(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <ArrowForwardIosIcon
                // className="prevbtn"
                // onClick={() => goToPage(currentPage + 1)}
                />
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageTable;
