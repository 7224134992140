import { Dispatch, SetStateAction, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import "../../layout/Layout.scss";
import { Button } from "../../components";
import {
  useOtpSignUpVerificationMutation,
  useOtpVerficationMutation,
  useResendOtpMutation,
} from "../../service/Auth";
import { useAppDispatch } from "../../hooks/Store";
import { temporaryToken } from "../../reducers/authSlice";
import { Loader, errorToast, successToast } from "../../helpers";

type otpProps = {
  email?: string;
  setActiveModal: Dispatch<SetStateAction<number>>;
  otpModalType: string;
  closeModal: () => void;
};

const OtpModal = ({
  email,
  setActiveModal,
  otpModalType,
  closeModal,
}: otpProps) => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState<boolean>(false);
  const [countDown, setCountDown] = useState<number>(59);
  const [loading, setLoading] = useState<boolean>(false);

  const [otpMutation] = useOtpVerficationMutation();
  const [signUpOtpMethod] = useOtpSignUpVerificationMutation();
  const [resendOtpMethod] = useResendOtpMutation();
  const dispatch = useAppDispatch();

  const otpStyle = {
    padding: "15px",
    border: "none",
    borderBottom: "1px solid #B4B4B4",
    margin: "22px",
  };

  const handleSubmit = async () => {
    if (otp?.length === 4) {
      setError(false);

      let body = {
        email: email,
        otp: otp,
      };

      try {
        setLoading(true);
        if (otpModalType === "SignUp") {
          const response = await signUpOtpMethod(body).unwrap();
          setLoading(false);
          if (response?.code === 200) {
            successToast("Please check your email for further information");
            closeModal();
            setActiveModal(1);
          }
        } else {
          const response = await otpMutation(body).unwrap();
          setLoading(false);
          if (response?.code === 200) {
            dispatch(
              temporaryToken({
                tempToken: response?.data || "",
              })
            );
            setActiveModal(5);
          }
        }
      } catch (error: any) {
        setLoading(false);
        errorToast(error?.data?.message || "");
        console.log(error, "errorrr");
      }
    } else {
      setError(true);
    }
  };

  const resendClick = async () => {
    try {
      let body = {
        email: email,
      };
      setLoading(true);
      const res = await resendOtpMethod(body).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        successToast("OTP sent successfully");
      }
      setCountDown(59);
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <>
      <Loader isLoad={loading} />

      <div className="otpModal">
        <div className="cancelBtn">
          {/* <IconButton size="large" onClick={handleClose}>
          <CancelIcon sx={style2} />
        </IconButton> */}
        </div>
        <h2>Verification</h2>
        <div className="otpInfo">
          <p>
            Enter the verification code that we have just sent to your
            registered email address
          </p>
        </div>

        <div className="otpInput">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderInput={(props) => <input {...props} />}
            inputStyle={otpStyle}
          />
        </div>
        {error && otp.length !== 4 ? (
          <h6 className="err_msg">Please enter the verification code.</h6>
        ) : (
          ""
        )}
        <div className="btn">
          <Button value="Verify" onClick={() => handleSubmit()} />
        </div>

        {/* <h4 onClick={resendClick}>Resend Otp</h4> */}
        {countDown === 0 ? (
          <div className="btn">
            <h5
              style={{
                textAlign: "center",
                cursor: "pointer",
                color: "#61439f ",
              }}
              onClick={resendClick}
              className="btn_rd"
            >
              Resend OTP
            </h5>
          </div>
        ) : (
          <h5 style={{ textAlign: "center", marginTop: 20 }}>
            The verify code will expire in
            <span className="counter" style={{ color: "#61439f" }}>
              {" "}
              00 : {countDown}
            </span>
          </h5>
        )}
      </div>
    </>
  );
};

export default OtpModal;
