import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { OrderType } from "../ManageOrder";

type ManageProps = {
  orders: OrderType[];
};

const OrdersReportTable = ({ orders }: ManageProps) => {
  return (
    <TableContainer component={Paper} sx={{ padding: 0 }} className="tablemain">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Order ID</TableCell>
            <TableCell>Product Name</TableCell>
            <TableCell>Brand Name</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Total amount</TableCell>
            {/* <TableCell>Action</TableCell> */}
          </TableRow>
        </TableHead>

        {orders?.length ? (
          orders?.slice(0.5)?.map((item) => (
            <TableBody>
              <TableRow
                key={item?.product_order_id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  #{item?.order_id}
                </TableCell>
                <TableCell>{item?.product || ""}</TableCell>
                <TableCell>{item?.brand || ""}</TableCell>
                <TableCell>{item?.product_category || ""}</TableCell>
                <TableCell>{item?.quantity || ""}</TableCell>
                <TableCell>{item?.cost || ""}</TableCell>
                {/* <TableCell>
                  <RemoveRedEyeIcon sx={{ color: "green" }} />
                </TableCell> */}
              </TableRow>
            </TableBody>
          ))
        ) : (
          <TableBody className="order_err">
            <h4>Orders not found</h4>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default OrdersReportTable;
