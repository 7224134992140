import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { ProductCard } from "../../components";
import { SideBar } from "../../features/home";
import {
  useGetAllProductsMutation,
  useLazyGetCategoryListQuery,
} from "../../service/home";
import { CategoryResponse, ManageProduct } from "../../types/General";
import { Loader } from "../../helpers";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useAppSelector } from "../../hooks/Store";
import { getFilterId } from "../../reducers/commonSlice";
import { useLocation } from "react-router-dom";

const Products = () => {
  const location = useLocation();
  const { state } = location;
  const countryId = state?.country;

  const filter = useAppSelector(getFilterId);

  const [categories, setCategories] = useState<CategoryResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [getAllProducts, setGetAllProducts] = useState<ManageProduct[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState(0);

  const [getCatSubCatMethod] = useLazyGetCategoryListQuery();
  const [getAllProductsMethod] = useGetAllProductsMutation();

  const totalPages = Math.ceil(totalCount / 16);

  const goToPage = (page: React.SetStateAction<number>) => {
    setCurrentPage(page);
  };

  const getCatSubCatData = async () => {
    try {
      setLoading(true);
      const res = await getCatSubCatMethod({}).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        setCategories(res?.data || []);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const getAllProductsData = async () => {
    try {
      let body = {
        length: 16,
        start: currentPage,
        search: {
          value: "",
          regex: false,
        },
        category: filter?.catId || null,
        sub_category: filter?.subCatId || null,
        country: filter?.countryId || null,
      };
      setLoading(true);
      const res = await getAllProductsMethod(body).unwrap();
      setLoading(false);
      if (res?.code === 200) {
        setGetAllProducts(res?.data || []);
        setTotalCount(res?.recordsTotal || 0);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllProductsData();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, [currentPage, filter]);

  useEffect(() => {
    // eslint-disable-line react-hooks/exhaustive-deps
    getCatSubCatData();
  }, []);

  return (
    <Layout>
      <div className="wrapcls ">
        <Loader isLoad={loading} />
        <div className="categriessec ">
          <div className="container">
            <div className="category" style={{ marginTop: "40px" }}>
              <div className="cleft">
                <SideBar
                  categories={categories}
                  state={state}
                  countryId={countryId}
                  setSelected={setSelected}
                  selected={selected}
                />
              </div>

              <div className="cright">
                <div className="procontainer">
                  <div className="pmain">
                    <div className="product">
                      {getAllProducts?.length
                        ? getAllProducts?.map((item) => {
                            return (
                              <div className="proCard" key={item?.id}>
                                <ProductCard latestProduct={item} />
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                  <div
                    className="pagination"
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {getAllProducts?.length ? (
                      <div className="pages">
                        <button
                          className="prevbtn"
                          onClick={() => goToPage(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          <ArrowBackIosIcon />
                        </button>
                        <div className="count">
                          {totalPages > 6
                            ? Array.from({ length: totalPages })
                                .slice(0, 6)
                                .map((_, index) => (
                                  <button
                                    className={
                                      currentPage === index + 1
                                        ? "actv"
                                        : "inActv"
                                    }
                                    key={index}
                                    onClick={() => goToPage(index + 1)}
                                    disabled={currentPage === index + 1}
                                  >
                                    {index + 1}
                                  </button>
                                ))
                                .concat(
                                  <span key="ellipsis" className="ellipsis">
                                    ...
                                  </span>,
                                  <button
                                    className={"actv"}
                                    style={{
                                      display:
                                        currentPage < 6 ||
                                        currentPage === totalPages
                                          ? "none"
                                          : undefined,
                                    }}
                                    key={totalPages}
                                  >
                                    {currentPage}
                                  </button>,
                                  <button
                                    className={
                                      currentPage === totalPages
                                        ? "actv"
                                        : "inActv"
                                    }
                                    key={totalPages}
                                    onClick={() => goToPage(totalPages)}
                                    disabled={currentPage === totalPages}
                                  >
                                    {totalPages}
                                  </button>
                                )
                            : Array.from({ length: totalPages }).map(
                                (_, index) => (
                                  <button
                                    className={
                                      currentPage === index + 1
                                        ? "actv"
                                        : "inActv"
                                    }
                                    key={index}
                                    onClick={() => goToPage(index + 1)}
                                    disabled={currentPage === index + 1}
                                  >
                                    {index + 1}
                                  </button>
                                )
                              )}
                        </div>
                        <button
                          className="prevbtn"
                          onClick={() => goToPage(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        >
                          <ArrowForwardIosIcon
                          // className="prevbtn"
                          // onClick={() => goToPage(currentPage + 1)}
                          />
                        </button>
                      </div>
                    ) : (
                      <div className="no_cate">
                        <figure>
                          <img
                            src="/static/images/no_pro.png"
                            alt="No Products"
                          />
                        </figure>
                        <h2>No products found</h2>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Products;
